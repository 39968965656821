import calendar from '@/intelligence/store/modules/calendar';
import customerOpportunities from '@/intelligence/store/modules/customerOpportunities';
import enquiry from '@/intelligence/store/modules/enquiry';
import lastInvoice from '@/intelligence/store/modules/lastInvoice';
import metadata from '@/intelligence/store/modules/metadata';
import notifications from '@/intelligence/store/modules/notifications';
import opportunities from '@/intelligence/store/modules/opportunities';
import performanceInsight from '@/intelligence/store/modules/performanceInsight';
import productGroups from '@/intelligence/store/modules/productGroups';
import queryBuilder from '@/intelligence/store/modules/queryBuilder';
import reportLazyLoad from '@/intelligence/store/modules/reportLazyLoad';
import savedQuery from '@/intelligence/store/modules/savedQuery';
import shared from '@/intelligence/store/modules/shared';
import targets from '@/intelligence/store/modules/targets';
import communities from '@/intelligence/store/modules/communities';

export default {
  namespaced: true,
  modules: {
    calendar,
    customerOpportunities,
    enquiry,
    lastInvoice,
    metadata,
    notifications,
    opportunities,
    performanceInsight,
    productGroups,
    queryBuilder,
    reportLazyLoad,
    savedQuery,
    shared,
    targets,
    communities,
  }
};
