<template>
  <div class="crm-export-view">
    <div class="button-container">
      <CustomButton
        class="text-button reset-button"
        purpose="text"
        :label="t('reset')"
        @click="resetData"
      />
      <ExportButton 
        :is-export-disabled="isExportDisabled"
        @export="exportData"
      />
    </div>
    <TwoColumnLayout class="export-content">
      <template #sidebar>
        <CustomButton
          class="text-button"
          :class="{activated: activeComponent === 'AccountType'}"
          purpose="text"
          :label="t('account_type')"
          @click="onMenuButtonClick('AccountType')"
        />
        
        <!-- not required until next revision -->
        <!-- <p>{{ t('optional') }}:</p>
        <li>
          <CustomButton
            class="text-button"
            :class="{activated: activeComponent === 'FinancialData'}"
            purpose="text"
            :label="t('financial_data')"
            @click="onMenuButtonClick('FinancialData')"
          />
        </li> -->

        <p>{{ t('Filter options') }}:</p>
        <li class="sub-li">
          <Roundels 
            :count="analysisFieldFilter.length"
            class="roundels"
          >
            <CustomButton
              class="text-button"
              :class="{activated: activeComponent === 'AnalysisFields'}"
              purpose="text"
              :label="t('analysis_fields')"
              @click="onMenuButtonClick('AnalysisFields')"
            />
          </Roundels>
        </li>

        <!-- Date filters are not required until next revision -->
        <!-- <li class="sub-li">
          <CustomButton
            class="text-button"
            :class="{activated: activeComponent === 'Date'}"
            purpose="text"
            :label="t('date')"
            @click="onMenuButtonClick('Date')"
          />
        </li> -->
      </template>
      <template #content>
        <component 
          :is="componentMap[activeComponent]"
          :account-types="accountTypes"
          :crm-objects="crmObjects"
          :analysis-field-filter="analysisFieldFilter"
          @update:account-types="updateAccountTypes"
          @update:crm-objects="updateCrmObjects"
          @update:analysis-field="updateAnalysisField"
          @delete-filter="deleteFilter"
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script setup>
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import Roundels from '@/shared/components/Roundels/Roundels';
import { CustomButton } from '@sales-i/dsv3';
import { tAdmin as t, exportCRM } from '@sales-i/utils';
import { computed, ref } from 'vue';
import AccountType from '@/admin/components/CRMExport/AccountType.vue';
import FinancialData from '@/admin/components/CRMExport/FinancialData.vue';
import AnalysisFields from '@/admin/components/CRMExport/AnalysisFields.vue';
import Date from '@/admin/components/CRMExport/Date.vue';
import ExportButton from '@/admin/components/CRMExport/ExportButton.vue';
import useNotification from '@/shared/composables/useNotification';

const componentMap = {
  AccountType,
  FinancialData,
  AnalysisFields,
  Date,
};

const { sendSuccessAlert } = useNotification();

const accountTypes = ref({
  customer: false,
  prospect: false,
});
const crmObjects = ref({
  contact: false,
  opportunity: false,
  interaction: false,
});
const analysisFieldFilter = ref([]);
const activeComponent = ref('AccountType');

const isExportDisabled = computed(() => {
  return !Object.values(accountTypes.value).some(value => value);
});
const preparedFilters = computed(() => {
  return analysisFieldFilter.value.map(filter => ({
    analysis_field: filter.analysis_field,
    analysis_values: filter.analysis_values
  }));
});

function onMenuButtonClick(componentKey) {
  activeComponent.value = componentKey;
}
function updateAccountTypes(payload) {
  accountTypes.value[payload.type] = payload.value;
}
function updateCrmObjects(payload) {
  crmObjects.value[payload.type] = payload.value;
}
function updateAnalysisField(payload) {
  analysisFieldFilter.value.push(payload);
}
function deleteFilter(indexToRemove) {
  analysisFieldFilter.value.splice(indexToRemove, 1);
}
async function exportData(exportType) {
  const entities = [
    ...Object.keys(accountTypes.value).filter(key => accountTypes.value[key]),
    ...Object.keys(crmObjects.value).filter(key => crmObjects.value[key])
  ];
  const filter = {
    analysis: preparedFilters.value
  };
  
  try {
    const success = await exportCRM(
      entities,
      exportType,
      filter,
    );  
    if (success) sendSuccessAlert(t('we_will_notify_you_when_your_download_is_ready'));
  } catch (error) {
    console.error('Export failed: ', error);
  }
}

function resetData() {
  accountTypes.value = {
    customer: false,
    prospect: false,
  };
  crmObjects.value = {
    contact: false,
    opportunity: false,
    interaction: false,
  };
  analysisFieldFilter.value = [];

  // TODO: add the reset functionality for the other views
}
</script>

<style lang="scss" scoped>
.crm-export-view {
  .export-button {
    padding: var(--spacing-1) var(--spacing-4);
  }

  .button-container {
    background-color: var(--colour-panel-action);
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-half) var(--spacing-5);
  }

  .export-content {
    margin-top: 0;
    padding-top: 0;
  }

  .text-button {
    display: inline-block;

    &.activated {
      color: var(--colour-utility-black);
      text-decoration: none;
      font-weight: var(--font-weight-semibold);
    }
  }

  .reset-button {
    text-decoration: none;
    padding: 0 var(--spacing-4);
  }

  li {
    &.sub-li {
      margin-left: var(--spacing-2);
      position: relative;

      .roundels {
        position: static;

        :deep(.indicator) {
          position: absolute;
          left: 100%;
        }
      }
    }
  }
}

:deep(.main) {
  min-height: 510px;
}
</style>