<template>
  <div class="target-amount">
    <CustomButton
      v-if="!isMobile"
      :disabled="disabled"
      purpose="reversed"
      small
      @click="showModal"
    >
      <span class="no-wrap">{{ t('target_amount') }}</span>
    </CustomButton>

    <CustomButton
      v-else
      :disabled="disabled"
      purpose="text"
      @click="showModal"
    >
      <span class="no-wrap">{{ t('target_amount') }}</span>
    </CustomButton>
  </div>

  <CreateTargetAmountPicker 
    v-if="isModalVisible"
    :date-array="props.dateArray"
    :initial-target-amount="targetAmount"
    :prefer-same-value="preferSameValue"
    @target-amount="handleTargetAmountSelection"
    @mode-preference-changed="handleModePreferenceChanged"
    @close-modal="closeModal"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import { useMq } from 'vue3-mq';
import CreateTargetAmountPicker from '@/admin/components/Targets/CreateTargetAmountPicker.vue';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  dateArray: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isEditMode: {
    type: Boolean,
    default: false
  },
  targetAmount: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['target-amount-selected', 'validity-changed']);

const targetAmounts = ref([]);
const isModalVisible = ref(false);
const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const preferSameValue = ref(false);

const handleTargetAmountSelection = (value) => {
  // Ensure we always have values, defaulting to '0' for any empty spots
  targetAmounts.value = value.map(amount => amount === '' ? '0' : amount);
  
  // Emit the target amounts to the parent component
  emit('target-amount-selected', targetAmounts.value);
  
  // Always consider valid since we have default values
  emit('validity-changed', true);
};

const handleModePreferenceChanged = (value) => {
  preferSameValue.value = value;
};

function showModal() {
  isModalVisible.value = true;
}

function closeModal() {
  isModalVisible.value = false;
}
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
.target-amount  {
  margin-bottom: 0;

  :deep(input:disabled) {
    opacity: 0.7;
  }
}
</style>