export const mapWidgetForAPI = (item, isForPost = true) => {
  let payload = {...item};
  
  // Handle top-level requestParams and settings
  if (isForPost || Object.prototype.hasOwnProperty.call(payload, 'requestParams'))
    payload.request_params = JSON.stringify(item.requestParams || {});
  
  if (isForPost || Object.prototype.hasOwnProperty.call(payload, 'settings'))
    payload.settings = JSON.stringify(item.settings || {});
  
  if (isForPost || Object.prototype.hasOwnProperty.call(payload, 'reportType'))
    payload.report_type = item.reportType;
  
  // Handle widget object specifically
  if (payload.widget) {
    if (!payload.widget.requestParams) {
      payload.widget.requestParams = {};
    }
    
    if (!payload.widget.settings) {
      payload.widget.settings = {};
    }
    
    // Stringify requestParams in the widget object
    payload.widget.request_params = JSON.stringify(payload.widget.requestParams);
    
    // Stringify settings in the widget object
    payload.widget.settings = JSON.stringify(payload.widget.settings);
  }
  
  return payload;
};

export const mapWidgetForStore = (w) => ({
  ...w ,
  settings: JSON.parse(w.settings),
  requestParams: JSON.parse(w.request_params),
  reportType: w.report_type
});

export const mapDashboardForStore = (dashboard) => {
  let widgets = (dashboard.widgets || []).map(x => mapWidgetForStore(x));
  return { ...dashboard, widgets };
};

