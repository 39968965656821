<template>
  <div>
    <SignPost
      v-if="mySalesILinks.length"
      :heading="t('my_sales_i')"
      :description="t('configure_your_settings_to_sell_smarter')"
      color="var(--colour-data-mandy-label)"
      background-color="var(--colour-data-mandy-light)"
      :links="mySalesILinks"
    />
    <SignPost
      v-if="crmLinks.length"
      :heading="t('c_r_m_settings')"
      :description="t('manage_your_custom_schema_sections_and_fields')"
      color="var(--colour-data-deluge-label)"
      background-color="var(--colour-data-deluge-light)"
      :links="crmLinks"
    />
    <SignPost
      v-if="intelligenceLinks.length"
      :heading="t('intelligence')"
      :description="t('take_control_of_enquiries_associations_and_communities')"
      color="var(--colour-data-puerto-rico-label)"
      background-color="var(--colour-data-puerto-rico-light)"
      :links="intelligenceLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import {
  root as adminBase,
  crmArea, intelligenceArea, companyArea,
  tagsSection, tagsReportSection, tagConsolidationSection,
  emailSyncSection,
  storageSection,
  permissionsSection, usersSection, rolesSection,
  targetsSection,
  customersPropspectsSection, contactsSection, opportunitiesSection, interactionsSection,
  customSectionsPath, stagesPath,
  associationsSection, communitiesSection,
  blacklistPath,
  logoSection,
  exportSection,
  languagesSection,
} from '@/admin/router/urlBits.js';
import SignPost from './SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import useMailAuth from '@/shared/composables/useMailAuth';
import {
  product_associations_admin,
  product_blacklist,
  product_communities_admin,
  crm_admin,
  targets_admin,
  iam_update,
  user_management,
  intelligence_admin,
  languages,
} from '@/shared/store/data/policies';
import { insightsScope, performanceScope, snapshotByAnalysisField } from '@/intelligence/router/urlBits';

const {
  permissions: permissionsComputed,
} = usePermissions();
const { isNylasAvailable } = useMailAuth();

const type = 'a';

const mySalesILinks = computed(() => {
  const permissions = permissionsComputed.value;
  const aiArr = [];
  if (permissions[crm_admin]?.read) {
    aiArr.push({
      title: t('tags'),
      description: t('view_reports_and_consolidate_similar_tags'),
      href: `/${adminBase}/${companyArea}`,
      sectionLinks: [{
        type,
        text: t('reporting'),
        href: `/${adminBase}/${companyArea}/${tagsSection}/${tagsReportSection}`,
      },{
        type,
        text: t('consolidation'),
        href: `/${adminBase}/${companyArea}/${tagsSection}/${tagConsolidationSection}`,
      }]
    });
  }
  if (isNylasAvailable.value) {
    aiArr.push({
      title: t('email_sync'),
      description: t('connect_your_inbox_with_sales_i'),
      href: `/${adminBase}/${companyArea}/${emailSyncSection}`,
    });
  }
  if (permissions[iam_update]?.read) {
    aiArr.push({
      title: t('storage'),
      description: t('view_used_storage_details'),
      href: `/${adminBase}/${companyArea}/${storageSection}`,
    });
  }
  if (permissions[languages]?.read) {
    aiArr.push({
      title: t('languages'),
      description: t('manage_your_language_settings'),
      href: `/${adminBase}/${companyArea}/${languagesSection}`,
    });
  }
  if (permissions[user_management]?.update) {
    aiArr.push({
      title: t('permissions'),
      description: t('create_and_manage_users_and_roles'),
      href: `/${adminBase}/${companyArea}/${permissionsSection}/${usersSection}`,
      sectionLinks: [{
        type,
        text: t('users'),
        href: `/${adminBase}/${companyArea}/${permissionsSection}/${usersSection}`,
      },{
        type,
        text: t('roles'),
        href: `/${adminBase}/${companyArea}/${permissionsSection}/${rolesSection}`,
      }]
    });
  }
  if (permissions[targets_admin]?.update) {
    aiArr.push({
      title: t('targets'),
      description: t('create_and_view_current_targets'),
      href: `${adminBase}/${companyArea}/${targetsSection}`
    });
  }
  if (permissions[targets_admin]?.update) {
    aiArr.push({
      title: t('logo'),
      description: t('manage_your_logo_across_desktop_and_mobile'),
      href: `${adminBase}/${companyArea}/${logoSection}`
    });
  }
  return aiArr;
});

const crmLinks = computed(() => {
  const crmArr = [];
  const permissions = permissionsComputed.value;

  if (!permissions[crm_admin]?.read) return [];

  crmArr.push({
    title: t('customer_prospect'),
    description: t('custom_schema_settings_for_customer_prospect'),
    href: `/${adminBase}/${crmArea}/${customersPropspectsSection}`,
    sectionLinks: [{
      type,
      text: t('default_sections'),
      href: `/${adminBase}/${crmArea}/${customersPropspectsSection}`,
    },{
      type,
      text: t('custom_sections'),
      href: `/${adminBase}/${crmArea}/${customersPropspectsSection}/${customSectionsPath}`,
    }]
  });
  crmArr.push({
    title: t('contacts'),
    description: t('custom_schema_settings_for_contacts'),
    href: `/${adminBase}/${crmArea}/${contactsSection}`,
    sectionLinks: [{
      type,
      text: t('default_sections'),
      href: `/${adminBase}/${crmArea}/${contactsSection}`,
    },{
      type,
      text: t('custom_sections'),
      href: `/${adminBase}/${crmArea}/${contactsSection}/${customSectionsPath}`,
    }]
  });
  crmArr.push({
    title: t('opportunities'),
    description: t('custom_schema_settings_for_opportunities'),
    href: `/${adminBase}/${crmArea}/${opportunitiesSection}`,
    sectionLinks: [{
      type,
      text: t('default_sections'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}`,
    },{
      type,
      text: t('custom_sections'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}/${customSectionsPath}`,
    },{
      type,
      text: t('stages'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}/${stagesPath}`,
    }]
  });
  crmArr.push({
    title: t('interactions'),
    description: t('custom_schema_settings_for_interactions'),
    href: `/${adminBase}/${crmArea}/${interactionsSection}`,
    sectionLinks: [{
      type,
      text: t('fields'),
      href: `/${adminBase}/${crmArea}/${interactionsSection}`,
    }]
  });
  crmArr.push({
    title: t('crm_export'),
    description: t('download_crm_data_to_csv'),
    href: `/${adminBase}/${crmArea}/${exportSection}`,
  });
  return crmArr;
});

const intelligenceLinks = computed(() => {
  const intelligenceArr = [];
  const permissions = permissionsComputed.value;

  if (!permissions[intelligence_admin]?.read) return [];

  intelligenceArr.push({
    title: t('enquiries'),
    description: t('manage_customer_viewing_mode'),
    href: `/${adminBase}/${intelligenceArea}`
  });
  if (permissions[product_associations_admin]?.update) {
    const assObj = {
      title: t('associations'),
      description: t('manage_product_associations_and_exceptions'),
      href: `/${adminBase}/${intelligenceArea}/${associationsSection}`,
      sectionLinks: [{
        type,
        text: t('products'),
        href: `/${adminBase}/${intelligenceArea}/${associationsSection}`,
      }]
    };
    if (permissions[product_blacklist]?.read) {
      assObj.sectionLinks.push({
        type,
        text: t('exceptions'),
        href: `/${adminBase}/${intelligenceArea}/${associationsSection}/${blacklistPath}`,
      });
    }
    intelligenceArr.push(assObj);
  }
  if (permissions[product_communities_admin]?.update) {
    intelligenceArr.push({
      title: t('communities'),
      description: t('manage_and_setup_product_communities'),
      href: `/${adminBase}/${intelligenceArea}/${communitiesSection}`,
    });
  }
  intelligenceArr.push({
    title: t('snapshot_by_analysis_field'),
    description: t('create_a_custom_snapshot_by_selecting_an_analysis_field'),
    href: `/${intelligenceArea}/${insightsScope}/${performanceScope}/${snapshotByAnalysisField}`,
  });
  return intelligenceArr;
});
</script>

<style lang="scss" scoped>
</style>
