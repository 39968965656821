import { getAllMutations, getAllActions } from './getAll';
import { getWidgetByIdMutations, getWidgetByIdActions } from './getWidgetById';
import { getByIdMutations, getByIdActions } from './getById';
import { postMutations, postActions } from './post';
import { postItemMutations, postItemActions } from './itemPost';
import { patchItemMutations, patchItemActions } from './itemPatch';
import { deleteItemMutations, deleteItemActions } from './itemDelete';
import { patchMutations, patchdActions } from './patch';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { postSubjectsMutations, postSubjectsActions } from './subjectsPost';

// initial state
const state = () => ({
  all: {
    data: [],
    loaded: false,
    loading: false,
  },
  selected: {
    data: {
      widgets: []
    },
    loaded: false,
    loading: false,
    deleted: false
  },
  isQueryStringChanged: false,
  showUnsavedChangesModal: false,
});

const getters = {};

// mutations
const mutations = {
  ...getAllMutations,
  ...getByIdMutations,
  ...getWidgetByIdMutations,
  ...postMutations,
  ...patchMutations,
  ...deleteByIdMutations,
  ...postItemMutations,
  ...patchItemMutations,
  ...deleteItemMutations,
  ...postSubjectsMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getByIdActions,
  ...getWidgetByIdActions,
  ...postActions,
  ...patchdActions,
  ...deleteByIdActions,
  ...postItemActions,
  ...patchItemActions,
  ...deleteItemActions,
  ...postSubjectsActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};