<template>
  <div class="measurement">
    <label
      for="measurementSelect"
      :class="{ 'visually-hidden': !isMobile }"
    >
      {{ t('measurement') }}
    </label>
    <CustomSelect
      id="measurementSelect"
      class="measurement"
      :disabled="props.disabled"
      :value="selectedMeasurement"
      type="select"
      :items="measurementOptions"
      @on-change="handleMeasurementSelection"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomSelect } from '@sales-i/dsv3';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  selectedMeasurement: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['measurement-selected', 'validity-changed']);

const mq = useMq();
const selectedMeasurement = ref(props.selectedMeasurement || '');
const measurementOptions = ref([
  {
    text: t('please_select'),
    value: ''
  },
  {
    text: t('sales_value'),
    value: 'Sales Value'
  },
  {
    text: t('gp'),
    value: 'GP'
  },
  {
    text: t('profit'),
    value: 'Profit'
  },
  {
    text: t('transaction_count'),
    value: 'Transaction Count'
  },
  {
    text: t('cost'),
    value: 'Cost'
  },
  {
    text: t('quantity'),
    value: 'Quantity'
  }
]);
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const isValid = computed(() => {
  return selectedMeasurement.value !== '';
});

const handleMeasurementSelection = (value) => {
  selectedMeasurement.value = value;
  emit('measurement-selected', value);
  emit('validity-changed', isValid.value);
};

watch(() => props.selectedMeasurement, (newValue) => {
  selectedMeasurement.value = newValue;
});
</script>

<style lang="scss" scoped>
// Override the transparent background colour of the select input
.measurement  {
  margin-bottom: 0;
}
</style>