import { getAllMutations, getAllActions } from './getAll';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { getHistoryByIdMutations, getHistoryByIdActions } from './getHistoryById';
import { getByIdMutations, getByIdActions } from './getById';
import { getSectionMutations, getSectionActions  } from '@/shared/store/modules/customSections/getCustomSection';
import { updateMutations, updateActions, updateGetters } from './update';
import { stagesMutations, stagesActions } from './stages';
import { getSummaryMutations, getSummaryActions } from './getSummary';

// initial state
const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
    error: undefined,
  },
  customSection: {
    data: {},
    loaded: false,
    loading: false,
  },
  selectedHistory: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  summary: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  selectedItems: [],
  stages: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  sorting: [
    {
      text: 'Customer/Prospect ASC - DES',
      value: 'entity_name:asc',
    },
    {
      text: 'Customer/Prospect DES - ASC',
      value: 'entity_name:desc',
    },
    {
      text: 'Value ASC - DES',
      value: 'value:asc',
    },
    {
      text: 'Value DES - ASC',
      value: 'value:desc',
    },
    {
      text: 'Decision Date ASC - DES',
      value: 'decision_date:asc',
    },
    {
      text: 'Decision Date DES - ASC',
      value: 'decision_date:desc',
    },
    {
      text: '% Probability ASC - DES',
      value: 'probability:asc',
    },
    {
      text: '% Probability DES - ASC',
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {
  ...updateGetters,
};

// mutations
const mutations = {
  ...deleteByIdMutations,
  ...getByIdMutations,
  ...getByIdMutations,
  ...updateMutations,
  ...getAllMutations,
  ...stagesMutations,
  ...getHistoryByIdMutations,
  ...getSectionMutations,
  ...getSummaryMutations,
};

// actions
const actions = {
  ...deleteByIdActions,
  ...getByIdActions,
  ...updateActions,
  ...getAllActions,
  ...stagesActions,
  ...getHistoryByIdActions,
  ...getSectionActions,
  ...getSummaryActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

