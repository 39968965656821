<template>
  <div 
    ref="dropdownTrigger"
    class="relative"
    @click.stop
  > 
    <label
      :for="id"
      :class="{ 'visually-hidden': !isMobile }"
    >
      <slot name="label" />
    </label>
    <button
      :id="id"
      :disabled="props.disabled"
      type="button"
      class="dropdown-label"
      :aria-expanded="isDropdownVisible"
      aria-controls="dropdownList"
      @click="handleTriggerClick"
    >
      <slot name="trigger" />
    </button>

    <Teleport
      v-if="!isMobile"
      to="body"
    >
      <button
        v-if="isDropdownVisible"
        id="dropdownList"
        ref="dropdownList"
        type="button"
        :style="dropdownStyles"
        :class="['dropdown-list', { 'dropdown-list--visible': isDropdownVisible }]"
        @keydown.tab="handleTab"
        @keydown.esc="close"
      >
        <div 
          ref="dropdownContent"
          class="dropdown-list__content"
          tabindex="0"
        >
          <slot name="content" />
        </div>

        <div
          v-if="showFooter"
          class="dropdown-list__footer"
        >
          <slot name="footer" />
        </div>
      </button>
    </Teleport>
    <div v-else>
      <button
        v-if="isDropdownVisible"
        id="dropdownList"
        ref="dropdownList"
        type="button"
        :style="dropdownStyles"
        :class="['dropdown-list', { 'dropdown-list--visible': isDropdownVisible }]"
        @keydown.tab="handleTab"
        @keydown.esc="close"
      >
        <div 
          ref="dropdownContent"
          class="dropdown-list__content"
          tabindex="0"
        >
          <slot name="content" />
        </div>

        <div
          v-if="showFooter"
          class="dropdown-list__footer"
        >
          <slot name="footer" />
        </div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onUpdated, nextTick, watch } from 'vue';
import { defineEmits } from 'vue';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    required: true
  },
  isDropdownVisible: {
    type: Boolean,
    default: false
  },
  showFooter: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['triggerClick', 'update:isDropdownVisible']);
const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

const getDropdownPosition = (triggerElement) => {
  if (!triggerElement) return null;

  const triggerRect = triggerElement.getBoundingClientRect();
  const viewportWidth = window.innerWidth;

  // Get the scroll position of the page
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  // Calculate available space on the right
  const spaceRight = viewportWidth - (triggerRect.left - scrollLeft);

  // Set default position below the trigger
  let top = `${triggerRect.bottom + scrollTop}px`;
  let left = `${triggerRect.left + scrollLeft}px`;
  let transformOrigin = 'top left';
  
  // Calculate maximum width (either 300px or available space minus padding)
  const maxWidth = Math.min(300, spaceRight - 24);

  // Add a small offset to prevent the dropdown from touching the trigger
  const verticalOffset = 1;

  return {
    // position: 'absolute',
    top,
    left,
    width: 'auto',
    minWidth: `${triggerRect.width}px`,
    maxWidth: `${maxWidth}px`,
    transformOrigin,
    transform: `translate3d(0, ${verticalOffset}px, 0)`,
    zIndex: 1000
  };
};

const dropdownTrigger = ref(null);
const dropdownStyles = ref({});
const dropdownList = ref(null);
const dropdownContent = ref(null);

onMounted(() => {
  setDropdownMaxHeight();
  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);
  window.addEventListener('scroll', updatePosition, true);
  window.addEventListener('resize', updatePosition);
});

onUpdated(() => {
  setDropdownMaxHeight();
});
  
onUnmounted(() => {
  document.removeEventListener('mousedown', handleClickOutside);
  document.removeEventListener('touchstart', handleClickOutside);
  window.removeEventListener('scroll', updatePosition, true);
  window.removeEventListener('resize', updatePosition);
});

// Watch for visibility changes to update position
watch(() => props.isDropdownVisible, (newValue) => {
  if (newValue) {
    nextTick(() => {
      updatePosition();
    });
  }
});

const handleTriggerClick = () => {
  const position = getDropdownPosition(dropdownTrigger.value);
  if (position) {
    dropdownStyles.value = position;
  }
  
  emit('triggerClick', props.id);
  const newVisibility = !props.isDropdownVisible;
  emit('update:isDropdownVisible', newVisibility);

  if (newVisibility) {
    nextTick(() => {
      dropdownContent.value?.focus();
    });
  }
};

const close = () => {
  emit('update:isDropdownVisible', false);
  dropdownTrigger.value?.focus();
};

const handleTab = (event) => {
  const focusableElements = dropdownList.value?.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
  
  if (!focusableElements?.length) return;
  
  const firstElement = focusableElements[0];
  const lastElement = focusableElements[focusableElements.length - 1];
  
  if (event.shiftKey && document.activeElement === firstElement) {
    event.preventDefault();
    lastElement.focus();
  } else if (!event.shiftKey && document.activeElement === lastElement) {
    event.preventDefault();
    firstElement.focus();
  }
};

// Update position on scroll or resize
const updatePosition = () => {
  if (props.isDropdownVisible && dropdownTrigger.value) {
    dropdownStyles.value = getDropdownPosition(dropdownTrigger.value);
  }
};

function setDropdownMaxHeight() {
  // This ensures that the apply button in the dropdown is always visible.
  // On mobile we have a footer that takes up space at the bottom.
  // To accomodate this we need to calculate the available height differently when this appears.
  if (dropdownList.value) {
    const rect = dropdownList.value.getBoundingClientRect();
    const availableHeight = window.innerHeight - rect.top;
    const offset = isMobile.value ? 116 : 8;
    dropdownList.value.style.maxHeight = `${availableHeight - offset}px`;
  }
}

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  // Return early if dropdownList isn't visible
  if (!dropdownList.value) return;

  // Check if click was inside dropdown or trigger
  const clickedDropdown = dropdownList.value.contains(event.target);
  const clickedTrigger = dropdownTrigger.value?.contains(event.target);
  
  // If click was outside both, close the dropdown
  if (!clickedDropdown && !clickedTrigger) {
    close();
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.relative:focus {
  box-shadow: 0 0 0 6px var(--colour-utility-focus);
  border-radius: var(--border-radius-half);
}

.dropdown-label {
  position: relative;
  width: 100%;
}

// If the dropdown trigger is a read-only input, use the default cursor style rather than the default text cursor
.dropdown-label :deep(input:read-only) {
  cursor: default;
}

.dropdown-list {
  border-bottom-left-radius: var(--border-radius-half);
  border-bottom-right-radius: var(--border-radius-half);
  background: var(--colour-utility-white);
  top: 77px;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 1px var(--border-utility-base);
  overflow-y: auto;
  z-index: 10;

  // These styles are need to fix the footer to the bottom of this container
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    // top: auto !important;
    // left: 0 !important;
    // right: 0 !important;
    // bottom: 0;
    // width: 100% !important;
    // max-width: none !important;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    // transform: translateY(100%);
  }
}

.dropdown-list--visible {
  transform: scale(1, 1);
}

.dropdown-list__content {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  width: 100%;
}

.dropdown-list__footer {
  background-color: var(--colour-panel-g-4);
  display: flex;
  place-content: center;
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-3);
  width: 100%;
}
</style>