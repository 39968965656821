import { computed } from 'vue';
import { useStore } from 'vuex';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

import { GET_BY_ID, CLEAR_BY_ID, DELETE_BY_ID} from '@/shared/store/actionType';
import { GET_CONTACTS, CLEAR_CONTACTS } from '@/crm/store/actionType';

export const useProspect = ({ store = useStore() } = {}) => {
  const selectedProspect = computed(() => store.state.crm.prospects.selected.data);
  const selectedProspectLoading = computed(() => store.state.crm.prospects.selected.loading);
  const selectedProspectLoaded = computed(() => store.state.crm.prospects.selected.loaded);
  
  const getProspectById = params => store.dispatch(`crm/prospects/${GET_BY_ID}`, params);
  const clearProspectById = () => store.dispatch(`crm/prospects/${CLEAR_BY_ID}`);
  const deleteProspect = params => store.dispatch(`crm/prospects/${DELETE_BY_ID}`, params);
  const getProspectContacts = params => store.dispatch(`crm/prospects/${GET_CONTACTS}`, params);
  const clearProspectContacts = params => store.dispatch(`crm/prospects/${CLEAR_CONTACTS}`, params);

  const searchAnalysisFields = async ({ id, q = '' }) => {
    try {
      const params = querystring.stringify({ q });
      const url = `prospects/analysis-fields/${encodeURIComponent(id)}?${params}`;
      const response = await httpRequest('get', url);
      return response;
    } catch (error) {
      console.error('Error fetching analysis fields:', error);
      return [];
    }
  };

  const fetchAnalysisFields = async () => {
    try {
      const response = await httpRequest('get', 'prospects/analysis-fields');
      return response;
    } catch (error) {
      console.error('Error fetching analysis fields:', error);
      return false;
    }
  };

  return {
    selectedProspect,
    selectedProspectLoading,
    selectedProspectLoaded,
    getProspectById,
    clearProspectById,
    deleteProspect,
    getProspectContacts,
    clearProspectContacts,
    searchAnalysisFields,
    fetchAnalysisFields,
  };
};

