<template>
  <div class="disclosure">
    <details v-if="remainingValues.length >= 1">
      <summary>
        <span>{{ firstValue }}
          <span class="trigger"> (+{{ remainingValues.length }})</span>
        </span>
      </summary>
      <ul
        :class="{ tooltip: tooltip }"
      >
        <li
          v-for="(value, idx) in remainingValues"
          :key="idx"
        >
          {{ value }}
        </li>
      </ul>
    </details>
    <div v-else>
      {{ firstValue }}
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
const props = defineProps({
  tooltip: {
    type: Boolean,
    default: false
  },
  values: {
    type: Array,
    required: true,
    default: () => []
  }
});

const firstValue = computed(() => props.values[0] || '');
const remainingValues = computed(() => props.values.slice(1));
</script>

<style lang="scss" scoped>
details > summary:-webkit-details-marker {
  display: none;
}

.disclosure {
  display: flex;
  gap: var(--spacing-1);
}

  details {
    display: inline;
  }

    summary {
      display: flex;
    }

    summary .trigger {
      color: var(--colour-utility-action);
      cursor: pointer;
      text-decoration: underline;
    }

    ul.tooltip {
      background-color: var(--colour-utility-white);
      border: 1px solid var(--colour-panel-g-16);
      border-radius: var(--border-radius-1);
      position: absolute;
      z-index: 1;
      line-height: 2.5;
      padding-inline: var(--spacing-2);
      padding-block: var(--spacing-half);
      min-width: var(--spacing-12);
      list-style-type: none;
      box-shadow: var(--shadow-2);
    }
</style>