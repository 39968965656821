<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    v-click-outside="closeMenu"
    class="export-menu-wrapper"
  >
    <CustomButton
      class="export-button"
      purpose="action"
      :label="t('export')"
      icon-name="caret-down"
      icon-color="var(--colour-utility-white)"
      icon-align="right"
      small
      :disabled="isExportDisabled"
      @click="toggleMenu"
    />
    <ul
      v-if="isOpen"
      class="dropdown-menu"
    >
      <li
        class="dropdown-item"
        @click="handleExport('normalised')"
      >
        <h4>{{ t('export_as_multiple_files') }}</h4>
        <p>{{ t('you_ll_download_multiple_files_for_the_crm_objects_you_need_in_the_crm_export') }}</p>
      </li>
      <li
        class="dropdown-item"
        @click="handleExport('denormalised')"
      >
        <h4>{{ t('export_as_one_file') }}</h4>
        <p>{{ t('you_ll_download_a_single_combined_csv_file_with_all_of_the_account_types_and_crm_objects_you_ve_selected') }}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import { ClickOutside } from 'vue-click-outside-of';

const vClickOutside = ClickOutside;

defineProps({
  isExportDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['export']);

const isOpen = ref(false);

function toggleMenu() {
  isOpen.value = !isOpen.value;
}

function closeMenu() {
  isOpen.value = false;
}

function handleExport(type) {
  emit('export', type);
  isOpen.value = false;
}
</script>

<style lang="scss" scoped>
.export-button {
  padding: var(--spacing-1) var(--spacing-4);
}

.export-menu-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--colout-utility-white);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  border-radius: var(--border-radius-1);
  margin-top: var(--spacing-half);
  width: 250px;
  z-index: 1;
}

.dropdown-item {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--colour-panel-g-32);
  background-color: var(--colour-utility-white);
}

.dropdown-item h4 {
  color: var(--colour-utility-action);
  margin-bottom: var(--spacing-half);
}

.dropdown-item p {
  margin: 0;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background: var(--colour-panel-g-4);
}
</style>