// <!-- todo: translate -->
import { computed, ref } from 'vue';
import { CLEAR_CURRENT_TARGET, ENABLE_TARGETS, FETCH_REPORT_DATA, SAVE_CURRENT_DIMENSIONS, SAVE_CURRENT_VALUES, SAVE_WHOLE_DIM, TOGGLE_TARGETS_SWITCH, } from '@/intelligence/store/actionType';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';
import useAdminUsers from '@/admin/composables/useAdminUsers';
import {
  GET_BY_ID,
  SET_CONFIRMATION_MODAL
} from '@/shared/store/actionType';
import { usePagination } from '@/shared/composables/usePagination';

const initialFormData = {
  selectedAnalysisField: '',
  selectedProperties: [],
  selectedUsers: [],
  selectedDate: {
    interval: undefined,
    expires: undefined
  },
  selectedMeasurement: undefined,
  targetAmount: ''
};

const initialFormState = {
  analysisField: '',
  properties: [],
  users: [],
  date: {
    interval: undefined,
    expires: undefined
  },
  measurement: undefined,
  targetAmount: ''
};

export default function useTargets({ store = useStore() } = {}) {
  const { fieldsLookup } = useAdminUsers({ store });
  const formState = ref({ ...initialFormState });
  const validityState = ref({
    analysisFields: false,
    property: false,
    users: false,
    date: false,
    measurement: false,
    targetAmount: false
  });
  
  const propertyOptions = ref([]);
  const selectedProperties = ref([]);
  const selectedUsers = ref([]);
  const daysFromInterval = {
    WEEKLY: 7,
    MONTHLY: 30,
    QUARTERLY: 90,
    YEARLY: 365
  };
  const targets = ref([]);
  const editingTargetId = ref(null);
  const targetToEdit = ref(null);
  const isEditMode = ref(false);
  const limit = ref(50); // Default value
  const offset = ref(0);

  const isAnalysisFieldSelected = computed(() => formData.value.selectedAnalysisField !== '');
  const isPropertyFieldSelected = computed(() => formData.value.selectedProperties.length > 0);
  const isUserFieldSelected = computed(() => formData.value.selectedUsers.length > 0);
  const isDateFieldSelected = computed(() => formData.value.selectedDate.interval !== undefined && formData.value.selectedDate.expires !== undefined);
  const isMeasurementFieldSelected = computed(() => formData.value.selectedMeasurement !== undefined);
  const isTargetsSwitchActive = computed(() => store.state.intelligence.targets.isTargetsSwitchActive);
  const areTargetsEnabled = computed(() => store.state.intelligence.targets.areTargetsEnabled);  
  const currentTargetValues = computed(() => store.state.intelligence.targets.currentTargetValues);
  const valid = computed(() => currentTargetValues.value.length && currentTargetValues.value.every(item => item.values.length || item.checkedAll));
  const measurements = computed(() => store.state.intelligence.targets.measurementsData);
  const targetsData = computed(() => store.state.intelligence.targets.targetsData || { targets: [] });
  const isFormValid = computed(() => Object.values(validityState.value).every(value => value === true));
  
  // Disabled states for form fields
  const disabledStates = computed(() => ({
    property: !formState.value.analysisField,
    users: !formState.value.properties.length,
    date: !formState.value.users.length,
    measurement: !formState.value.date.interval || !formState.value.date.expires,
    targetAmount: !formState.value.measurement
  }));

  const updateValidity = (field, value) => {
    validityState.value[field] = value;
  };

  const resetForm = () => {
    formState.value = { ...initialFormState };
    Object.keys(validityState.value).forEach(key => {
      validityState.value[key] = false;
    });
    propertyOptions.value = [];
  };

  // helpers
  const metricOptions = computed(() => measurements.value.length ? measurements.value.map(el => (
    { unit: el.unit, text: el.metric, value: el.id }
  )) : []);

  const formatTargetPeriod = computed(() => {
    return (period) => {
      if (!period) return '';
      return `${period.interval.charAt(0) + period.interval.slice(1).toLowerCase()} at ${period.expires}`;
    };
  });
  
  const getTarget = id => store.dispatch(`intelligence/targets/${GET_BY_ID}`, id);
  const toggleTargetsSwitch = (params) => store.dispatch(`intelligence/targets/${TOGGLE_TARGETS_SWITCH}`, params);
  const enableTargets = value => store.dispatch(`intelligence/targets/${ENABLE_TARGETS}`, value);
  const clearCurrentTarget = () => store.dispatch(`intelligence/targets/${CLEAR_CURRENT_TARGET}`);
  const queryTargetData = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const saveCurrentValues = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_VALUES}`, payload);
  const saveWholeDim = payload => store.dispatch(`intelligence/targets/${SAVE_WHOLE_DIM}`, payload);
  const saveCurrentDims = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_DIMENSIONS}`, payload);
  const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const showConfirmationModal = (payload) => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, payload);

  // Create a local array of the data the user has entered so far
  const formData = ref({ ...initialFormData });

  // Convert the target data into a format that the form expects
  const convertTargetData = async (target) => {
    if (!target) return;

    const field = target.analysis_fields[0];
    
    const analysisFieldData = {
      entity: field.area.toLowerCase(),
      fieldId: field.analysis_field_id,
      fieldName: field.title
    };

    await fetchFieldsValue(field.area, field.analysis_field_id);

    // Store the mapped properties temporarily
    const properties = propertyOptions.value.filter(option => 
      field.values.some(v => v.code === option.code)
    );

    // Return the properties separately
    return {
      data: {
        selectedAnalysisField: analysisFieldData,
        selectedProperties: properties,
        selectedUsers: [],
        selectedDate: {
          interval: target.target_period?.interval,
          expires: target.target_period?.expires
        },
        selectedMeasurement: target.target_value?.measurement.metric,
        targetAmount: target.target_value.value?.toString()
      },
      properties: properties
    };
  };

  // Get the field values from the store
  const fetchFieldsValue = async (entity, fieldId) => {
    await fieldsLookup({
      entity: entity,
      id: fieldId
    });
    
    const fieldsDataValue = store.state.admin.users.fields.data[entity] || [];
    
    // Update propertyOptions with the fetched values
    propertyOptions.value = fieldsDataValue;
  };

  const handleFieldSelection = async (value) => {
    if (value) {
      const { entity, fieldId, fieldName } = value;
      formData.value.selectedAnalysisField = value;
  
      // Fetch field values for the selected field
      await fetchFieldsValue(entity, fieldId, fieldName);
    }
  };

  const handlePropertySelection = (value) => {
    selectedProperties.value = value;
    formData.value.selectedProperties = value;
  };

  const handleUserSelection = (value) => {
    selectedUsers.value = value;
    formData.value.selectedUsers = value;
  };
  
  const handleIntervalSelection = (value) => {
    formData.value.selectedDate.interval = value;
  };
  
  const handleExpiryYearSelection = (event) => {
    formData.value.selectedDate.expires = event;
  };
    
  const handleMeasurementSelection = (event) => {
    formData.value.selectedMeasurement = event;
  };
  
  const handleTargetAmount = (event) => {
    formData.value.targetAmount = event;
  };

  const resetFormData = (validity) => {
    // Reset the formData object to its initial state
    formData.value = { ...initialFormData };
    
    // Reset propertyOptions and selectedProperties
    propertyOptions.value = [];
    selectedProperties.value = [];

    if (validity) {
      validity.value = {
        analysisFields: false,
        date: false,
        measurement: false,
        property: false,
        targetAmount: false,
      };
    }

    return formData.value;
  };

  const getAnalysisPropertyValues = (field) => {
    return field.values.map(v => v.value);
  };
  
  async function loadPageData() {
    const response = await queryTargetData({
      type: 'get',
      params: {
        limit: limit.value,
        offset: offset.value
      }
    });
    targets.value = response?.targets;
  }

  const setupPagination = (props, emit) => {
    const { limit, offset, handlePageChanged } = usePagination({
      ...props,
      twoWayPagination: false,
      pageLimit: 50,
    }, loadPageData, emit);


    return {
      limit,
      offset,
      handlePageChanged,
      loadPageData,
      targets
    };
  };
  


  const handleEditTarget = async (targetId) => {
    const response = await getTarget(targetId);
    
    if (response) {
      editingTargetId.value = targetId;
      targetToEdit.value = response;
      isEditMode.value = true;
    }
    
    return {
      editingTargetId: editingTargetId.value,
      targetToEdit: targetToEdit.value,
      isEditMode: isEditMode.value
    };
  };

  const handleDeleteTarget = async (targetId) => {
    return new Promise((resolve) => {
      showConfirmationModal({
        title: t('Delete target'),
        message: t('Do you really want to delete this target?'),
        updatedMessage: t('You have deleted the target.'),
        updateFunction: async () => {
          try {
            const response = await deleteTarget({
              type: 'delete',
              params: { targetId }
            });

            if (response) {
              await loadPageData();
              resolve(true);
            }
            return true;
          } catch (e) {
            resolve(false);
            return false;
          }
        }
      });
    });
  };

  const handleDropdownAction = async (action, targetId) => {
    const actions = {
      'edit': handleEditTarget,
      'delete': handleDeleteTarget
    };

    if (actions[action]) {
      return await actions[action](targetId);
    }
  };

  const cancelEdit = () => {
    editingTargetId.value = null;
    targetToEdit.value = null;
    isEditMode.value = false;
    
    return {
      editingTargetId: editingTargetId.value,
      targetToEdit: targetToEdit.value,
      isEditMode: isEditMode.value
    };
  };

  return {
    targetsData,
    currentTargetValues,
    isTargetsSwitchActive,
    areTargetsEnabled,
    valid,
    measurements,
    metricOptions,
    propertyOptions,
    getTarget,
    toggleTargetsSwitch,
    enableTargets,
    clearCurrentTarget,
    queryTargetData,
    saveCurrentValues,
    saveWholeDim,
    saveCurrentDims,
    deleteTarget,
    handleFieldSelection,
    formData,
    selectedProperties,
    fetchFieldsValue,
    isAnalysisFieldSelected,
    isPropertyFieldSelected,
    isUserFieldSelected,
    isDateFieldSelected,
    isMeasurementFieldSelected,
    handlePropertySelection,
    handleUserSelection,
    handleIntervalSelection,
    handleExpiryYearSelection,
    handleMeasurementSelection,
    handleTargetAmount,
    resetFormData,
    getAnalysisPropertyValues,
    formatTargetPeriod,
    daysFromInterval,
    setupPagination,
    targets,
    showConfirmationModal,
    isFormValid,
    disabledStates,
    updateValidity,
    resetForm,
    formState,
    convertTargetData,
    handleEditTarget,
    handleDeleteTarget,
    handleDropdownAction,
    cancelEdit,
    editingTargetId,
    targetToEdit,
    isEditMode
  };
}