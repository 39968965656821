import { createRouter, createWebHistory } from 'vue-router';
import AdminRoutes from '@/admin/router';

const routes = [
  ...AdminRoutes,
];

const router = createRouter({
  history: createWebHistory(), // THIS NEEDS TO BE HASH UNTIL HOSTING CHANGE BECAUSE WE CAN'T CONFIG (HOSTING SIDE) PROPERLY CURRENTLY
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 64, // Takes into account the fixed header
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
