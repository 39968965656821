// const customClickOutside = {
//   beforeMount: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent);
//   },
//   unmounted: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent);
//   },
// };

export const scrollIntoView = () => {
  document.getElementById('single-spa-application:@sales-i/admin').scrollIntoView();
};

/**
 * Scroll to the top of a page when this directive is added to the <router-link> tag
 */
const scrollToTop = {
  beforeMount: function (el) {
    el.addEventListener('click', () => {
      scrollIntoView();
    });
  }
};

export { /*customClickOutside,*/ scrollToTop };
