<template>
  <CustomCard
    purpose="reversed"
    class="stages"
  >
    <LoadingOverlay
      v-show="loading"
      style="border-radius: var(--border-radius-1)"
    />
    <Stages
      :value="stages"
      :loading="loading"
      :name="t('Stages')"
      @add="addStage"
      @move="moveStage"
      @edit="editStage"
      @delete="delStageWithConfirmation"
      @save="patchStages"
    >
      <template #top />
    </Stages>
    <ConfirmationModal
      v-if="isDeleteModal"
      v-bind="deleteModalProps"
      :save-button-options="saveButtonOptions"
    >
      <Field
        :value="to_stage_id"
        :label="t('Select a stage for existing opportunities')"
        type="select"
        :options="stageOptionsForDelete"
        required
        twoway
        @input="handleToStageIdInput"
      />
    </ConfirmationModal>
  </CustomCard>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomCard, LoadingOverlay } from '@sales-i/dsv3';
import Stages from '@/admin/components/Opportunities/Stages.vue';
import Field from '@/shared/components/Form/Field.vue';
import ConfirmationModal from '@/shared/components/Modal/ConfirmationModal.vue';
import { useOpportunityStages } from '@/admin/composables/useOpportunityStages';

const store = useStore();
const emit = defineEmits(['closeModal']);

const {
  stages,
  loading,
  stageOptionsForDelete,
  saveButtonOptions,
  deleteModalProps,
  isDeleteModal,
  to_stage_id,
  getStages,
  patchStages,
  addStage,
  moveStage,
  editStage,
  delStageWithConfirmation,
  handleToStageIdInput,
} = useOpportunityStages({ store, emit });

onMounted(async () => {
  await getStages();
});
</script>

<style lang="scss" scoped>
.stages {
  position: relative;
}
</style>