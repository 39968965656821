<!-- todo: translate -->
<template>
  <div class="button-wrapper">
    <CustomButton
      purpose="reversed"
      small
      @click="handleCancelCreateTarget"
    >
      {{ t('Cancel') }}
    </CustomButton>
    
    <CustomButton
      v-if="!isEditMode"
      purpose="action"
      small
      :disabled="!isValid"
      @click="handleCreateTarget(formData)"
    >
      {{ t('Create') }}
    </CustomButton>
    
    <CustomButton
      v-if="isEditMode"
      purpose="action"
      small
      @click="handleUpdateTarget(targetId)"
    >
      {{ t('Save') }}
    </CustomButton>

  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({})
  },
  isValid: {
    type: Boolean,
    default: false
  },
  targetToEdit: {
    type: Object,
    default: null
  },
  isEditMode: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['target-cancelled', 'target-created', 'refresh-targets']);

const { queryTargetData } = useTargets();

const targetId = props.targetToEdit?.id ?? null;

async function handleCreateTarget(payload) {
  await queryTargetData({
    type: 'add',
    params: payload
  });
  emit('target-created');
  emit('refresh-targets');
}

async function handleUpdateTarget(targetId) {
  await queryTargetData({
    type: 'update',
    params: {
      ...props.formData,
      targetId
    }
  });
  emit('target-created');
  emit('refresh-targets');
}

function handleCancelCreateTarget () {
  emit('target-cancelled');
}
</script>