<template>
  <div 
    v-if="filter"
    class="filter-row"
  >
    <div class="column">
      {{ filter?.analysis_field_label }}
    </div>
    <div class="column">
      <ul>
        <li
          v-for="(label, index) in filter.analysis_value_labels"
          :key="index"
        >
          {{ label }}
        </li>
      </ul>
    </div>
    <div class="column">
      <IconButton
        class="delete-icon"
        icon-name="trash"
        purpose="transparent"
        icon-color="var(--colour-utility-action)"
        @click="deleteFilter"
      />
    </div>
  </div>
</template>

<script setup>
import { IconButton } from '@sales-i/dsv3';

const props = defineProps({
  filter: {
    type: Object,
    default: () => ({}),
  },
  filterIndex: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['delete-filter']);

function deleteFilter() {
  emit('delete-filter', props.filterIndex);
}
</script>

<style lang="scss" scoped>
.filter-row {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-2);
}

.column {
  flex: 1;
  display: flex;
  align-items: center;
}

.delete-icon {
  margin-left: auto;
}
</style>