<template>
  <p>{{ t('choose_the_account_types_to_be_exported') }}</p>
  <CustomCheckbox
    :label="t('customer')"
    class="account-type-checkbox"
    :value="accountTypes.customer"
    @input="handleAccountType($event, 'customer')"
  />
  <CustomCheckbox
    :label="t('prospect')"
    class="account-type-checkbox"
    :value="accountTypes.prospect"
    @input="handleAccountType($event, 'prospect')"
  />
  <p>{{ t('which_crm_objects_do_you_want_to_include_with_your_accounts') }}</p>
  <CustomCheckbox
    :label="t('contacts')"
    class="account-type-checkbox"
    :value="crmObjects.contact"
    @input="handleCrmObjects($event, 'contact')"
  />
  <CustomCheckbox
    :label="t('opportunities')"
    class="account-type-checkbox"
    :value="crmObjects.opportunity"
    @input="handleCrmObjects($event, 'opportunity')"
  />
  <CustomCheckbox
    :label="t('interactions')"
    class="account-type-checkbox"
    :value="crmObjects.interaction"
    @input="handleCrmObjects($event, 'interaction')"
  />
</template>

<script setup>
import { tAdmin as t } from '@sales-i/utils';
import { CustomCheckbox } from '@sales-i/dsv3';

const emit = defineEmits(['update:accountTypes', 'update:crmObjects']);

defineProps({
  accountTypes: {
    type: Object,
    default: () => ({
      customer: false,
      prospect: false,
    }),
  },
  crmObjects: {
    type: Object,
    default: () => ({
      contact: false,
      opportunity: false,
      interaction: false,
    }),
  },
});

const handleAccountType = (event, type) => {
  emit('update:accountTypes', { type, value: event });
};

const handleCrmObjects = (event, type) => {
  emit('update:crmObjects', { type, value: event });
};
</script>

<style lang="scss" scoped>
.account-type-checkbox {
  margin: var(--spacing-2) var(--spacing-3);
}
</style>
