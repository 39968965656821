<!-- todo: translate -->
<template>
  <div class="users flow">
    <DropDown
      id="users"
      :is-dropdown-visible="dropdownVisibility.users"
      :show-footer="showFooter"
      :disabled="disabled"
      @trigger-click="toggleDropdown"
      @update:is-dropdown-visible="updateDropdownVisibility('users', $event)"
    >
      <template #label>
        {{ t('users') }}
      </template>

      <template #trigger>
        <DropDownTriggerSelect
          :disabled="disabled"
          :value="selectedUsersText"
        >
          {{ selectedUsersText || t('select_users') }}
        </DropDownTriggerSelect>
      </template>

      <template #content>
        <div class="p-2 flow">
          <CustomCheckbox
            v-for="value in usersOptions"
            :key="value.value"
            :label="value.text"
            :value="isChecked(value)"
            @input="updateTempSelectedUser(value)"
          />

          <div
            v-if="usersOptions.length >= limit"
            class="load-more-wrapper"
          >
            <CustomButton
              purpose="neutral"
              small
              :loading="isLoading"
              @click="loadMore"
            >
              {{ t('more') }}
            </CustomButton>
          </div>
        </div>
      </template>

      <template
        v-if="showFooter"
        #footer
      >
        <CustomButton
          purpose="action"
          small
          @click="applyUserSelection"
        >
          {{ t('apply') }}
        </CustomButton>
      </template>
    </DropDown>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton, CustomCheckbox } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';
import DropDown from '@/admin/components/Targets/DropDown.vue';
import DropDownTriggerSelect from '@/admin/components/Targets/DropDownTriggerSelect.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  selectedUsers: {
    type: Array,
    default: () => []
  },
  isEditMode: {
    type: Boolean,
    default: false
  },
  usersOptions: {
    type: Array,
    default: () => []
  },
  limit: {
    type: Number,
    default: 50
  }
});

const emit = defineEmits(['user-selected', 'validity-changed', 'load-more']);

const store = useStore();

const {
  formData,
  handleUserSelection,
} = useTargets({ store });

const dropdownVisibility = ref({ users: false });
const tempSelectedUser = ref([...props.selectedUsers] || []);
const isLoading = ref(false);
const currentOffset = ref(0);

const showFooter = computed(() => {
  return JSON.stringify(tempSelectedUser.value) !== JSON.stringify(formData.selectedUsers);
});

const isChecked = (value) => {
  return tempSelectedUser.value.some(item => item.value === value.value);
};

const updateTempSelectedUser = (value) => {
  const index = tempSelectedUser.value.findIndex(item => item.value === value.value);

  if (index === -1) {
    tempSelectedUser.value.push(value);
  } else {
    tempSelectedUser.value.splice(index, 1);
  }
};

const applyUserSelection = () => {
  handleUserSelection(tempSelectedUser.value);
  
  emit('user-selected', [...tempSelectedUser.value]);
  updateDropdownVisibility('users', false);
};


const loadMore = async () => {
  isLoading.value = true;
  currentOffset.value += props.limit;
  
  // Emit event to parent component to load more data
  emit('load-more', currentOffset.value);
  
  // Reset loading state after a short delay
  setTimeout(() => {
    isLoading.value = false;
  }, 500);
};


const toggleDropdown = (dropdownId) => {
  dropdownVisibility.value[dropdownId] = !dropdownVisibility.value[dropdownId];
  for (const key in dropdownVisibility.value) {
    if (key !== dropdownId) {
      dropdownVisibility.value[key] = false;
    }
  }
};

const updateDropdownVisibility = (dropdownId, isVisible) => {
  dropdownVisibility.value[dropdownId] = isVisible;
};

// Computed property to generate the string representation of selected users
const selectedUsersText = computed(() => {
  const selected = tempSelectedUser.value;
  if (selected.length > 1) {
    return `${selected[0].text || selected[0].name} (+${selected.length - 1})`;
  } else if (selected.length === 1) {
    return selected[0].text || selected[0].name;
  } else {
    return '';
  }
});
</script>

<style lang="scss" scoped>
  .load-more-wrapper {
    display: grid;
    place-content: center;
  }

  .dropdown-list__footer {
    background-color: var(--colour-panel-g-4);
    display: flex;
    place-content: center;
    padding-block: var(--spacing-2);
    padding-inline: var(--spacing-3);
  }
</style>