<template>
  <div class="padded">
    <LeftHandNavigation
      :loading="customSchema.loading || itemsLoading"
      :items-loading="itemsLoading"
      :items="menuItems"
      :active-section="activeSection"
      :sub-section="subSection"
      @item-clicked="itemClicked"
      @active-section-clicked="setActiveSection"
      @sub-section-clicked="setSubSection"
      @move="move"
      @create-entity="createSection"
    >
      <router-view
        :active-section="subSection"
        :titles="{
          settings: t('entity_settings', {
            entity: 'Opportunities',
          }),
        }"
        :entity="entity"
        :is-custom-section="isCustomSection"
      />
    </LeftHandNavigation>
  </div>
</template>

<script setup>
import { provide, ref } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { useCustomSchema } from '@/admin/composables/useCustomSchema';

const saveRef = ref(null);
const setSaveRef = (val) => saveRef.value = val;

provide('saveRefProvider', {
  saveRef,
  setSaveRef
});

const {
  activeSection,
  subSection,
  isCustomSection,
  itemsLoading,
  customSchema,
  menuItems,
  entity,
  move,
  createSection,
  itemClicked,
  setActiveSection,
  setSubSection,
} = useCustomSchema({saveRef});
</script>

<style lang="scss"></style>
