import { ref, computed } from 'vue';
import { t } from '@sales-i/utils';
import {
  GET_STAGES,
  CLEAR_STAGES,
  PATCH_STAGES,
  SET_STAGES,
  ADD_STAGE,
  MOVE_STAGE,
  EDIT_STAGE,
  DELETE_STAGE,
} from '@/admin/store/actionType';
import { useStore } from 'vuex';

export function useOpportunityStages({ store = useStore(), emit }) {
  const deleteModalProps = ref({});
  const isDeleteModal = ref(false);
  const idToDelete = ref(undefined);
  const to_stage_id = ref(undefined);

  const stages = computed(() => store.state.admin.opportunities.stages.data || []);
  const loading = computed(() => !!store.state.admin.opportunities.stages.loading);

  const stageOptionsForDelete = computed(() => stages.value
    .filter(s => s.id != idToDelete.value)
    .map(s => ({ text: s.name, value: s.id })));

  const saveButtonOptions = computed(() => ({
    disabled: !(idToDelete.value && to_stage_id.value),
  }));

  const getStages = async () => {
    await store.dispatch(`admin/opportunities/${GET_STAGES}`);
  };

  const clearStages = async () => {
    await store.dispatch(`admin/opportunities/${CLEAR_STAGES}`);
  };

  const setStages = async (items) => {
    await store.dispatch(`admin/opportunities/${SET_STAGES}`, items);
  };

  const patchStages = async () => {
    await store.dispatch(`admin/opportunities/${PATCH_STAGES}`);
  };

  const addStage = async (index) => {
    await store.dispatch(`admin/opportunities/${ADD_STAGE}`, index);
  };

  const moveStage = async (payload) => {
    await store.dispatch(`admin/opportunities/${MOVE_STAGE}`, payload);
  };

  const editStage = async (payload) => {
    await store.dispatch(`admin/opportunities/${EDIT_STAGE}`, payload);
  };

  const deleteStage = async (payload) => {
    return await store.dispatch(`admin/opportunities/${DELETE_STAGE}`, payload);
  };

  const delStageWithConfirmation = (payload) => {
    idToDelete.value = payload.item.id;
    deleteModalProps.value = {
      message:
        t(`Do you really want to delete Stage ${payload.index + 1} - ${payload.item.name}?`, '', {
          interpolations: {
            number: payload.index + 1,
            name: payload.item.name,
          },
        }) || t('No label'),
      updatedMessage: `You have deleted Stage ${payload.index + 1} - ${payload.item.name}`,
      updateFunction: confirmDelete,
      finishFunction: () => {
        emit('closeModal');
        getStages();
        return true;
      },
      closeFunction: () => {
        deleteModalProps.value = {};
        idToDelete.value = undefined;
        to_stage_id.value = undefined;
        isDeleteModal.value = false;
      },
    };
    isDeleteModal.value = true;
  };

  const confirmDelete = async () => {
    const success = await deleteStage({
      id: +idToDelete.value,
      to_stage_id: +to_stage_id.value,
    });
    emit('closeModal');
    return success;
  };

  const handleToStageIdInput = (value) => {
    to_stage_id.value = value;
  };

  return {
    stages,
    loading,
    stageOptionsForDelete,
    saveButtonOptions,
    deleteModalProps,
    isDeleteModal,
    idToDelete,
    to_stage_id,
    getStages,
    clearStages,
    setStages,
    patchStages,
    addStage,
    moveStage,
    editStage,
    delStageWithConfirmation,
    confirmDelete,
    handleToStageIdInput,
  };
}