<template>
  <details
    ref="dropdownTrigger"
    @click.stop
    @click="handleDetailsClick"
  >
    <summary
      :id="id"
      class="dropdown-label"
      @click="handleTriggerClick"
    >
      <slot
        name="trigger"
        :disabled="disabled"
      />
    </summary>
    <Teleport
      to="body"
    >
      <div
        v-if="isDropdownVisible"
        id="dropdownList"
        ref="dropdownList"
        :class="['dropdown-list', { 'dropdown-list--visible': isDropdownVisible }]"
        :style="dropdownStyles"
      >
        <div 
          ref="dropdownContent"
          class="dropdown-list__content"
          tabindex="0"
        >
          <slot name="content" />
        </div>
        <div
          v-if="showFooter"
          class="dropdown-list__footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </Teleport>
  </details>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onUpdated, nextTick, watch } from 'vue';
import { defineEmits } from 'vue';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    required: true
  },
  isDropdownVisible: {
    type: Boolean,
    default: false
  },
  showFooter: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['triggerClick', 'update:isDropdownVisible']);
const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

const dropdownTrigger = ref(null);
const dropdownStyles = ref({});
const dropdownList = ref(null);
const dropdownContent = ref(null);
const resizeObserver = ref(null);

const getDropdownPosition = (triggerElement) => {
  if (!triggerElement || !dropdownList.value) return null;

  const triggerRect = triggerElement.getBoundingClientRect();
  const dropdownRect = dropdownList.value.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  
  // Calculate actual dropdown dimensions
  const dropdownHeight = dropdownRect.height;

  // Calculate available space below and above
  const spaceBelow = viewportHeight - triggerRect.bottom;
  const spaceAbove = triggerRect.top;
  const spaceRight = viewportWidth - triggerRect.left;

  // Determine if the dropdown should appear above or below
  // Compare available space and position where there's more room
  const showAbove = spaceAbove > spaceBelow;

  // Set position based on available space
  let top, transformOrigin, maxHeight;
  
  if (showAbove) {
    // Position above the trigger
    maxHeight = Math.min(dropdownHeight, spaceAbove - 8);
    top = `${triggerRect.top - maxHeight}px`;
    transformOrigin = 'bottom left';
  } else {
    // Position below the trigger
    maxHeight = Math.min(dropdownHeight, spaceBelow - 8);
    top = `${triggerRect.bottom}px`;
    transformOrigin = 'top left';
  }

  const left = `${triggerRect.left}px`;
  
  // Calculate maximum width
  const maxWidth = Math.min(300, spaceRight - 24);

  // Add a small offset
  const verticalOffset = showAbove ? -1 : 1;

  return {
    position: 'fixed',
    top,
    left,
    width: 'auto',
    minWidth: `${triggerRect.width}px`,
    maxWidth: `${maxWidth}px`,
    maxHeight: `${maxHeight}px`,
    transformOrigin,
    transform: `translate3d(0, ${verticalOffset}px, 0)`,
    zIndex: 1000
  };
};

const handleScroll = () => {
  if (props.isDropdownVisible && dropdownTrigger.value) {
    updatePosition();
  }
};

const setDropdownOverflow = () => {
  if (!dropdownList.value) return;
  
  // Get the position of dropdown relative to trigger
  const triggerRect = dropdownTrigger.value?.getBoundingClientRect();
  if (!triggerRect) return;
  
  const dropdownRect = dropdownList.value.getBoundingClientRect();
  
  // Check if dropdown is above trigger
  const isAbove = dropdownRect.bottom <= triggerRect.top;
  
  // Set overflow property
  if (isAbove) {
    dropdownList.value.style.overflowY = 'auto';
  }
};

onMounted(() => {
  // Create ResizeObserver instance with debouncing/throttling
  resizeObserver.value = new ResizeObserver(() => {
    // Using requestAnimationFrame to batch DOM reads/writes and prevent loops
    requestAnimationFrame(() => {
      if (props.isDropdownVisible && dropdownList.value) {
        updatePosition();
        setDropdownMaxHeight();
        setDropdownOverflow();
      }
    });
  });

  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);
  window.addEventListener('scroll', handleScroll, { passive: true, capture: true });
  window.addEventListener('resize', updatePosition);
});

onUpdated(() => {
  setDropdownMaxHeight();
});
  
onUnmounted(() => {
  // Clean up the ResizeObserver
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
  
  document.removeEventListener('mousedown', handleClickOutside);
  document.removeEventListener('touchstart', handleClickOutside);
  window.removeEventListener('scroll', handleScroll, { capture: true });
  window.removeEventListener('resize', updatePosition);
});

// Update this watch to ensure proper positioning
watch(() => props.isDropdownVisible, (newValue) => {
  if (newValue) {
    nextTick(() => {
      // Initial position calculation after DOM update
      updatePosition();
      
      // Stop observing first to prevent potential overlapping observations (possibly cause of 'ResizeObserver loop completed with undelivered notifications')
      if (resizeObserver.value) {
        resizeObserver.value.disconnect();
      }
      
      // Then start observing the dropdown for size changes
      if (dropdownList.value && resizeObserver.value) {
        resizeObserver.value.observe(dropdownList.value);
        
        // Only observe the content if it's different from the list
        if (dropdownContent.value && dropdownContent.value !== dropdownList.value) {
          resizeObserver.value.observe(dropdownContent.value);
        }
      }
      
      // Ensure content has focus
      dropdownContent.value?.focus();
    });
  } else {
    // Stop observing when dropdown is hidden
    if (resizeObserver.value) {
      resizeObserver.value.disconnect();
    }
    
    dropdownTrigger.value?.removeAttribute('open');
  }
});

const handleTriggerClick = () => {
  emit('triggerClick', props.id);
  const newVisibility = !props.isDropdownVisible;
  emit('update:isDropdownVisible', newVisibility);
};

const close = () => {
  emit('update:isDropdownVisible', false);
  dropdownTrigger.value?.removeAttribute('open');
  dropdownTrigger.value?.focus();
};

// Update this function to ensure correct positioning
const updatePosition = () => {
  if (props.isDropdownVisible && dropdownTrigger.value && dropdownList.value) {
    const newStyles = getDropdownPosition(dropdownTrigger.value);
    if (newStyles) {
      Object.assign(dropdownStyles.value, newStyles);
    }
  }
};

function handleDetailsClick(event) {
  if (props.disabled) {
    event.preventDefault();
    dropdownTrigger.value?.removeAttribute('open');
    emit('update:isDropdownVisible', false);
  }
}

function setDropdownMaxHeight() {
  if (!dropdownList.value) return;
  
  const triggerRect = dropdownTrigger.value?.getBoundingClientRect();
  if (!triggerRect) return;
  
  const dropdownRect = dropdownList.value.getBoundingClientRect();
  const isAbove = dropdownRect.bottom <= triggerRect.top;
  
  let availableHeight;
  if (isAbove) {
    // When above, calculate based on space from top of viewport to trigger
    availableHeight = triggerRect.top;
  } else {
    // When below, calculate based on space from trigger to bottom of viewport
    availableHeight = window.innerHeight - triggerRect.bottom;
  }
  
  // Account for footer and padding
  const offset = isMobile.value ? 116 : 8;
  const maxHeight = availableHeight - offset;
  
  // Apply max height
  dropdownList.value.style.maxHeight = `${maxHeight}px`;
  
  // Ensure overflow is set appropriately
  dropdownList.value.style.overflowY = 'auto';
}

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  // Return early if dropdownList isn't visible
  if (!dropdownList.value) return;

  // Check if click was inside dropdown or trigger
  const clickedDropdown = dropdownList.value?.contains(event.target);
  const clickedTrigger = dropdownTrigger.value?.contains(event.target);
  
  // If click was outside both, close the dropdown
  if (!clickedDropdown && !clickedTrigger) {
    close();
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
// Hide the default marker for the summary element
summary::-webkit-details-marker { display:none; }
summary::marker { content: "";}

.relative:focus {
  box-shadow: 0 0 0 6px var(--colour-utility-focus);
  border-radius: var(--border-radius-half);
}

.dropdown-label {
  position: relative;
  width: 100%;
}

// If the dropdown trigger is a read-only input, use the default cursor style rather than the default text cursor
.dropdown-label :deep(input:read-only) {
  cursor: default;
}

.dropdown-list {
  border-radius: var(--border-radius-half);
  background: var(--colour-utility-white);
  box-shadow: 0 0 0 1px var(--border-utility-base), 0px 4px 8px rgba(0, 0, 0, 0.1);
  
  // These styles are needed to fix the footer to the bottom of this container
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  // Position fixed is required for the teleported dropdown
  position: fixed;
  z-index: 1000;
}

.dropdown-list--visible {
  transform: scale(1, 1);
}

.dropdown-list__content {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  width: 100%;
}

.dropdown-list__footer {
  background-color: var(--colour-panel-g-4);
  display: flex;
  place-content: center;
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-3);
  width: 100%;
}
</style>