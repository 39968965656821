<template>
  <ViewTargetsDataViz
    :target="firstGraphTarget"
    :format-target-period="formatTargetPeriod"
    :measurement-value="measurementValue"
    :attained="attained"
  />
  
  <ViewTargetsDataViz
    :target="secondGraphTarget"
    :format-target-period="formatTargetPeriod"
    :measurement-value="measurementValue"
    :attained="attained"
  />

  <ViewTargetsResults
    :selected-targets="selectedTargets"
    @toggle-target-selection="toggleTargetSelection"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import useTargets from '@/intelligence/composables/useTargets';
import useCurrency from '@/shared/composables/useCurrency';
import ViewTargetsDataViz from '@/admin/components/Targets/ViewTargetsDataViz.vue';
import ViewTargetsResults from '@/admin/components/Targets/ViewTargetsResults.vue';

const maxSelections = 2;
const selectedTargets = ref([]);

const {
  formatTargetPeriod,
  getTarget,
  measurementValue
} = useTargets();
const { cPrice } = useCurrency();

// Add computed properties for first and second graph targets
const firstGraphTarget = computed(() => selectedTargets.value[0] || null);
const secondGraphTarget = computed(() => selectedTargets.value[1] || null);

const attained = computed(() => (attained) => cPrice(`${attained.attained_value}`));

// Selection handling
const isTargetSelected = (target) => {
  return selectedTargets.value.some(selected => selected.id === target.id);
};

async function toggleTargetSelection(target) {
  if (isTargetSelected(target)) {
    // Always allow deselection
    selectedTargets.value = selectedTargets.value.filter(selected => selected.id !== target.id);

  } else if (selectedTargets.value.length < maxSelections) {
    // Only allow selection if under the limit
    selectedTargets.value.push(target);
    
    // Call getTarget to ensure it gets the latest attained value
    await getTarget(target.id);
  }
}
</script>

<style lang="scss" scoped>
  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }

  .cell-spacing {
    display: grid;
    gap: var(--spacing-5);
    grid-template-columns: var(--spacing-16) 1fr;
  }

  table {
    font-size: var(--font-size-4);
    width: 100%;
  }

  .table-wrapper {
    overflow-x: auto;
    width: 100%;
    position: relative;
    background: linear-gradient(to right, #fff 0%, transparent), linear-gradient(to right, transparent, #fff 100%) 0 100%, linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent), linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent) 0 100%;
    background-repeat: no-repeat;
    background-color: var(--colour-utility-white);
    background-size: 250px 100%, 250px 100%, 20px 100%, 20px 100%;
    background-position: 0 0, 100%, 0 0, right var(--scrollbar-width) bottom 0;
    background-attachment: local, local, scroll, scroll;
    transition: all 0.2s;
  }

  th {
    border: none;
    font-size: 20px;
    padding: var(--spacing-3) var(--spacing-3) var(--spacing-3);
    background: var(--colour-panel-action);
    color: var(--colour-utility-action);
    border-bottom: 1px solid var(--colour-panel-g-16);
    text-decoration: underline;
  }

  td {
    border: none;
    font-size: inherit;
    padding: var(--spacing-3);
    vertical-align: middle;
  }

  td:last-child {
    padding-inline-end: var(--spacing-2);
  }
</style>