import { computed, ref } from 'vue';
import { CLEAR_CURRENT_TARGET, ENABLE_TARGETS, FETCH_REPORT_DATA, FETCH_TARGET_PERIODS, SAVE_CURRENT_DIMENSIONS, SAVE_CURRENT_VALUES, SAVE_WHOLE_DIM, TOGGLE_TARGETS_SWITCH, } from '@/intelligence/store/actionType';
import {  tAdmin as t, dates } from '@sales-i/utils';
import { useStore } from 'vuex';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useNotification from '@/shared/composables/useNotification';
import { useDashboard } from '@/admin/components/Dashboard/composables/useDashboard';
import { DEFAULT_WIDGET } from '@/admin/components/Dashboard/constants';
import {
  GET_BY_ID,
  GET_ALL,
  SET_CONFIRMATION_MODAL
} from '@/shared/store/actionType';

const initialFormData = {
  selectedAnalysisField: '',
  selectedProperties: [],
  selectedUsers: [],
  selectedDate: {
    startDate: undefined,
    endDate: undefined,
    interval: undefined,
  },
  selectedMeasurement: undefined,
  targetAmount: []
};

const initialFormState = {
  analysisField: '',
  properties: [],
  users: [],
  date: {
    startDate: undefined,
    endDate: undefined,
    interval: undefined,
  },
  measurement: undefined,
  targetAmount: ''
};

export default function useTargets({ store = useStore() } = {}) {
  const { fetchBubbleValues } = useEnquiries({ store });
  const { getDashboardByCategory, postWidget } = useDashboard({ store });
  const { sendSuccessAlert } = useNotification({ store });
  const formState = ref({ ...initialFormState });
  const validityState = ref({
    analysisFields: false,
    property: false,
    date: false,
    measurement: false,
    targetAmount: false
  });
  
  const propertyOptions = ref([]);
  const selectedProperties = ref([]);
  const usersOptions = ref([]);
  const selectedUsers = ref([]);
  const selectedDate = ref(null);
  const daysFromInterval = {
    WEEKLY: 7,
    MONTHLY: 30,
    QUARTERLY: 90,
    YEARLY: 365
  };
  const targets = ref([]);
  const editingTargetId = ref(null);
  const targetToEdit = ref(null);
  const isEditMode = ref(false);
  const bubbleValues = ref([]);
  const numberOfValues = ref(0);
  const dateArray = ref([]);
  const targetDateArrays = ref({});
  const isTargetIntervalModalVisible = ref(false);
  const currentPeriodIndices = ref({});
  const recordsCount = ref(0);
  const limitTargetList = ref(20);
  const limit = ref(50);
  const offset = ref(0);
  const isLoading = ref(true);
  const isSavingWidget = ref(false);

  const isAnalysisFieldSelected = computed(() => formData.value.selectedAnalysisField !== '');
  const isPropertyFieldSelected = computed(() => formData.value.selectedProperties.length > 0);
  const isUserFieldSelected = computed(() => formData.value.selectedUsers.length > 0);
  const isDateFieldSelected = computed(() => formData.value.selectedDate.startDate !== undefined && formData.value.selectedDate.endDate !== undefined);
  // Check if value is undefined or empty string
  const isMeasurementFieldSelected = computed(() => formData.value.selectedMeasurement !== undefined && formData.value.selectedMeasurement !== '');
  const isTargetsSwitchActive = computed(() => store.state.intelligence.targets.isTargetsSwitchActive);
  const areTargetsEnabled = computed(() => store.state.intelligence.targets.areTargetsEnabled);  
  const currentTargetValues = computed(() => store.state.intelligence.targets.currentTargetValues);
  const valid = computed(() => currentTargetValues.value.length && currentTargetValues.value.every(item => item.values.length || item.checkedAll));
  const measurements = computed(() => store.state.intelligence.targets.measurementsData);
  const targetsData = computed(() => store.state.intelligence.targets.targetsData || { targets: [] });
  const isFormValid = computed(() => Object.values(validityState.value).every(value => value === true));
  const users = computed(() => store.state.admin.users.data);

  // Disabled states for form fields
  const disabledStates = computed(() => ({
    property: !formState.value.analysisField,
    users: !formState.value.properties.length,
    date: !formState.value.users.length,
    measurement: !formState.value.date.interval || !formState.value.date.endDate,
    targetAmount: !formState.value.measurement
  }));

  const updateFormField = (field, value) => {
    formData.value[field] = value;
  };
  
  const updateValidity = (field, value) => {
    validityState.value[field] = value;
  };

  const resetForm = () => {
    formState.value = { ...initialFormState };
    Object.keys(validityState.value).forEach(key => {
      validityState.value[key] = false;
    });
    propertyOptions.value = [];
  };

  // helpers
  const metricOptions = computed(() => measurements.value.length ? measurements.value.map(el => (
    { unit: el.unit, text: el.metric, value: el.id }
  )) : []);

  const formatTargetPeriod = computed(() => {
    return (period) => {
      if (!period) return '';
      return `${period.interval.charAt(0) + period.interval.slice(1).toLowerCase()} ${t('until')} ${dates.format(period.endDate, 'short')}`;
    };
  });

  const getCurrentPeriodIndex = (targetId) => {
    return currentPeriodIndices.value[targetId] || 0;
  };
  
  const setCurrentPeriodIndex = (targetId, index) => {
    if (targetId) {
      currentPeriodIndices.value[targetId] = index;
    }
  };

  const handlePeriodChange = (targetId, periodIndex) => {
    setCurrentPeriodIndex(targetId, periodIndex);
    return periodIndex;
  };

  const getTarget = id => store.dispatch(`intelligence/targets/${GET_BY_ID}`, id);
  const toggleTargetsSwitch = (params) => store.dispatch(`intelligence/targets/${TOGGLE_TARGETS_SWITCH}`, params);
  const enableTargets = value => store.dispatch(`intelligence/targets/${ENABLE_TARGETS}`, value);
  const clearCurrentTarget = () => store.dispatch(`intelligence/targets/${CLEAR_CURRENT_TARGET}`);
  const queryTargetData = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const getTargetPeriods = payload => store.dispatch(`intelligence/targets/${FETCH_TARGET_PERIODS}`, payload);
  const saveCurrentValues = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_VALUES}`, payload);
  const saveWholeDim = payload => store.dispatch(`intelligence/targets/${SAVE_WHOLE_DIM}`, payload);
  const saveCurrentDims = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_DIMENSIONS}`, payload);
  const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const showConfirmationModal = (payload) => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, payload);
  const getUsers = (params) => store.dispatch(`admin/users/${GET_ALL}`, (params));

  // Create a local array of the data the user has entered so far
  const formData = ref({ ...initialFormData });

  const handleAnalysisFieldSelection = async (value) => {
    if (value) {
      const previousAnalysisField = formData.value.selectedAnalysisField;
      updateFormField('selectedAnalysisField', value);
  
      // Only reset properties if the analysis field has actually changed and the previous analysisField is not empty
      if (previousAnalysisField && previousAnalysisField !== value) {
        updateFormField('selectedProperties', []);
        selectedProperties.value = [];
      }
    }
  };

  const handlePropertySelection = (properties) => {
    updateFormField('selectedProperties', properties);
  };

  const handleUserSelection = (value) => {
    // Convert users to have 'name' or 'text'
    const convertedUsers = value.map(user => ({
      name: user.text || user.name,
      value: user.value
    }));
  
    selectedUsers.value = convertedUsers;
    updateFormField('selectedUsers', convertedUsers);
  };
  
  const handleDateSelection = (dateData) => {
    selectedDate.value = dateData;
    updateFormField('selectedDate', dateData);
  };

  const handleMeasurementSelection = (event) => {
    updateFormField('selectedMeasurement', event);
  };
  
  const handleTargetAmountSelection = (event) => {
    updateFormField('targetAmount', event);
  };

  const handlePageChange = async (newOffset) => {
    try {
      isLoading.value = true;
      offset.value = newOffset;
  
      const response = await queryTargetData({
        type: 'get',
        params: {
          limit: limitTargetList.value,
          offset: offset.value
        }
      });
  
      targets.value = response?.targets || [];
      
      recordsCount.value = offset.value;
  
      await generateDateArraysForTargets();
    } catch (error) {
      console.error('Error changing page:', error);
    } finally {
      isLoading.value = false;
    }
  };
  
  const handleLoadMoreProperties = async (newOffset) => {
    offset.value = newOffset;
    
    try {
      // Fetch more properties using the new offset
      const additionalProperties = await fetchAdditionalProperties(
        formData.value.selectedAnalysisField.analysis_field_id,
        newOffset,
        limit.value
      );
      
      const transformedItems = additionalProperties.map(item => ({
        code: item.id || item.code,
        value: item.value
      }));
  
      // Append new properties to existing ones
      propertyOptions.value = [...propertyOptions.value, ...transformedItems];
    } catch (error) {
      console.error('Error loading more properties:', error);
    }
  };

  const fetchAdditionalProperties = async (fieldId, offset, limit) => {
    const bubbleValues = await fetchBubbleValues([fieldId, '', offset, limit]);
    return bubbleValues;
  };

  async function getUsersOptions() {
    try {
      await getUsers({
        offset: offset.value,
        limit: limit.value
      });
  
      usersOptions.value = users.value.map(user => ({
        text: user.display_name,
        value: user.id
      }));
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }
  
  const handleLoadMoreUsers = async (newOffset) => {
    
    try {
      offset.value = newOffset;

      const additionalUsers = await fetchAdditionalUsers(
        offset.value,
        limit.value
      );
      
      // Only append if there are new users
      if (additionalUsers.length > 0) {
        usersOptions.value = [...usersOptions.value, ...additionalUsers];
      }
    } catch (error) {
      console.error('Error loading more properties:', error);
    }
  };

  const fetchAdditionalUsers = async (offset, limit) => {
    try {
      await getUsers({
        offset: offset,
        limit: limit
      });
  
      // Map the new users to the format expected by the dropdown
      return users.value.map(user => ({
        text: user.display_name,
        value: user.id
      }));
    } catch (error) {
      console.error('Error fetching additional users:', error);
      return [];
    }
  };

  const resetFormData = (validity) => {
    // Reset the formData object to its initial state
    formData.value = { ...initialFormData };
    
    loadPageData();

    if (validity) {
      Object.keys(validity.value).forEach(key => {
        validity.value[key] = false;
      });
    }

    return formData.value;
  };

  const getAnalysisPropertyValues = (field) => {
    return field.values.map(v => v.value);
  };

  const getSelectedUserValues = (users) => {
    // Ensure users is an array
    const userArray = Array.isArray(users) ? users : [users];
    
    // Extract unique user names, filtering out any undefined or null values
    const uniqueNames = [...new Set(userArray.map(u => u?.name || u?.text).filter(Boolean))];
    
    return uniqueNames;
  };

  async function loadPageData(limit, offset) {
    const response = await queryTargetData({
      type: 'get',
      params: {
        limit: limit,
        offset: offset
      }
    });

    targets.value = response?.targets || [];

    // recordsCount should match the limit until the last page where it should return the number of targets remaining
    recordsCount.value = limit;
    
    if (targets.value.length < limit) {
      recordsCount.value = targets.value.length;
    }
  }
  
  const handleEditTarget = async (targetId) => {
    const response = await getTarget(targetId);
    
    if (response) {
      editingTargetId.value = targetId;
      targetToEdit.value = response;
      isEditMode.value = true;
      
      // Populate the form data with the target's values
      populateFormDataFromTarget(response);
    }
      
    return {
      editingTargetId: editingTargetId.value,
      targetToEdit: targetToEdit.value,
      isEditMode: isEditMode.value
    };
  };

  const handleDeleteTarget = async (targetId) => {
    return new Promise((resolve) => {
      showConfirmationModal({
        title: t('delete_target'),
        message: t('do_you_really_want_to_delete_this_target'),
        updatedMessage: t('you_have_deleted_the_target'),
        updateFunction: async () => {
          try {
            const response = await deleteTarget({
              type: 'delete',
              params: { targetId }
            });

            if (response) {
              await loadPageData();
              resolve(true);
            }
            return true;
          } catch (e) {
            resolve(false);
            return false;
          }
        }
      });
    });
  };

  async function addToDashboard(target) {
    if (isSavingWidget.value) return;
    isSavingWidget.value = true;
  
    try {
      // get dashboard id for this area
      const dashboard_id = (
        getDashboardByCategory('targets')
      )?.id;
  
      const item = {
        ...DEFAULT_WIDGET,
        dashboard_id,
        description: formatTargetPeriod.value(target.target_period),
        name: `${target.analysis_fields?.[0]?.title ?? ''} (${formatTargetPeriod.value(target.target_period)})`,    
        reportType: 'target',
        requestParams: target,
        settings: { 
          'url': ''
        },
        width: 1
      };
  
      const response = await postWidget(item);
  
      if (response) {
        sendSuccessAlert(t('added_successfully'));
      }
  
    } finally {
      // Reset the flag regardless of success or failure
      isSavingWidget.value = false;  
    }
  }
  
  const handleDropdownAction = async (action, targetId) => {
    const actions = {
      'edit': handleEditTarget,
      'addToDashboard': addToDashboard,
      'delete': handleDeleteTarget
    };


    if (action === 'addToDashboard') {
      const target = targets.value.find(t => t.id === targetId);
      return await actions[action](target);
    } else {
      return await actions[action](targetId);
    }
  };

  const cancelEdit = () => {
    editingTargetId.value = null;
    targetToEdit.value = null;
    isEditMode.value = false;

    resetFormData();
    
    return {
      editingTargetId: editingTargetId.value,
      targetToEdit: targetToEdit.value,
      isEditMode: isEditMode.value
    };
  };

  const handleBubbleValuesFetched = (values) => {
    bubbleValues.value = values;
    
    // Format bubble values to match propertyOptions structure
    const formattedValues = values.map(value => ({
      code: value.id,
      value: value.value,
    }));
    
    // Update propertyOptions
    propertyOptions.value = formattedValues;
  };

  const populateFormDataFromTarget = (target) => {
    if (!target) return;
    
    formData.value = {
      selectedAnalysisField: target.analysis_fields?.[0] || '',
      selectedProperties: target.analysis_fields?.[0]?.values || [],
      selectedUsers: target.share_with || [],
      selectedDate: {
        startDate: dates.format(target.target_period?.startDate, 'yyyy-MM-dd') || '',
        endDate: dates.format(target.target_period?.endDate, 'yyyy-MM-dd') || '',
        interval: target.target_period?.interval || '',
        intervalMultiplier: target.target_period?.intervalMultiplier
      },
      selectedMeasurement: target.target_value?.measurement?.metric || '',
      targetAmount: target.target_value?.values || []
    };
    
    // Also update selected properties ref for property dropdown
    selectedProperties.value = target.analysis_fields?.[0]?.values || [];
    
    return formData.value;
  };

  async function generateDateArray(dateSelection) {
    // Check if dateSelection exists
    if (!dateSelection) return;
    
    const response = await getTargetPeriods({
      type: 'getTargetPeriod',
      params: {
        startDate: dateSelection.startDate,
        endDate: dateSelection.endDate,
        interval: dateSelection.interval,
        intervalMultiplier: parseInt(dateSelection.intervalMultiplier),
      }
    });

    dateArray.value = response;

    return dateArray.value;
  }

  async function generateDateArraysForTargets(targetsList) {
    const targetsToProcess = targetsList || targets.value;
    if (!targets.value?.length) return;
    
    for (const target of targetsToProcess) {
      if (target.target_period) {
        const dateArray = await generateDateArray({
          startDate: target.target_period.startDate,
          endDate: target.target_period.endDate,
          interval: target.target_period.interval,
          intervalMultiplier: target.target_period.intervalMultiplier || 1
        });
        
        targetDateArrays.value[target.id] = dateArray;
      
        // Initialize period index if not already set
        if (currentPeriodIndices.value[target.id] === undefined) {
          currentPeriodIndices.value[target.id] = 0;
        }
      }
    }
  }
  
  return {
    targetsData,
    currentTargetValues,
    isTargetsSwitchActive,
    areTargetsEnabled,
    valid,
    measurements,
    metricOptions,
    propertyOptions,
    usersOptions,
    getTarget,
    toggleTargetsSwitch,
    enableTargets,
    clearCurrentTarget,
    queryTargetData,
    getTargetPeriods,
    saveCurrentValues,
    saveWholeDim,
    saveCurrentDims,
    deleteTarget,
    handleAnalysisFieldSelection,
    formData,
    selectedProperties,
    isAnalysisFieldSelected,
    isPropertyFieldSelected,
    isUserFieldSelected,
    isDateFieldSelected,
    isMeasurementFieldSelected,
    handlePropertySelection,
    handleUserSelection,
    handleDateSelection,
    numberOfValues,
    handleMeasurementSelection,
    handleTargetAmountSelection,
    resetFormData,
    getAnalysisPropertyValues,
    getSelectedUserValues,
    formatTargetPeriod,
    daysFromInterval,
    targets,
    showConfirmationModal,
    isFormValid,
    disabledStates,
    updateValidity,
    resetForm,
    formState,
    handleEditTarget,
    handleDeleteTarget,
    handleDropdownAction,
    cancelEdit,
    editingTargetId,
    targetToEdit,
    isEditMode,
    handleBubbleValuesFetched,
    populateFormDataFromTarget,
    updateFormField,
    validityState,
    generateDateArray,
    generateDateArraysForTargets,
    dateArray,
    targetDateArrays,
    isTargetIntervalModalVisible,
    getCurrentPeriodIndex,
    setCurrentPeriodIndex,
    handlePeriodChange,
    currentPeriodIndices,
    loadPageData,
    recordsCount,
    handlePageChange,
    handleLoadMoreProperties,
    handleLoadMoreUsers,
    getUsersOptions,
    limitTargetList
  };
}