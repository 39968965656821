<template>
  <div class="set-permissions">
    <CustomCard
      purpose="reversed"
      class="flow"
    >
      <div
        v-if="showHeading"
        class="heading-and-controls"
      >
        <h3 class="fw-sbld">
          {{
            t('tenant_item_count', { tenantName, count })
          }}
        </h3>
        <div class="actions">
          <CustomButton
            v-if="deleteUserList.length && permissions.delete"
            purpose="action"
            small
            @on-click="deleteUsers"
          >
            {{
              t('delete_users_count', {
                count: deleteUserList.length
              }
              )
            }}
          </CustomButton>
          <CustomButton
            v-if="permissions.create"
            purpose="action"
            small
            @on-click="addUser"
          >
            {{ t('add_a_user') }}
          </CustomButton>
        </div>
      </div>
      <router-view
        :delete-user-key="deleteUserKey"
        @delete-user-list="setupDeleteUserList"
      />
    </CustomCard>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import { CustomCard, CustomButton, } from '@sales-i/dsv3';
import { useRoute, } from 'vue-router';
import { user_management } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { GET_SUMMARY, DELETE_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const vroute = useRoute();
const { getPermissions } = usePermissions();
const { tenant } = useSystem({ store });

const permissions = computed(() => getPermissions(user_management));
const deleteUserKey = ref(0);
const deleteUserList = ref([]);

const count = computed(() => store.getters['admin/users/totalCount']);
const tenantName = computed(() => tenant.value.data.name);
const getUserSummary = params => store.dispatch(`admin/users/${GET_SUMMARY}`, params);
const deleteUsersById = params => store.dispatch(`admin/users/${DELETE_BY_ID}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const showHeading = computed(() => {
  return !(vroute.path.includes('add') || vroute.path.includes('edit'));
});

function addUser() {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/add`);
}

function deleteUsers() {
  let userNames = '';
  let userIds = [];
  for (let i = 0, len = deleteUserList.value.length; i < len; i++) {
    const user = deleteUserList.value[i];
    if (i > 0) userNames += ', ';
    userNames += user.display_name;
    userIds.push(user.id);
  }
  showConfirmationModal({
    message: t('do_you_really_want_to_delete_variable', { variable: `${userNames}` }),
    updatedMessage: t('you_have_deleted_variable', { variable: userNames }),
    updateFunction: async () => {
      try {
        await deleteUsersById(userIds);
        getUserSummary();
        deleteUserList.value = [];
        deleteUserKey.value++;
        return true;
      } catch (e) {
        return false;
      }
    },
    finishFunction: () => {
      return true;
    },
    closeFunction: () => {
      return true;
    },
  });
}

function setupDeleteUserList(list) {
  deleteUserList.value = list;
}

onMounted(() => {
  getUserSummary();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.set-permissions {
  width: 100%;
  
  > :deep(.c-card) {
    margin: var(--spacing-3);
    overflow: auto;
    width: auto;
    flex: auto; // Ensure that the columns takes up the available space

    :deep(.c-card--footer) {
      padding: 0;
    }

    .heading-and-controls {
      padding-inline-start: calc(var(--spacing-6) + var(--spacing-1));

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .actions {
      margin-block-start: var(--spacing-1);
      display: flex;
      justify-content: flex-end;
      gap: var(--spacing-1);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-block-start: 0;
      }
    }
  }
}
</style>