export const getRequestParameters = () => ([reportType, params]) => {
  const {
    selectedAnalysisField = {},
    selectedProperties = [],
    selectedDate = {},
    selectedMeasurement,
    targetAmount,
    year = new Date().getFullYear(),
    offset = 0,
    limit = 20,
    // sortHeader = 'name',
    // sortOption = 'asc',
    targetId,
    bubbleId,
  } = params;

  const analysis_field_id = selectedAnalysisField?.fieldId ?? '';
  const area = selectedAnalysisField?.entity ?? '';
  const title = selectedAnalysisField?.fieldName ?? '';
  const propertyValues = selectedProperties.map(property => property.code);
  const type = 'analysis_field';
  const interval = selectedDate?.interval ?? 'WEEKLY';
  // const startTime = selectedDate?.startTime ?? '';
  // const startDate = selectedDate?.startDate ?? '';
  // const endDate = selectedDate?.endDate ?? '';
  const startDate = '2024-02-07 15:52:44.233+00';
  const endDate = '2025-12-14 15:52:44.233+00';
  const expires = selectedDate?.expires ?? year; // Default to the current year when getting targets
  const measurement = selectedMeasurement ?? '';
  const measurementValue = targetAmount ?? '';

  const generateInput = () => `
  input: {
    ${targetId ? `id: ${targetId}` : ''}
    analysis_fields: [
      {
        analysis_field_id: ${analysis_field_id}
        area: "${area}"
        title: "${title}"
        value_codes: ${JSON.stringify(propertyValues)}
        type: "${type}"
      }
    ]
    target_period: {
      interval: ${interval}
      startDate: "${startDate}"
      endDate: "${endDate}"
      expires: "${expires}"
    }
    target_value: {
      measurement: "${measurement}"
      value: ${measurementValue}
    }
  }
`;

  switch (reportType) {
  case 'get':
    return `
      filter: {}
      pagination: {
        offset: ${offset}, 
        limit: ${limit}
      }
    `;
  case 'add':
  case 'update':
    return generateInput(params);
  case 'delete':
    return `id: ${targetId}`;
  case 'deleteTargetsByBubbleId':
    return `bubbleId: ${bubbleId}`;
  }
};
