<template>
  <CustomModal
    show-modal
    :max-width="800"
    title="Select values"
    @close-modal="closeModal"
  >
    <div class="px-3 pb-3 flow">
      <CustomRadio
        id="same-value"
        name="value-type"
        :label="t('use_the_same_value_for_each_interval')"
        :checked="isSameValue"
        value="same-value"
        @change="setSameValue(true)"
      />
      <CreateTargetAmountValues
        v-if="isSameValue"
        :date-array="props.dateArray"
        :initial-target-amount="targetAmounts"
        :is-same-value="true"
        @target-amount="handleTargetAmounts"
        @validity-changed="handleValidityChanged"
        @reset-state="initializeState"
        @update-for-same-value-mode="updateForSameValueMode"
      />

      <CustomRadio
        id="different-values"
        name="value-type"
        :label="t('use_different_values_for_each_interval')"
        :checked="!isSameValue"
        value="different-values"
        @change="setSameValue(false)"
      />
      <CreateTargetAmountValues
        v-if="!isSameValue"
        :date-array="props.dateArray"
        :initial-target-amount="targetAmounts"
        :is-same-value="false"
        @target-amount="handleTargetAmounts"
        @validity-changed="handleValidityChanged"
      />
    </div>
    <template
      v-if="isValid"
      #footer
    >
      <ButtonGroup>
        <CustomButton
          purpose="action"
          small
          @click="apply"
        >
          {{ t('apply') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton, CustomModal, CustomRadio } from '@sales-i/dsv3';
import CreateTargetAmountValues from '@/admin/components/Targets/CreateTargetAmountValues.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';

const emit = defineEmits(['target-amount', 'closeModal', 'mode-preference-changed']);

const props = defineProps({
  dateArray: {
    type: Array,
    default: () => []
  },
  initialTargetAmount: {
    type: Array,
    default: () => []
  },
  preferSameValue: {
    type: Boolean,
    default: false
  }
});

const targetAmounts = ref(Array(props.dateArray.length || 1).fill('0'));
const isValid = ref(false);
const isSameValue = ref(props.preferSameValue);

const handleTargetAmounts = (amounts) => {
  targetAmounts.value = amounts;
};

const handleValidityChanged = (validity) => {
  isValid.value = validity;
};

function setSameValue(value) {
  // Reset targetAmounts to empty values when switching modes
  targetAmounts.value = Array(props.dateArray.length).fill('0');
  isSameValue.value = value;
  
  emit('mode-preference-changed', value);
}

function closeModal() {
  emit('closeModal');
}

function apply() {
  emit('target-amount', targetAmounts.value);
  emit('closeModal');
}

// Watch for initial target amount prop to set initial values
watch(() => props.initialTargetAmount, (newInitialTargetAmount) => {
  // If initialTargetAmount is provided and has values, use it. Otherwise, initialize with empty values
  if (newInitialTargetAmount && newInitialTargetAmount.length > 0) {
    targetAmounts.value = [...newInitialTargetAmount];
  } else {
    targetAmounts.value = Array(props.dateArray.length || 1).fill('0'); 
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
</style>