<!-- todo: translate -->
<template>
  <div class="button-wrapper">
    <CustomButton
      purpose="action"
      small
      @click="toggleFormVisibility"
    >
      {{ t('Create a target') }}
    </CustomButton>
  </div>

  <div class="table-wrapper">
    <table
      v-if="!isLoading"
    >
      <thead>
        <tr>
          <th>{{ t('Analysis field') }}</th>
          <th>{{ t('Share with') }}</th>
          <th>{{ t('Date') }}</th>
          <th>{{ t('Target') }}</th>
          <th>{{ t('Attained') }}</th>
          <th colspan="2">
            {{ t('Progress') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <CreateTargetsForm
          v-if="isFormVisible && !editingTargetId"
          :is-form-visible="true"
          :target-to-edit="targetToEdit"
          :is-edit-mode="isEditMode"
          @form-cancelled="toggleFormVisibility"
          @refresh-targets="refreshTargets"
        />
        <template
          v-for="target in targets"
          :key="target.id"
        >
          <!-- Show form in place of row when editing -->
          <CreateTargetsForm
            v-if="editingTargetId === target.id"
            :is-form-visible="true"
            :target-to-edit="targetToEdit"
            :is-edit-mode="isEditMode"
            @form-cancelled="cancelEdit"
            @refresh-targets="refreshTargets"
          />
          <!-- Show regular row when not editing -->
          <tr v-else>
            <td>
              <div class="flex">
                <div
                  v-for="field in target.analysis_fields"
                  :key="field.analysis_field_id"
                  class="cell-spacing"
                >
                  <CustomCheckbox
                    :checked="isTargetSelected(target)"
                    :disabled="isCheckboxDisabled(target)"
                    :label="analysisField(field)"
                    @change="toggleTargetSelection(target)"
                  />
                  <div>
                    <ViewTargetsDisclosure
                      tooltip
                      :values="getAnalysisPropertyValues(field)"
                    />
                  </div>
                </div>
              </div>
            </td>
            <td />
            <td>{{ formatTargetPeriod(target.target_period) }}</td>
            <td>
              <div class="cell-spacing">
                <span>{{ measurementMetric(target.target_value) }}</span>
                <span>{{ measurementValue(target.target_value) }}</span>
              </div>
            </td>
            <td>{{ attained(target.attained) }}</td>
            <td>
              <RagChip
                :value="progress(target.attained)"
                :green="67"
                :amber="33"
                suffix="%"
              />
            </td>
            <td>
              <CustomDropdown
                purpose="reversed"
                menu-position="left"
                close-on-selection
                :items="[
                  {
                    title: t('Edit'),
                    action: 'edit'
                  },
                  {
                    title: t('Delete'),
                    action: 'delete'
                  }
                ]"
                icon-color="var(--colour-utility-black)"
                @click="handleDropdownAction($event.action, target.id)"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <BufferImage
      v-if="isLoading"
      color="var(--colour-utility-black)"
      float="center"
      class="loading-spinner"
    />
  </div>

  <Pagination
    :offset="offset"
    :limit="limit"
    :records-count="targets.length"
    @page-changed="handlePageChanged"
  />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { BufferImage, CustomButton, CustomCheckbox, CustomDropdown, RagChip } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import useTargets from '@/intelligence/composables/useTargets';
import useCurrency from '@/shared/composables/useCurrency';
import CreateTargetsForm from '@/admin/components/Targets/CreateTargetsForm.vue';
import ViewTargetsDisclosure from '@/admin/components/Targets/ViewTargetsDisclosure.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { paginationProps, paginationEmits } from '@/shared/composables/usePagination';

const props = defineProps({
  ...paginationProps,
  selectedTargets: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits([...paginationEmits, 'toggleTargetSelection']);

const maxSelections = 2;
const isFormVisible = ref(false);
const isEditMode = ref(false);
const targetToEdit = ref(null);
const editingTargetId = ref(null);
const isLoading = ref(true);

const {
  deleteTarget,
  getTarget,
  formatTargetPeriod,
  queryTargetData,
  getAnalysisPropertyValues,
  setupPagination,
  showConfirmationModal,
  targets
} = useTargets();
const { cPrice } = useCurrency();

const measurementValue = computed(() => (targetValue) => cPrice(targetValue.value));
const attained = computed(() => (attained) => cPrice(`${attained.attained_value}`));
const progress = computed(() => (attained) => `${attained.progress}`);
const analysisField = computed(() => (field) => field.title);
const measurementMetric = computed(() => (targetValue) => targetValue.measurement.metric);

// Selection handling
const isTargetSelected = (target) => {
  return props.selectedTargets?.some(selected => selected.id === target.id);
};

const isCheckboxDisabled = (target) => {
  return props.selectedTargets?.length >= maxSelections && !isTargetSelected(target);
};

const toggleTargetSelection = (target) => {
  emit('toggleTargetSelection', target);
};

const toggleFormVisibility = () => {
  isFormVisible.value = !isFormVisible.value;
  if (!isFormVisible.value) {
    cancelEdit();
  }
};

const actionHandlers = {
  'delete': deleteTargetById,
  'edit': editTarget
};

const handleDropdownAction = (action, targetId) => {
  actionHandlers[action](targetId);
};


const refreshTargets = async () => {
  const response = await queryTargetData({
    type: 'get',
    params: {}
  });
  targets.value = response?.targets.slice(offset.value, offset.value + limit.value);
  cancelEdit();
};

const {
  limit,
  offset,
  handlePageChanged,
  loadPageData
} = setupPagination(props, emit);

async function editTarget(targetId) {  
  const response = await getTarget(targetId);

  if (response) {
    editingTargetId.value = targetId;
    targetToEdit.value = response;
    isEditMode.value = true;
  }
}

// Add a new function to cancel editing
function cancelEdit() {
  editingTargetId.value = null;
  targetToEdit.value = null;
  isEditMode.value = false;
}

async function deleteTargetById(targetId) {
  showConfirmationModal({
    title: t('Delete target'),
    message: t('Do you really want to delete this target?'),
    updatedMessage: t('You have deleted the target.'),

    updateFunction: async () => {
      try {
        const response = await deleteTarget({
          type: 'delete',
          params: { targetId }
        });

        if (response) {
          await refreshTargets();
        }
        return true;
      } catch (e) {
        return false;
      }
    }
  });
}

onMounted(async () => {
  try {
    isLoading.value = true; // Set to true before loading data
    await loadPageData();
  } catch (error) {
    // Handle any errors
    console.error(error);
  } finally {
    isLoading.value = false; // Set to false after data is loaded
  }
});
</script>

<style lang="scss" scoped>
  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }

  .cell-spacing {
    display: grid;
    gap: var(--spacing-5);
    grid-template-columns: calc(var(--spacing-6) * 4) 1fr;
    align-items: center;
  }

  table {
    font-size: var(--font-size-4);
    width: 100%;
  }

  .table-wrapper {
    overflow-x: auto;
    width: 100%;
    position: relative;
    background: linear-gradient(to right, #fff 0%, transparent), linear-gradient(to right, transparent, #fff 100%) 0 100%, linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent), linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent) 0 100%;
    background-repeat: no-repeat;
    background-color: var(--colour-utility-white);
    background-size: 250px 100%, 250px 100%, 20px 100%, 20px 100%;
    background-position: 0 0, 100%, 0 0, right var(--scrollbar-width) bottom 0;
    background-attachment: local, local, scroll, scroll;
    transition: all 0.2s;
  }

  th {
    border: none;
    font-size: 20px;
    padding: var(--spacing-3) var(--spacing-3) var(--spacing-3);
    background: var(--colour-panel-action);
    color: var(--colour-utility-action);
    border-bottom: 1px solid var(--colour-panel-g-16);
    text-decoration: underline;
  }

  td {
    border: none;
    font-size: inherit;
    padding: var(--spacing-3);
    vertical-align: middle;
  }

  td:last-child {
    padding-inline-end: var(--spacing-2);
  }
</style>