<template>
  <CreateTargetsFormSmall
    :editing-target-id="editingTargetId"
    :target-to-edit="targetToEdit"
    :is-edit-mode="isEditMode"
    :is-form-visible="isFormVisible"
    @form-cancelled="handleFormCancel"
    @refresh-targets="refreshTargets"
  />

  <ViewTargetsResultsSmall
    v-if="!isFormVisible"
    :targets="targets"
    @toggle-form-visibility="toggleFormVisibility"
    @edit-state-changed="handleEditStateChanged"
  />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import useTargets from '@/intelligence/composables/useTargets';
import CreateTargetsFormSmall from '@/admin/components/Targets/CreateTargetsFormSmall.vue';
import ViewTargetsResultsSmall from '@/admin/components/Targets/ViewTargetsResultsSmall.vue';

const isFormVisible = ref(false);
const targets = ref([]); 
const editingTargetId = ref(null);
const targetToEdit = ref(null);
const isEditMode = ref(false);

const {
  queryTargetData,
  cancelEdit
} = useTargets();

const toggleFormVisibility = () => {
  isFormVisible.value = !isFormVisible.value;
  
  // Reset edit state when closing form
  if (!isFormVisible.value) {
    const resetState = cancelEdit();
    editingTargetId.value = resetState.editingTargetId;
    targetToEdit.value = resetState.targetToEdit;
    isEditMode.value = resetState.isEditMode;
  }
};

const handleFormCancel = () => {
  isFormVisible.value = false;
  const resetState = cancelEdit();
  editingTargetId.value = resetState.editingTargetId;
  targetToEdit.value = resetState.targetToEdit;
  isEditMode.value = resetState.isEditMode;
};

const refreshTargets = async () => {
  const response = await queryTargetData({
    type: 'get',
    params: {}
  });
  targets.value = response.targets;
  
  // Reset form state after refresh
  isFormVisible.value = false;
  const resetState = cancelEdit();
  editingTargetId.value = resetState.editingTargetId;
  targetToEdit.value = resetState.targetToEdit;
  isEditMode.value = resetState.isEditMode;
};

const handleEditStateChanged = (newState) => {
  editingTargetId.value = newState.editingTargetId;
  targetToEdit.value = newState.targetToEdit;
  isEditMode.value = newState.isEditMode;
  isFormVisible.value = true;
};

onMounted(async () => {
  const response = await queryTargetData({
    type: 'get',
    params: {}
  });
  targets.value = response.targets;
});
</script>

<style lang="scss" scoped>
  .target-details {
    background-color: var(--colour-panel-base);
    border-radius: var(--border-radius-1);
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    display: flex;
    flex-direction: column;
  }

    // If the use click the (+#) button, the parent will change background colour.
    .target-details:has(.target-details__primary[aria-expanded="true"]) {
      background-color: var(--colour-brand-puerto-rico);
    }

    .target-details__primary {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: var(--spacing-3);
      width: 100%;

      ul {
        list-style-type: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .target-details__secondary {
      background-color: var(--colour-utility-white);
      padding-block: var(--spacing-1);
      padding-inline: var(--spacing-3);
    }

      .target-details__info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }


  summary:-webkit-details-marker {
    display: none;
  }

  summary {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center; 
  }

    .summary-icon {
      color: white;
      display: flex;
      place-content: center;
      transition: all 0.2s ease;
    }

    details[open] .target-details__primary {
      padding-bottom: 0;
    }
      
      details[open] .summary-icon {
        transform: rotate(180deg);
      }

      details[open] summary span {
        visibility: hidden;
      }

  .selected-target-wrapper {
    position: fixed;
    bottom: 96px;
    left: 0;
    right: 0;
    z-index: 100; // This scares me.
  }

  .button-wrapper {
    position: absolute;
    right: 0;
    top: -20px;
    padding-inline: var(--spacing-2);    
  }
  </style>