<template>
  <div class="settings">
    <h2>
      {{
        t('Presentation Settings', 'entity_settings', {
          interpolations: {
            entity: 'Presentation',
          },
        })
      }}
    </h2>
    <h4>{{ t('Visiting customer? Hide sensitive metrics.') }}</h4>
    <CustomCheckbox
      id="enabled_customer_view"
      v-model:value="checkboxValue"
      :label="t('Customer view')"
      :disabled="!showUpdateButton"
      @input:value="handleInput"
    />
    <div
      v-if="showUpdateButton"
      class="submit"
    >
      <CustomButton
        action="submit"
        :label="t('Update')"
        :disabled="disableUpdateButton"
        @click="updateSettings"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import { ref, computed, onMounted } from 'vue';
import { CustomCheckbox, CustomButton } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { TOGGLE_CUSTOMER_MODE } from '@/shared/store/actionType';
import { intelligence_admin } from '@/shared/store/data/policies';
import { t } from '@sales-i/utils';

export default {
  name: 'EnquiriesCustomerView',
  components: {
    CustomCheckbox,
    CustomButton,
  },
  setup() {
    const store = useStore();
    const checkboxValue = ref(false);
    const { permissions } = usePermissions();

    const isCustomerModeEnabled = computed(() => store.state.system.isCustomerModeEnabled);

    const showUpdateButton = computed(() => permissions.value[intelligence_admin] && permissions.value[intelligence_admin].update);
    const disableUpdateButton = computed(() => checkboxValue.value === isCustomerModeEnabled.value);

    const toggleCustomerMode = bool => store.dispatch(`system/${TOGGLE_CUSTOMER_MODE}`, bool);

    function handleInput(e) {
      checkboxValue.value = e;
    }
    function updateSettings() {
      toggleCustomerMode(checkboxValue.value);
    }

    onMounted(() => {
      if (Object.keys(localStorage).includes('isCustomerModeEnabled')) {
        toggleCustomerMode(localStorage.isCustomerModeEnabled === 'true');
      }

      checkboxValue.value = isCustomerModeEnabled.value;
    });

    return {
      // data
      checkboxValue,
      // computed - vuex
      isCustomerModeEnabled,
      // computed
      showUpdateButton,
      disableUpdateButton,
      // methods
      t,
      handleInput,
      updateSettings,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.settings {
  background: var(--colour-panel-g-0);
  width: 100%;
  border-top-right-radius: var(--border-radius-1);
  padding: var(--spacing-3) var(--spacing-3) var(--spacing-12);
  position: relative;
}

h2,
h4 {
  margin-bottom: var(--spacing-3);
}

.submit {
  position: absolute;
  width: 100%;
  background: var(--colour-panel-action);
  text-align: center;
  padding: var(--spacing-2);
  bottom: 0;
  left: 0;
  right: 0;

  button {
    margin: 0 auto;
  }
}
</style>
