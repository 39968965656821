<template>
  <CustomModal
    class="dismiss-modal"
    :show-modal="dismissModalOpen"
    :show-unload-warning="isFormDirty"
    :max-width="600"
    @close-modal="closeDismissModal"
  >
    <div class="container modal-content">
      <h3>
        <IconBase icon-name="warning" />
        {{
          t('dismiss_this_variable', {
            variable: objectType,
          })
        }}
      </h3>

      <p>
        {{ t('tell_sales_i_why_this_community_is_not_relevant_to_help_provide_more_informed_and_effective_suggestions_in_the_future') }}
      </p>

      <Field
        v-bind="dismissFields.review_type"
        @input="onFieldInput($event, 'review_type')"
      />
      <Field
        v-bind="dismissFields.feedback_information"
        @input="onFieldInput($event, 'feedback_information')"
      />
    </div>
    <template #footer>
      <ButtonGroup
        name="opportunity-actions"
        class="btn-group opportunity-actions"
      >
        <CustomButton
          purpose="reversed"
          small
          @on-click="() => closeDismissModal()"
        >
          {{ t('cancel') }}
        </CustomButton>
        <CustomButton
          purpose="action"
          small
          @on-click="() => dismissItems()"
        >
          {{ t('dismiss') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isEqualObjects, tAdmin as t } from '@sales-i/utils';
import { POST_BY_ENTITY } from '@/shared/store/actionType';
import { CustomModal, IconBase, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';

const props = defineProps({
  dismissModalOpen: {
    type: Boolean,
    default: false,
  },
  closeDismissModal: {
    type: Function,
    default: () => {},
  },
  dismissOptions: {
    type: [Object, Array],
    default: () => {},
  },
  objectType: {
    type: String,
    default: 'opportunity',
  },
});

const emit = defineEmits(['postFeedback']);

const store = useStore();

const dismissInput = ref({
  review_type: 'SINGLE_CUSTOMER',
  feedback_information: '',
});

const initialFormValues = ref({});

const feedback = computed(() => store.state.admin.aiFeedback);

const isFormDirty = computed(() => {
  return !isEqualObjects(initialFormValues.value, dismissInput.value);
});

watch(() => props.dismissModalOpen, () => {
  dismissInput.value = {
    review_type: 'SINGLE_CUSTOMER',
    feedback_information: '',
  };
});

onMounted(() => {
  initialFormValues.value = { ...dismissInput.value };
});

const dismissFields = computed(() => ({
  review_type: {
    id: 'review_type',
    label: t('who_are_you_dismissing_this_association_for'),
    type: 'select',
    options: [
      {
        value: 'SINGLE_CUSTOMER',
        text: t('this_customer'),
      },
      {
        value: 'ALL_CUSTOMERS',
        text: t('all_customers'),
      },
    ],
    error: dismissInput.value.review_type === '',
    errorMessage: t('please_select_who_you_are_dismissing_this_association_for'),
    placeholder: t('select'),
    value: dismissInput.value.review_type,
    twoway: true,
    required: true,
  },
  feedback_information: {
    id: 'feedback_information',
    type: 'textarea',
    label: t('why_are_you_dismissing_this_association'),
    value: dismissInput.value.feedback_information,
    twoway: true,
    maxLength: 4000,
  },
}));

const onFieldInput = (value, key) => {
  dismissInput.value[key] = value;
};

const dismissItems = async () => {
  let requestObject = [
    {
      ...props.dismissOptions,
      ...dismissInput.value,
    },
  ];
  if (props.dismissOptions.constructor === Array) {
    requestObject = props.dismissOptions.map(o => ({
      object: o.object,
      object_id: o.object_id,
      feedback_information: dismissInput.value.feedback_information,
      review_type: dismissInput.value.review_type,
    }));
  }

  await store.dispatch(`admin/aiFeedback/${POST_BY_ENTITY}`, requestObject);

  if (feedback.value.errors.length === 0) {
    emit('postFeedback');
  }
};
</script>

<style lang="scss" scoped>
.dismiss-modal {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0 0 var(--spacing-2) 0;
  }
  p,
  label {
    font-size: 1rem;
    margin: var(--spacing-2) 0;
  }
  div.svg-container {
    margin: auto;
  }
}
.opportunity-actions {
  display: flex;
  flex-direction: row;

  button {
    margin: 0 10px 0 0;
  }
}
.modal-content {
  padding: var(--spacing-2);

  table {
    width: 100%;
    margin: var(--spacing-2) 0;

    th,
    td {
      padding: var(--spacing-2);
    }
  }

  .association-selection {
    margin-bottom: 0;
  }
}
</style>