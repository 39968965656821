<template>
  <label
    for="fieldSelect"
    :class="{ 'visually-hidden': !isMobile }"
  >
    {{ t('select_target_type') }}
  </label>
  <div class="analysis-field">
    <select
      id="fieldSelect" 
      :value="selectedAnalysisField?.analysis_field_id || ''"
      @input="handleInput"
      @focus="storePreviousField"
    >
      <option 
        value="" 
        selected
      >
        {{ t('select_analysis_field') }}
      </option>

      <optgroup 
        v-for="area in bubblesGroupedByArea"
        :key="area.areaName"
        :label="area.areaName"
      >
        <option
          v-for="bubble in area.bubbles"
          :key="bubble.id"
          :value="bubble.id"
        >
          {{ bubble.title }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  selectedAnalysisField: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(['field-selected', 'validity-changed', 'bubble-values-fetched']);

const store = useStore();

const {
  bubbleData,
  fetchBubbleData,
  fetchBubbleValues
} = useEnquiries({ store });


const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const selectedAnalysisField = ref(props.selectedAnalysisField || '');
const previousAnalysisField = ref('');
const offset = ref(0);
const limit = ref(50);

// Specific IDs we want to filter
const specificBubbleIds = [
  // Customer  
  '1',
  '2',
  '3',
  '4',

  // Product
  '9',
  '10',

  // Sales
  '5',
  '6',
  '7',
  '8',


  '13',
  '33',
  '14',
  '23',
  '34',
  '11',
  '31',
  '16'
];

// Filtered bubbles computed property
const filteredBubbles = computed(() => {
  if (!bubbleData.value) return [];
  
  const filtered = bubbleData.value.filter(bubble => 
    specificBubbleIds.includes(bubble.id)
  );
  
  return filtered;
});

// Computed property to group filtered bubbles by area
const bubblesGroupedByArea = computed(() => {
  if (!filteredBubbles.value.length) return [];
  
  // Get unique areas
  const areas = [...new Set(filteredBubbles.value.map(bubble => bubble.area))];
  
  // Group bubbles by area
  return areas.map(areaName => {
    return {
      areaName,
      bubbles: filteredBubbles.value.filter(bubble => bubble.area === areaName)
    };
  });
});

const handleInput = async (event) => {
  const selectedOptionValue = event.target.value;
  
  if (!selectedOptionValue) {
    selectedAnalysisField.value = '';
    handleAnalysisFieldSelection();
    return;
  }

  // Find the selected bubble in the filteredBubbles
  const selectedBubble = filteredBubbles.value.find(bubble => bubble.id === selectedOptionValue);
  
  if (selectedBubble) {
    selectedAnalysisField.value = {
      area: selectedBubble.area.toLowerCase(), // Ensure area is lowercase
      analysis_field_id: parseInt(selectedBubble.id),
      title: selectedBubble.title
    };

    // Fetch and emit the properties for the selected analysis field
    const bubbleValues = await getBubbleValues(selectedBubble.id);
    if (bubbleValues) {
      emit('bubble-values-fetched', bubbleValues);
    }
  } else {
    selectedAnalysisField.value = '';
  }
  
  handleAnalysisFieldSelection();
};

const isValid = computed(() => {
  return selectedAnalysisField.value !== '';
});

async function getBubbleData() {
  try {
    if (!bubbleData.value) await fetchBubbleData();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching bubble data:', error);
  }
}

async function getBubbleValues(selectedId) {
  if (!selectedId) return;

  try {
    const bubbleValues = await fetchBubbleValues([selectedId, '', offset.value, limit.value]);

    // Emit the bubble values to the parent component
    emit('bubble-values-fetched', bubbleValues);
    
    return bubbleValues;

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error fetching bubble values for ID: ${selectedId}:`, error);
    return null;
  }
}

onMounted(async () => {
  await getBubbleData();

  // If we have a selectedAnalysisField, set it as the selected field
  if (props.selectedAnalysisField) {
    selectedAnalysisField.value = props.selectedAnalysisField;
    handleAnalysisFieldSelection();
  }
});

const storePreviousField = () => {
  previousAnalysisField.value = selectedAnalysisField.value;
};

const handleAnalysisFieldSelection = async () => {
  const fieldData = selectedAnalysisField.value;

  emit('field-selected', fieldData);
  emit('validity-changed', isValid.value);
};

watch(() => props.selectedAnalysisField, async (newValue) => {
  if (newValue?.analysis_field_id) {
    // Fetch bubble values for the selected analysis field
    const bubbleValues = await getBubbleValues(newValue.analysis_field_id);
    
    // If there are values, emit them to parent
    if (bubbleValues) {
      emit('bubble-values-fetched', bubbleValues);
    }
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
  .analysis-field {
    position: relative;
  }

  select {
    background-image: url( 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="%235c5c5c" d="M23.9998169,32.4246826 L36.0206337,20.4038696 L33.1922054,17.5754395 L23.9998188,26.767828 L14.8074324,17.5754395 L11.9790039,20.4038663 L23.9998169,32.4246826 Z" /></svg>');
    background-position: right var(--spacing-1) top 50%;
    background-size: 30px;
  }
</style>