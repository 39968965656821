<template>
  <div class="graph">
    <div class="graph__label">
      {{ t('target') }}
    </div>
    <div 
      class="graph__bar" 
      :style="{ width: targetWidth }"
    />
    <div class="graph__value">
      <CustomButton
        purpose="text"
        class="trigger"
        @click="showTargetIntervalModal"
      >
        {{ cPrice(targetValue) }}
      </CustomButton>
    </div>
    <div class="graph__label">
      {{ t('Attained') }}
    </div>
    <div
      class="graph__bar graph--attained"
      :style="{ width: attainedWidth }"
    />
    <div class="graph__value">
      {{ cPrice(attainedValue) }}
    </div>
  </div>
  <Teleport to="#modal-teleport-target">
    <ViewTargetsAmountValues
      v-if="isTargetIntervalModalVisible"
      :interval-data="intervalData"
      :current-period-index="props.dateArray?.currentPeriodIndex || 0"
      @close-modal="closeTargetIntervalModal"
    />
  </Teleport> 
</template>

<script setup>
import { computed, watch } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';
import useCurrency from '@/shared/composables/useCurrency';
import ViewTargetsAmountValues from '@/admin/components/Targets/ViewTargetsAmountValues.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  dateArray: {
    type: Object,
    default: () => ({})
  },
  values: {
    type: Array,
    default: () => []
  }
});

const { cPrice } = useCurrency();

const {
  isTargetIntervalModalVisible,
  getCurrentPeriodIndex
} = useTargets();

// Track the selected period index
const selectedPeriodIndex = computed(() => {
  return getCurrentPeriodIndex(props.data?.id);
});

// const selectedPeriodIndex = ref(props.currentPeriodIndex);

const targetValue = computed(() => {
  // Get the target value for the current period
  const values = props.data.target_value.values;
  return values[props.currentPeriodIndex] || values[0] || 0;
});

const attainedValue = computed(() => props.data.attained.attained_value);

const targetWidth = computed(() => {
  if (attainedValue.value > targetValue.value) {
    return `${(targetValue.value / attainedValue.value) * 100}%`;
  }
  return '100%';
});

const attainedWidth = computed(() => {
  if (targetValue.value > attainedValue.value) {
    return `${(attainedValue.value / targetValue.value) * 100}%`;
  }
  return '100%';
});

const intervalData = computed(() => {
  return props.dateArray?.periods?.map((period, index) => ({
    date: period.startDate,
    value: props.values[index] || ''
  }));
});

function showTargetIntervalModal() {
  isTargetIntervalModalVisible.value = true;
}

function closeTargetIntervalModal() {
  isTargetIntervalModalVisible.value = false;
}


// Watch for changes in the currentPeriodIndex prop
watch(() => props.currentPeriodIndex, (newValue) => {
  selectedPeriodIndex.value = newValue;
});

</script>

<style lang="scss" scoped>
.graph {
  display: grid;
  align-items: center;
  gap: var(--spacing-half) var(--spacing-2);

  grid-template-areas:
    "label bar value"
    "label bar value";

  grid-template-columns: 6rem 1fr 5rem;
  grid-template-rows: repeat(2, auto);
}

.graph__bar {
  background-color: var(--colour-panel-g-32);
  border-radius: var(--border-radius-1);
  height: var(--spacing-2);
  max-width: 100%;
}

.graph__bar.graph--attained {
  background-color: var(--colour-data-viking-dark);
}

.chart-container {
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  align-items: center;
}

.bar-tooltip {
  width: 80px;
}

.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
}
</style>