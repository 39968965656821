<template>
  <div class="wrapper">
    <CustomButton
      purpose="text"
      class="trigger"
      @click="showTargetIntervalModal"
    >
      {{ cPrice(currentValue) }}
    </CustomButton>
  </div>

  <ViewTargetsAmountValues
    v-if="isTargetIntervalModalVisible"
    :interval-data="intervalData"
    :current-period="props.dateArray.currentPeriodIndex"
    @close-modal="closeTargetIntervalModal"
  />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import useTargets from '@/intelligence/composables/useTargets';
import useCurrency from '@/shared/composables/useCurrency';
import { CustomButton } from '@sales-i/dsv3';
import ViewTargetsAmountValues from '@/admin/components/Targets/ViewTargetsAmountValues.vue';

const props = defineProps({
  dateArray: {
    type: Object,
    default: () => ({})
  },
  targetPeriod: {
    type: Array,
    default: () => []
  },
  values: {
    type: Array,
    default: () => []
  },
  targetId: {
    type: String,
    required: true
  }
});

const { cPrice } = useCurrency();

const {
  generateDateArray,
  isTargetIntervalModalVisible,
} = useTargets();

const currentValue = computed(() => {
  const currentIndex = props.dateArray.currentPeriodIndex;
  return props.values[currentIndex] || '';
});

// Create combined data structure with dates and values
const intervalData = computed(() => {
  return props.dateArray?.periods?.map((period, index) => ({
    date: period.startDate,
    value: props.values[index] || ''
  }));
});

function showTargetIntervalModal() {
  isTargetIntervalModalVisible.value = true;
}

function closeTargetIntervalModal() {
  isTargetIntervalModalVisible.value = false;
}

onMounted(() => {
  generateDateArray();
});
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}
</style>