<template>
  <div class="date">
    <DropDown
      id="date"
      :is-dropdown-visible="isDropdownVisible"
      :show-footer="showFooter"
      :disabled="disabled"
      @trigger-click="toggleDropdown"
      @update:is-dropdown-visible="isDropdownVisible = $event"
    >
      <template #trigger>
        <DropDownTriggerSelect
          :disabled="disabled"
          :value="selectedDateText"
        >
          {{ selectedDateText || t('select_date') }}
        </DropDownTriggerSelect>
      </template>
    
      <template #content>
        <fieldset class="start-date">
          <legend class="visually-hidden">
            {{ t('start_date') }}
          </legend>
          <CustomInput
            id="startDate"
            :value="startDate"
            type="date"
            :label="t('start_date')"
            :min="todaysDate"
            :error="startDateError"
            :error-message="startDateErrorMessage"
            @input="handleStartDate"
          />
        </fieldset>

        <fieldset class="interval">
          <legend>{{ t('interval') }}</legend>
          <div class="interval__wrapper">
            <label
              for="every"
              class="visually-hidden"
            >
              {{ t('every') }}
            </label>
            <CustomInput
              id="every"
              type="number"
              min="1"
              :placeholder="t('every')"
              :value="intervalMultiplier"
              :error="intervalMultiplierError"
              :error-message="intervalMultiplierErrorMessage"
              @input="handleIntervalMultiplier"
            />
            <label
              for="day"
              class="visually-hidden"
            >
              {{ t('days') }}
            </label>
            <CustomSelect
              id="day"
              :value="interval"
              type="select"
              :items="intervals"
              @input="handleInterval"
            />
          </div>
        </fieldset>

        <fieldset class="end-date">
          <CustomInput
            id="endDate"
            :value="endDate"
            type="date"
            :label="t('end_date_required_for_interval_target_values')"
            :min="startDate"
            :max="maxEndDate"
            :error="endDateError"
            :error-message="endDateErrorMessage"
            @input="handleEndDate"
          />
        </fieldset>
      </template>

      <template
        v-if="showFooter"
        #footer
      >
        <CustomButton
          purpose="action"
          small
          @click="applyDateSelection"
        >
          {{ t('apply') }}
        </CustomButton>
      </template>
    </DropDown>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { tAdmin as t, dates } from '@sales-i/utils';
import { CustomButton, CustomInput, CustomSelect } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';
import DropDown from '@/admin/components/Targets/DropDown.vue';
import DropDownTriggerSelect from '@/admin/components/Targets/DropDownTriggerSelect.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  selectedDate: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['date-selected', 'validity-changed', 'number-of-values', 'date-array']);

// Create a variable for startDate that defaults to todays date but will update to reflect the selected date
// Initialize local state from props

const startDate = ref(props.selectedDate.startDate || new Date().toISOString().split('T')[0]);
const endDate = ref(props.selectedDate.endDate || '');

const interval = ref(props.selectedDate.interval || 'DAILY');
const intervalMultiplier = ref(props.selectedDate.intervalMultiplier || '');

const isDropdownVisible = ref(false);
const selectedDate = ref({ ...props.selectedDate });

const endDateError = ref(false);
const endDateErrorMessage = ref('');

const startDateError = ref(false);
const startDateErrorMessage = ref('');

const intervalMultiplierError = ref(false);
const intervalMultiplierErrorMessage = ref('');

const intervals = [
  {
    value: 'DAILY',
    text: t('day_s')
  },
  {
    value: 'WEEKLY',
    text: t('week_s')
  },
  {
    value: 'MONTHLY',
    text: t('month_s')
  },
  {
    value: 'YEARLY',
    text: t('year_s')
  }
];

const {
  dateArray,
  generateDateArray,
} = useTargets();

const todaysDate = dates.format(new Date(), 'short');
const lastDayOfYear = dates.format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd');
const maxEndDate = dates.format(new Date(new Date().getFullYear() + 1, 11, 31), 'yyyy-MM-dd');

const showFooter = computed(() => {
  // Check if all fields have values
  const allFieldsHaveValues = startDate.value && endDate.value && interval.value && intervalMultiplier.value;

  // Check if values have changed from saved selection or there's no initial startDate
  const valuesHaveChanged = startDate.value !== selectedDate.value.startDate || 
                            endDate.value !== selectedDate.value.endDate ||
                            interval.value !== selectedDate.value.interval ||
                            intervalMultiplier.value !== selectedDate.value.intervalMultiplier ||
                            !selectedDate.value.startDate;

  // Check if all validation is passed
  const allValidationPassed = !startDateError.value && !endDateError.value && !intervalMultiplierError.value;

  // Return true only if all conditions are met
  if (allFieldsHaveValues && valuesHaveChanged && allValidationPassed) {
    return true;
  }

  // Return false initially or if any condition is not met
  return false;
});

// Show the selected date in the input field 
const selectedDateText = computed(() => {
  if (props.selectedDate.startDate && props.selectedDate.interval && props.selectedDate.endDate) {
    const formattedStartDate = dates.format(props.selectedDate.startDate, 'short');
    const intervalText = intervals.find(i => i.value === props.selectedDate.interval)?.text || props.selectedDate.interval;
    const multiplier = props.selectedDate.intervalMultiplier || '1';
    const formattedEndDate = dates.format(props.selectedDate.endDate, 'short');

    return t('startdate_every_multiplier_period_until_enddate', { formattedStartDate, multiplier, period: intervalText, formattedEndDate });
  } else {
    return '';
  }
});

const isValid = computed(() => {
  return selectedDate.value.startDate !== '' && 
         selectedDate.value.endDate !== '' && 
         selectedDate.value.interval !== '';
});

function handleStartDate(selectedStartDate) {
  startDate.value = selectedStartDate;
  validateStartDate();
  validateEndDate();
}

const handleInterval = (selectedInterval) => {
  interval.value = selectedInterval;
};

const handleIntervalMultiplier = (selectedIntervalMultiplier) => {
  intervalMultiplier.value = selectedIntervalMultiplier;
  validateIntervalMultiplier();
};

const handleEndDate = (selectedEndDate) => {
  endDate.value = selectedEndDate;  
  validateEndDate();
};

async function applyDateSelection() {
  // Create date object with current values
  const dateSelection = {
    startDate: startDate.value,
    endDate: endDate.value,
    interval: interval.value,
    intervalMultiplier: intervalMultiplier.value
  };
  
  await generateDateArray(dateSelection);

  // Close the dropdown
  isDropdownVisible.value = false;
    
  // Emit all necessary information
  emit('date-array', dateArray.value);
  emit('date-selected', dateSelection);
  emit('validity-changed', isValid.value);

  // Focus handling
  document.getElementById('date').focus();
}

// New function to generate the array of dates
function validateStartDate() {
  // Check if start date is before today
  if (startDate.value < todaysDate.value) {
    startDateErrorMessage.value = t('start_date_cannot_be_before_today');
    startDateError.value = true;
  } else {
    startDateErrorMessage.value = '';
    startDateError.value = false;
  }
}

function validateEndDate() {
  // Only show error if end date exists and is before start date
  const hasEndDate = endDate.value !== '';
  const isEndDateBeforeStart = hasEndDate && endDate.value < startDate.value;
  
  // Add validation for end date after the last day of next year
  const endDateObj = new Date(endDate.value);
  const maxEndDateObj = new Date(maxEndDate);
  const isEndDateTooFar = hasEndDate && endDateObj > maxEndDateObj;
  
  if (isEndDateBeforeStart) {
    endDateErrorMessage.value = t('end_date_must_be_after_start_date');
    endDateError.value = true;
  } else if (isEndDateTooFar) {
    endDateErrorMessage.value = t('end_date_cannot_be_after_the_end_of_next_year');
    endDateError.value = true;
  } else {
    endDateErrorMessage.value = '';
    endDateError.value = false;
  }
}

function validateIntervalMultiplier() {
  // Check that the interval multiplier is a whole number and has no decimal place
  const isWholeNumber = /^[1-9]\d*$/.test(intervalMultiplier.value);

  if (!isWholeNumber) {
    intervalMultiplierErrorMessage.value = t('must_be_a_whole_number');
    intervalMultiplierError.value = true;
  } else {
    intervalMultiplierErrorMessage.value = '';
    intervalMultiplierError.value = false;
  }
}

onMounted(() => {
  if (props.selectedDate && Object.keys(props.selectedDate).length > 0) {
    startDate.value = props.selectedDate.startDate || startDate.value;
    endDate.value = props.selectedDate.endDate || lastDayOfYear; 
    interval.value = props.selectedDate.interval || interval.value;
    intervalMultiplier.value = props.selectedDate.intervalMultiplier || intervalMultiplier.value;
    
    // Update the internal selectedDate state
    selectedDate.value = { ...props.selectedDate };
  } else {
    // If no selectedDate is provided, set the end date to the last day of current year
    endDate.value = lastDayOfYear;
  }
});

watch(() => props.selectedDate, async (newValue) => {
  if (newValue && newValue.startDate && newValue.endDate && 
      newValue.interval && newValue.intervalMultiplier) {
    
    await generateDateArray(newValue);
  }
}, { deep: true, immediate: true });
</script>

<style lang="scss" scoped>
:deep(.form-group .error-text) {
  // The default erro message doesn't wrap in the dropdown so this fixes that
  bottom: auto;
  position: relative;
  white-space: wrap;
}


:deep(.dropdown-trigger) {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" ><g stroke="none" stroke-width="1" fill-rule="evenodd"><path d="M18,24 L18,28 L14,28 L14,24 L18,24 Z M18,32 L18,36 L14,36 L14,32 L18,32 Z M22,24 L22,28 L26,28 L26,24 L22,24 Z M26,32 L26,36 L22,36 L22,32 L26,32 Z M30,24 L30,28 L34,28 L34,24 L30,24 Z M34,32 L34,36 L30,36 L30,32 L34,32 Z M38,44 L10,44 C7.79399967,44 6,42.2060013 6,40 L6,12 C6,9.79399967 7.79399967,8 10,8 L14,8 L14,4 L18,4 L18,8 L30,8 L30,4 L34,4 L34,8 L38,8 C40.2060013,8 42,9.79399967 42,12 L42,40 C42,42.2060013 40.2060013,44 38,44 L38,44 Z M38.0002861,16 L38,12 L10,12 L10,16 L38.0002861,16 L38.0002861,16 Z M38.0005722,20 L38.0019989,40 L10,40 L10,20 L38.0005722,20 L38.0005722,20 Z" /></g></svg>');
  background-size: 30px;
}

.form-group {
  margin-bottom: 0;
}

.interval__wrapper {
  display: flex;
  gap: var(--spacing-2);

  :deep(select) {
    width: auto;
  }
}

.interval legend {
  padding-block-start: var(--spacing-1);
  text-align: left;
}
</style>