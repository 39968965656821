<template>
  <div class="target-amount flow">
    <!-- Single input for same value mode -->
    <template v-if="props.isSameValue">
      <div>
        <label for="target-amount-single">
          {{ t('target_amount') }}
        </label>
        <CustomInput
          id="target-amount-single"
          type="number"
          min="1"
          class="target-amount"
          :value="singleValue"
          :disabled="props.disabled"
          :placeholder="t('enter_target_amount')"
          :error="validationErrors.single.hasError"
          :error-message="validationErrors.single.message"
          @input="handleSingleValue"
        />
      </div>
    </template>

    <template v-else>
      <div
        v-for="(date, index) in valueFields"
        :key="index"
      >
        <label :for="`target-amount-${index}`">
          {{ formatDate(date) }}
        </label>
        <CustomInput
          :id="`target-amount-${index}`"
          type="number"
          min="1"
          class="target-amount"
          :value="targetAmounts[index] || DEFAULT_VALUE"
          :disabled="props.disabled"
          :name="`target-amount-${index}`"
          :placeholder="t('enter_target_amount')"
          :error="validationErrors.fields[index]?.hasError"
          :error-message="validationErrors.fields[index]?.message"
          @input="(value) => handleTargetAmount(value, index)"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { tAdmin as t, dates } from '@sales-i/utils';
import { CustomInput } from '@sales-i/dsv3';

// Default value to use for initialization
const DEFAULT_VALUE = '0';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  dateArray: {
    type: Array,
    default: () => []
  },
  initialTargetAmount: {
    type: Array,
    default: () => []
  },
  isSameValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['target-amount', 'validity-changed', 'closeModal', 'reset-state', 'update-for-same-value-mode']);

const targetAmounts = ref([]);
const singleValue = ref(DEFAULT_VALUE);
const validationErrors = ref({
  single: {
    hasError: false,
    message: ''
  },
  fields: []
});

function validateValue(value) {
  const isValid = /^[0-9]+$/.test(value) || value === '';
  return {
    hasError: !isValid && value !== '',
    message: !isValid && value !== '' ? t('must_be_a_whole_number') : ''
  };
}


const isValid = computed(() => {
  if (props.isSameValue) {
    return singleValue.value !== '' && !validationErrors.value.single.hasError;
  } else {
    return targetAmounts.value.some(amount => amount !== '') && 
           !validationErrors.value.fields.some(field => field?.hasError === true);
  }
});

// Extract the start dates from the dateArray prop
const valueFields = computed(() => {
  const dates = props.dateArray || [];
  
  // Check if dateArray exists and has periods property
  if (dates && dates.periods) {
    return dates.periods.map(period => period.startDate);
  }
  
  // If dateArray is just an array of objects with startDate
  if (dates.length > 0 && dates[0].startDate) {
    return dates.map(period => period.startDate);
  }

  return dates;
});

// Initialize or reset all state
function initializeState() {
  const length = valueFields.value.length || 1;
  
  // Initialize error arrays
  validationErrors.value.fields = Array(length).fill(null).map(() => ({
    hasError: false,
    message: ''
  }));

  // If we have initial values, use them
  if (props.initialTargetAmount && props.initialTargetAmount.length > 0) {
    // Copy initial values and ensure correct length
    const initialValues = [...props.initialTargetAmount];
    while (initialValues.length < length) {
      initialValues.push(DEFAULT_VALUE);
    }
    if (initialValues.length > length) {
      initialValues.length = length;
    }
    targetAmounts.value = initialValues;
    
    // Set single value mode if applicable
    if (props.isSameValue) {
      singleValue.value = initialValues[0] || DEFAULT_VALUE;
      validationErrors.value.single = validateValue(singleValue.value);
    } else {
      // Validate each initial value
      initialValues.forEach((value, index) => {
        validationErrors.value.fields[index] = validateValue(value);
      });
    }
  } else {
    // Otherwise create an array of default values
    targetAmounts.value = Array(length).fill(DEFAULT_VALUE);
    singleValue.value = DEFAULT_VALUE;
  }
  
  // Emit initial values
  emitCurrentState();
}

function emitCurrentState() {
  emit('target-amount', [...targetAmounts.value]);
  emit('validity-changed', isValid.value);
}

// Handle single value input - populate all dates with same value
const handleSingleValue = (value) => {
  singleValue.value = value;
  
  // Create an array of the same value for all dates
  const length = valueFields.value.length || 1;
  targetAmounts.value = Array(length).fill(value);
  
  // Validate single value
  validationErrors.value.single = validateValue(value);
  
  emitCurrentState();
};

const handleTargetAmount = (value, index) => {
  // Ensure targetAmounts has the correct length before updating
  while (targetAmounts.value.length < valueFields.value.length) {
    targetAmounts.value.push(DEFAULT_VALUE);
  }

  // Create a new array to ensure reactivity
  const newAmounts = [...targetAmounts.value];
  newAmounts[index] = value;
  targetAmounts.value = newAmounts;
  
  const newFieldErrors = [...validationErrors.value.fields];
  newFieldErrors[index] = validateValue(value);
  validationErrors.value.fields = newFieldErrors;

  // Emit the updated state
  emitCurrentState();
};

const formatDate = (dateString) => {
  return dates.format(dateString, 'short');
};

function handleUpdateForSameValueMode(isSameValueMode) {
  if (isSameValueMode) {
    // Use the first value or default when switching to same value mode
    const firstValue = targetAmounts.value[0] || DEFAULT_VALUE;
    singleValue.value = firstValue;
    targetAmounts.value = Array(valueFields.value.length || 1).fill(firstValue);
    validationErrors.value.single = validateValue(firstValue);
  } else {
    // Validate all fields when switching to different values mode
    targetAmounts.value.forEach((value, index) => {
      validationErrors.value.fields[index] = validateValue(value);
    });
  }
  emitCurrentState();
}
// Listen for reset-state and update-for-same-value-mode events
emit('reset-state', initializeState);
emit('update-for-same-value-mode', handleUpdateForSameValueMode);

// Initialize on mount
onMounted(() => {
  initializeState();
});
</script>

<style lang="scss" scoped>
.target-amount  {
  display: grid;
  gap: var(--spacing-2);
  margin-bottom: 0;

  label {
    margin-block-end: 0;
  }

  & > * {
    display: grid;
    align-items: center;
    gap: var(--spacing-3);
    grid-template-columns: 120px 1fr;
  }

  :deep(input:disabled) {
    opacity: 0.7;
  }
}
</style>