<template>
  <template v-if="target">
    <div class="selected-target flow">
      <template
        v-for="field in target.analysis_fields"
        :key="field.analysis_field"
      >
        <div class="doughnuts">
          <ViewTargetsDoughnut
            :current-value="attainedValue"
            :total-value="targetValue"
            :remaining-value="remainingValue"
            :text-size="14"
            :text-size-subtext="6"
          >
            {{ cPrice(remainingValue) }}
            <template #subText>
              {{ t('to reach target') }}
            </template>
          </ViewTargetsDoughnut>

          <ViewTargetsDoughnut
            :current-value="elapsedDays"
            :total-value="totalDays"
            :remaining-value="remainingDays"
            :text-size="14"
            :text-size-subtext="8"
          >
            {{ remainingDays }}
            <template #subText>
              {{ t('days to go') }}
            </template>
          </ViewTargetsDoughnut>
        </div>

        <p class="selected-target__title">
          {{ field.title }} ({{ formatTargetPeriod(target.target_period) }})
        </p>

        <div class="bars">
          <ViewTargetsChart
            :data="target"
          />
        </div>     
      </template>
    </div>
  </template>
</template>

<script setup>
import { computed, ref } from 'vue';
import { t } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';
import useTargets from '@/intelligence/composables/useTargets';
import ViewTargetsChart from '@/admin/components/Targets/ViewTargetsChart.vue';
import ViewTargetsDoughnut from '@/admin/components/Targets/ViewTargetsDoughnut.vue';

const props = defineProps({
  selectedTargets: {
    type: Array,
    default: () => []
  },
  target: {
    type: Object,
    default: null
  },
  formatTargetPeriod: {
    type: Function,
    default: () => true,
  },
  measurementValue: {
    type: Function,
    default: () => true,
  },
  attained: {
    type: Function,
    default: () => true,
  }
});

const {
  daysFromInterval,
} = useTargets();
const { cPrice } = useCurrency();

const elapsedDays = ref(4); // Hard-coded for now. This will be dynamic in the future

// Time-based data for the doughnuts
const totalDays = computed(() => daysFromInterval[props.target.target_period.interval] || 1);

// Doughnut data
const targetValue = computed(() => props.target.target_value.value);
const attainedValue = computed(() => props.target.attained.attained_value);
const remainingDays = computed(() => totalDays.value - elapsedDays.value);
const remainingValue = computed(() => targetValue.value - attainedValue.value);
</script>

<style lang="scss" scoped>
  .selected-target {
    background-color: var(--colour-utility-white);
    box-shadow: 0 -2px 4px var(--colour-panel-g-24);
    padding: var(--spacing-2);
  }
  .doughnuts {
    display: flex;
    gap: var(--spacing-6);
    justify-content: center;
  }

.selected-target__title {
  text-align: center; 
}

.selected-target__details {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: 10rem auto 1fr;
  align-items: center;
}

.analysis-fields {
  display: flex;
  gap: var(--spacing-1);
}
</style>