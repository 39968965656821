export const getResponseParameters = () => type => {
  switch (type) { 
  case 'get':
    return `
      targets {
        id
        last_update
        share_with {
          name
          value
        }
        analysis_fields {
          analysis_field_id
          analysis_field_name
          area
          title
          values {
            code
            value
          }
          type
        }
        target_period {
          interval
          intervalMultiplier
          startDate
          endDate
        }
        target_value {
          measurement {
            metric
            unit
          }
          values
        }
        attained {
          attained_value
          progress
        }
      }
    `;
  case 'add':
  case 'update':
  case 'delete':
    return `
      id
      last_update
      share_with {
          name
          value
      }
      analysis_fields {
        analysis_field_id
        analysis_field_name
        area
        title
        values {
          code
          value
        }
        type
      }
      target_period {
        interval
        intervalMultiplier
        startDate
        endDate
      }
      target_value {
        measurement {
          metric
          unit
        }
        values
      }
      attained {
        attained_value
        progress
      }
    `;
  case 'deleteTargetsByBubbleId':
    return '';
  case 'getTargetPeriod':
    return `
      periods {
        startDate
        endDate
      }
      count
      currentPeriodIndex
    `;
  }
};