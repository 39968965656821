/* eslint-disable no-unused-vars */
import {
  GET_OPPORTUNITY_STAGES,
  GET_OPPORTUNITY_STAGES_LOADING,
  GET_OPPORTUNITY_STAGES_ERROR,
  PUT_OPPORTUNITY_STAGE,
  PUT_OPPORTUNITY_STAGE_LOADING,
  PUT_OPPORTUNITY_STAGE_ERROR
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const stagesMutations = {
  [GET_OPPORTUNITY_STAGES]: (state, data) => {
    state.stages.data = data;
    state.stages.loaded = true;
    state.stages.loading = false;
    state.stages.error = undefined;
  },
  [GET_OPPORTUNITY_STAGES_LOADING]: (state) => {
    state.stages.loaded = false;
    state.stages.loading = true;
    state.stages.error = undefined;
  },
  [GET_OPPORTUNITY_STAGES_ERROR]: (state, error) => {
    state.stages.loaded = false;
    state.stages.loading = false;
    state.stages.error = error;
  },
  [PUT_OPPORTUNITY_STAGE]: (state) => {
    state.stages.loaded = true;
    state.stages.loading = false;
    state.stages.error = undefined;
  },
  [PUT_OPPORTUNITY_STAGE_LOADING]: (state) => {
    state.stages.loaded = false;
    state.stages.loading = true;
    state.stages.error = undefined;
  },
  [PUT_OPPORTUNITY_STAGE_ERROR]: (state, error) => {
    state.stages.loaded = false;
    state.stages.loading = false;
    state.stages.error = error;
  }
};

// actions
/**
 * parameters:
 *  customer
 *  prospect
 *  offset
 *  limit
 *  sort
 */
export const stagesActions = {
  [GET_OPPORTUNITY_STAGES]: async ({ commit, }) => {
    try {
      commit(GET_OPPORTUNITY_STAGES_LOADING);
      const data = await httpRequest('get', 'config/schema/opportunities/stages', {}, {version: '2',});
      commit(GET_OPPORTUNITY_STAGES, data);
      return true;
    } catch (error) {
      commit(GET_OPPORTUNITY_STAGES_ERROR, error);
      return false;
    }
  },
  [PUT_OPPORTUNITY_STAGE]: async ({ commit, dispatch}, payload) => {
    try {
      commit(PUT_OPPORTUNITY_STAGE_LOADING);
      /** @todo check response */
      const response = await httpRequest('put', 'opportunities/stages', payload, {version: '2',});
      await dispatch('GET_OPPORTUNITY_STAGES');
      commit(PUT_OPPORTUNITY_STAGE);
      return true;
    } catch (error) {
      commit(GET_OPPORTUNITY_STAGES_ERROR, error);
      return false;
    }
  },
};
