<template>
  <div class="button-wrapper">
    <CustomButton
      purpose="reversed"
      small
      @click="handleCancelCreateTarget"
    >
      {{ t('cancel') }}
    </CustomButton>
    
    <CustomButton
      v-if="!isEditMode"
      purpose="action"
      small
      :disabled="!isValid"
      @click="handleCreateTarget(formData)"
    >
      {{ t('create') }}
    </CustomButton>
    
    <CustomButton
      v-if="isEditMode"
      purpose="action"
      small
      @click="handleUpdateTarget(formData)"
    >
      {{ t('save') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { tAdmin as t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { CustomButton } from '@sales-i/dsv3';
import { useDashboard } from '@/admin/components/Dashboard/composables/useDashboard';
import useTargets from '@/intelligence/composables/useTargets';
import useNotification from '@/shared/composables/useNotification';
import useSystem from '@/shared/composables/useSystem';

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({})
  },
  isValid: {
    type: Boolean,
    default: false
  },
  targetToEdit: {
    type: Object,
    default: null
  },
  isEditMode: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['target-cancelled', 'target-created', 'refresh-targets']);
const store = useStore();

const {
  formatTargetPeriod,
  queryTargetData
} = useTargets();
const { postSubjects } = useDashboard({ store });
const { sendNotification } = useNotification();
const { userDetails } = useSystem({ store });

const targetId = props.targetToEdit?.id ?? null;

async function shareTargetWithUsers(target) {
  if (!props.formData.selectedUsers.length) return;

  // Check existing shared users from the target being editedand just render the value
  const existingSharedUsers = props.targetToEdit?.share_with.map(user => user.value) ?? [];

  // Get the selected users from the form data
  const selectedUsers = props.formData.selectedUsers.map(user => user.value);

  // If any of the selected users are already in the 'share with' list skip them
  const usersToShareWith = selectedUsers.filter(user => !existingSharedUsers.includes(user));

  const widgetName = `${props.formData.selectedAnalysisField.title ?? ''} (${formatTargetPeriod.value(props.formData.selectedDate)})`;

  const item = {
    dashboard: {
      title: t('targets'),
      description: t('targets_widgets'),
      category: 'targets'
    },
    widget: {
      name: widgetName,  
      description: widgetName,
      report_type: 'target',
      requestParams: target,
      settings: {'url': ''},
      order: 1000,
      width: 1,
      height: 1,
      fixed: true
    },
    subjects: usersToShareWith
  };

  await postSubjects(item);
}

async function handleCreateTarget(payload) {
  const response = await queryTargetData({
    type: 'add',
    params: payload
  });

  if (response) {
    shareTargetWithUsers(response);
    sendTargetNotification(payload, response);
    
    emit('target-created');
    emit('refresh-targets');
  }
}

async function handleUpdateTarget(payload) {
  const response = await queryTargetData({
    type: 'update',
    params: {
      ...props.formData,
      targetId
    }
  });

  if (response) {
    shareTargetWithUsers(response);
    sendTargetNotification(payload, response);

    emit('target-created');
    emit('refresh-targets');
  }
}

function sendTargetNotification(payload) {
  const existingSharedUsers = props.targetToEdit?.share_with.map(user => user.value) ?? [];
  const selectedUsers = payload.selectedUsers.map(user => user.value);
  const usersToShareWith = selectedUsers.filter(user => !existingSharedUsers.includes(user));
  const subjects = usersToShareWith;
  
  if (!subjects.length) return;
 
  sendNotification({
    type: 'SharedEnquiry',
    title: t('shared_target'),
    description: t('name_shared_a_target_with_you_the_following_target_type_was_used_selectedAnalysisField', {
      name: `${userDetails.value.first_name} ${userDetails.value.last_name}`, payload: payload, selectedAnalysisField: payload.selectedAnalysisField.title
    }),
    path: '/',
    subjects: subjects
  });
}

function handleCancelCreateTarget () {
  emit('target-cancelled');
}
</script>