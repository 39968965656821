<!-- todo: translate -->
<template>
  <div
    v-if="!isLoading"
  >
    <div
      v-for="target in targets"
      :key="target.id"
      class="target-details"
    >
      <button
        class="target-details__primary"
        :aria-expanded="isTargetSelected(target)"
        @click="toggleTargetSelection(target)"
        @keydown.enter="toggleTargetSelection(target)"
        @keydown.space="toggleTargetSelection(target)"
      >
        <template
          v-for="field in target.analysis_fields"
          :key="field.analysis_field_id"
        >
          <div>
            <p class="text-sm">
              {{ t('Target type') }}
            </p>
            <p>{{ analysisField(field) }}</p>
          </div>

          <div>
            <p class="text-sm">
              {{ t('Selected values') }}
            </p>

            <!-- Show only the first item in the getAnalysisPropertyValues(field) array -->
            <ul>
              <li>
                {{ getAnalysisPropertyValues(field)[0] }}
                <span 
                  v-if="getAnalysisPropertyValues(field).length > 1 && !isTargetSelected(target)"
                  class="selected-value-count"
                >
                  (+{{ getAnalysisPropertyValues(field).length - 1 }})
                </span>
              </li>
              <!-- When the user clicks, show the remaining list items -->
              <template v-if="isTargetSelected(target) && getAnalysisPropertyValues(field).length > 1">
                <li
                  v-for="(value, idx) in getAnalysisPropertyValues(field).slice(1)"
                  :key="idx"
                >
                  {{ value }}
                </li>
              </template>
            </ul>
          </div>
        </template>
      </button>

      <details class="target-details__secondary">
        <summary>
          <span>{{ t('Share with, Date, Target') }}</span>
          <IconBase
            class="summary-icon"
            icon-name="chevron-down"
          />
        </summary>
        <dl class="target-details__info">
          <dt>{{ t('Share with') }}</dt>
          <dd />
          
          <dt>{{ t('Date') }}:</dt>
          <dd>{{ formatTargetPeriod(target.target_period) }}</dd>
          
          <dt>{{ t('Target') }}:</dt>
          <dd>{{ formatTargetMetrics(target.target_value) }}</dd>
        </dl>
      </details>
    </div>
  </div>
  <BufferImage
    v-if="isLoading"
    color="var(--colour-utility-black)"
    float="center"
    class="loading-spinner"
  />
  <Pagination
    :offset="offset"
    :limit="limit"
    :records-count="targets.length"
    @page-changed="handlePageChanged"
  />

  <div class="selected-target-wrapper">
    <div class="button-wrapper flow">
      <IconButton
        icon-name="plus"
        purpose="action"
        icon-color="white"
        icon-size="32"
        variant="round"
        @click="toggleFormVisibility"
      />

      <CustomDropdown
        v-if="targetSelected"
        purpose="reversed"
        menu-position="left"
        close-on-selection
        :items="[
          {
            title: t('Edit'),
            action: 'edit'
          },
          {
            title: t('Delete'),
            action: 'delete'
          }
        ]"
        icon-color="var(--colour-utility-black)"
        @click="handleAction"
      />
    </div>

    <ViewTargetsDataVizSmall
      :target="graphTarget"
      :format-target-period="formatTargetPeriod"
      :measurement-value="measurementValue"
      :attained="attained"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { BufferImage, CustomDropdown, IconBase, IconButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import useTargets from '@/intelligence/composables/useTargets';
import useCurrency from '@/shared/composables/useCurrency';
import ViewTargetsDataVizSmall from '@/admin/components/Targets/ViewTargetsDataVizSmall.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { paginationProps, paginationEmits } from '@/shared/composables/usePagination';

const props = defineProps({
  ...paginationProps,
});
const emit = defineEmits([
  ...paginationEmits,
  'toggleFormVisibility',
  'editStateChanged'
]);

const selectedTargets = ref([]);
const isLoading = ref(true);

const {
  formatTargetPeriod,
  getAnalysisPropertyValues,
  targets,
  setupPagination,
  handleDropdownAction,
} = useTargets();

const { cPrice } = useCurrency();


const {
  limit,
  offset,
  handlePageChanged,
  loadPageData
} = setupPagination(props, emit);

// Target fields
const graphTarget = computed(() => selectedTargets.value[0] || null);
const measurementValue = computed(() => (targetValue) => cPrice(targetValue.value));
const analysisField = computed(() => (field) => field.title);
const formatTargetMetrics = computed(() => (targetValue) => `${cPrice(targetValue.value)} in ${targetValue.measurement.metric}`);
const attained = computed(() => (attained) => cPrice(`${attained.props.data.attained.attained_value}`));
const targetSelected = computed(() => selectedTargets.value.length > 0);

// Selection handling
const isTargetSelected = (target) => {
  return selectedTargets.value.some(selected => selected.id === target.id);
};

const toggleFormVisibility = () => {
  emit('toggleFormVisibility');
};

const toggleTargetSelection = (target) => {
  if (isTargetSelected(target)) {
    // Deselect if clicked again
    selectedTargets.value = [];
  } else {
    // Clear previous selections and select new target
    selectedTargets.value = [target];
  }
};

const handleAction = async (event) => {
  if (!graphTarget.value) return;
  
  const result = await handleDropdownAction(event.action, graphTarget.value.id);
  
  if (event.action === 'edit' && result) {
    emit('editStateChanged', {
      editingTargetId: result.editingTargetId,
      targetToEdit: result.targetToEdit,
      isEditMode: result.isEditMode
    });
  }
};

onMounted(async () => {
  try {
    isLoading.value = true; // Set to true before loading data
    await loadPageData();
  } catch (error) {
    // Handle any errors
    console.error(error);
  } finally {
    isLoading.value = false; // Set to false after data is loaded
  }
});
</script>

<style lang="scss" scoped>
  .target-details {
    background-color: var(--colour-panel-base);
    border-radius: var(--border-radius-1);
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    display: flex;
    flex-direction: column;
  }

    // If the use click the (+#) button, the parent will change background colour.
    .target-details:has(.target-details__primary[aria-expanded="true"]) {
      background-color: var(--colour-brand-puerto-rico);
    }

    .target-details__primary {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: var(--spacing-3);
      width: 100%;

      ul {
        list-style-type: none;
      }

      &:hover {
        cursor: pointer;
      }
    }


    .target-details__secondary {
      background-color: var(--colour-utility-white);
      padding-block: var(--spacing-1);
      padding-inline: var(--spacing-3);
    }

      .target-details__info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }


  summary:-webkit-details-marker {
    display: none;
  }

  summary {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center; 
  }

    .summary-icon {
      color: white;
      display: flex;
      place-content: center;
      transition: all 0.2s ease;
    }

    details[open] .target-details__primary {
      padding-bottom: 0;
    }
      
      details[open] .summary-icon {
        transform: rotate(180deg);
      }

      details[open] summary span {
        visibility: hidden;
      }

  .selected-target-wrapper {
    position: fixed;
    bottom: 96px;
    left: 0;
    right: 0;
    z-index: 100; // This scares me.
  }

  .button-wrapper {
    position: absolute;
    right: 0;
    top: -20px;
    padding-inline: var(--spacing-2);    
  }

  .selected-value-count {
    color: var(--colour-utility-action);
  }
  </style>