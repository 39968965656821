<template>
  <form
    v-if="isFormVisible"
    class="targets-form flow"
  >
    <CreateAnalysisFields
      :selected-analysis-field="selectedAnalysisField"
      @field-selected="handleAnalysisFieldSelection"
      @bubble-values-fetched="handleBubbleValuesFetched"
      @validity-changed="(value) => handleValidityChanged(value, 'analysisFields')"
    />

    <CreateProperty
      :disabled="!isAnalysisFieldSelected"
      :limit="limit"
      :is-edit-mode="isEditMode"
      :property-options="propertyOptions"
      :selected-properties="formData.selectedProperties"
      @load-more="handleLoadMoreProperties"
      @property-selected="handlePropertySelection"
      @validity-changed="(value) => updateValidity('property', value)"
    />

    <CreateUsers
      :disabled="!isPropertyFieldSelected"
      :limit="limit"
      :is-edit-mode="isEditMode"
      :users-options="usersOptions"
      :selected-users="formData.selectedUsers"
      @load-more="handleLoadMoreUsers"
      @user-selected="handleUserSelection"
    />

    <CreateDate
      :selected-date="formData.selectedDate"
      :disabled="!isPropertyFieldSelected"
      @date-array="handleDateArray"
      @date-selected="handleDateSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'date')"
    />

    <CreateMeasurement
      :selected-measurement="selectedMeasurement"
      :disabled="!isDateFieldSelected"
      @measurement-selected="handleMeasurementSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'measurement')"
    />

    <CreateTargetAmount
      :target-amount="formData.targetAmount"
      :is-edit-mode="isEditMode"
      :date-array="dateArray"
      :disabled="!isMeasurementFieldSelected"
      @target-amount-selected="handleTargetAmountSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'targetAmount')"
    />

    <CreateFormActions
      :is-edit-mode="isEditMode"
      :form-data="formData"
      :is-valid="isValid"
      :target-to-edit="targetToEdit"
      @target-cancelled="handleResetFormData"
      @target-created="handleResetFormData"
    />
  </form>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import useTargets from '@/intelligence/composables/useTargets';
import CreateAnalysisFields from '@/admin/components/Targets/CreateAnalysisFields.vue';
import CreateProperty from '@/admin/components/Targets/CreateProperty.vue';
import CreateUsers from '@/admin/components/Targets/CreateUsers.vue';
import CreateDate from '@/admin/components/Targets/CreateDate.vue';
import CreateMeasurement from '@/admin/components/Targets/CreateMeasurement.vue';
import CreateTargetAmount from '@/admin/components/Targets/CreateTargetAmount.vue';
import CreateFormActions from '@/admin/components/Targets/CreateFormActions.vue';

const props = defineProps({
  isFormVisible: {
    type: Boolean,
    default: false
  },
  targetToEdit: {
    type: Object,
    default: null
  },
  isEditMode: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['form-cancelled', 'refresh-targets']);

const store = useStore();
const {
  formData,
  isAnalysisFieldSelected,
  isPropertyFieldSelected,
  isDateFieldSelected,
  isMeasurementFieldSelected,
  handleAnalysisFieldSelection,
  handlePropertySelection,
  handleUserSelection,
  handleDateSelection,
  handleMeasurementSelection,
  handleTargetAmountSelection,
  propertyOptions,
  updateValidity,
  resetFormData,
  handleBubbleValuesFetched,
  populateFormDataFromTarget,
  validityState,
  generateDateArray,
  handleLoadMoreProperties,
  handleLoadMoreUsers,
  getUsersOptions,
  usersOptions
} = useTargets({ store });


const dateArray = ref([]);

const isValid = computed(() => {
  // Check the values of the items in the validity object
  return Object.values(validityState.value).every(value => value);
});

const selectedAnalysisField = computed(() => props.targetToEdit?.analysis_fields?.[0]);
const selectedMeasurement = computed(() => props.targetToEdit?.target_value?.measurement?.metric || '');

const initializeEditMode = async () => {
  if (props.targetToEdit) {
    // Populate form with target data
    populateFormDataFromTarget(props.targetToEdit);
    
    // Generate date array specifically for edit mode
    if (props.targetToEdit.target_period) {
      const dateSelection = {
        startDate: props.targetToEdit.target_period.startDate,
        endDate: props.targetToEdit.target_period.endDate,
        interval: props.targetToEdit.target_period.interval,
        intervalMultiplier: props.targetToEdit.target_period.intervalMultiplier
      };
      
      // Wait for the date array to be generated
      const dates = await generateDateArray(dateSelection);
      
      // Update local dateArray
      handleDateArray(dates);
    }
    
    // Update validity states
    Object.keys(validityState.value).forEach(key => {
      updateValidity(key, true);
    });
  }
};

function handleValidityChanged(value, component) {
  validityState.value[component] = value;
}

const handleDateArray = (dates) => {
  dateArray.value = dates;
};

function handleResetFormData() {
  emit('form-cancelled');
  emit('refresh-targets');
  resetFormData(validityState);
}

onMounted(async () => {
  await getUsersOptions();
});

watch(
  [() => props.isEditMode, () => props.targetToEdit],
  async ([newIsEditMode, newTargetToEdit]) => {
    if (newIsEditMode && newTargetToEdit) {
      await initializeEditMode();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
  // Overrides
  :deep(.form-group .input-container .svg-container.icon.right,
  .form-group .input-container button.icon.right) {
    z-index: 2;
  }

  :deep(select):not(.expiry select),
  :deep(input[type="text"]),
  :deep(input[type="number"]) {
    font-weight: normal;
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: center;
  }


  input[type="checkbox"] {
    box-shadow: none;
  }

  .targets-form {
    width: 100%;
  }
</style>