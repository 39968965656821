<!-- todo: translate -->
<template>
  <tr
    v-if="isFormVisible"
  >
    <td>
      <div class="column-wrapper">
        <CreateAnalysisFields
          :selected-analysis-field="formData.selectedAnalysisField"
          @field-selected="handleFieldSelection"
          @validity-changed="(value) => handleValidityChanged(value, 'analysisFields')"
        />

        <CreateProperty
          :disabled="!isAnalysisFieldSelected"
          :selected-properties="formData.selectedProperties"
          :property-options="propertyOptions"
          @property-selected="handlePropertySelection"
          @validity-changed="(value) => handleValidityChanged(value, 'property')"
        />
      </div>
    </td>
    <td>
      <CreateUsers
        disabled
        :selected-user="selectedUsers"
        @user-selected="handleUserSelection"
      />
    </td>
    <td>
      <CreateData
        :selected-date="formData.selectedDate"
        :selected-expires-value="formData.selectedDate.expires"
        :selected-radio-value="formData.selectedDate.interval"
        :disabled="!isPropertyFieldSelected"
        @interval-selected="handleIntervalSelection"
        @expiry-year-selected="handleExpiryYearSelection"
        @validity-changed="(value) => handleValidityChanged(value, 'date')"
      />
    </td>
    <td>
      <div class="column-wrapper">
        <CreateMeasurement
          :selected-measurement="formData.selectedMeasurement"
          :disabled="!isDateFieldSelected"
          @measurement-selected="handleMeasurementSelection"
          @validity-changed="(value) => handleValidityChanged(value, 'measurement')"
        />

        <CreateTargetAmount
          :target-amount="formData.targetAmount"
          :disabled="!isMeasurementFieldSelected"
          @target-amount="handleTargetAmount"
          @validity-changed="(value) => handleValidityChanged(value, 'targetAmount')"
        />
      </div>
    </td>
    <td colspan="3">
      <CreateFormActions
        class="text-right"
        :is-edit-mode="isEditMode"
        :form-data="formData"
        :is-valid="isValid"
        :target-to-edit="targetToEdit"
        @target-cancelled="handleResetFormData"
        @target-created="handleResetFormData"
      />
    </td>
  </tr>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import useTargets from '@/intelligence/composables/useTargets';
import CreateAnalysisFields from '@/admin/components/Targets/CreateAnalysisFields.vue';
import CreateProperty from '@/admin/components/Targets/CreateProperty.vue';
import CreateUsers from '@/admin/components/Targets/CreateUsers.vue';
import CreateData from '@/admin/components/Targets/CreateDate.vue';
import CreateMeasurement from '@/admin/components/Targets/CreateMeasurement.vue';
import CreateTargetAmount from '@/admin/components/Targets/CreateTargetAmount.vue';
import CreateFormActions from '@/admin/components/Targets/CreateFormActions.vue';

const props = defineProps({
  isFormVisible: {
    type: Boolean,
    default: false
  },
  targetToEdit: {
    type: Object,
    default: null
  },
  isEditMode: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['form-cancelled', 'refresh-targets']);

const store = useStore();
const validity = ref({
  analysisFields: false,
  property: false,
  date: false,
  measurement: false,
  targetAmount: false,
});

const {
  formData,
  isAnalysisFieldSelected,
  isPropertyFieldSelected,
  isDateFieldSelected,
  isMeasurementFieldSelected,
  handleFieldSelection,
  handlePropertySelection,
  handleUserSelection,
  handleIntervalSelection,
  handleExpiryYearSelection,
  handleMeasurementSelection,
  handleTargetAmount,
  propertyOptions,
  selectedUsers,
  resetFormData,
  convertTargetData,
} = useTargets({ store });

const isValid = computed(() => {
  // Check the values of the items in the validity object
  return Object.values(validity.value).every(value => value);
});

function handleValidityChanged(value, component) {
  validity.value[component] = value;
}

function handleResetFormData() {
  emit('form-cancelled');
  emit('refresh-targets');
  resetFormData(validity);
}

watch(() => props.targetToEdit, async (newTarget) => {
  if (newTarget) {
    const { data, properties } = await convertTargetData(newTarget);
    // First set the base data
    formData.value = data;
    
    // Then set the properties after a tick to ensure reactive updates have processed
    await nextTick();
    formData.value.selectedProperties = properties;
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
  // Overrides
  :deep(.form-group .input-container .svg-container.icon.right,
  .form-group .input-container button.icon.right) {
    z-index: 2;
  }

  :deep(select):not(.expiry select),
  :deep(input[type="text"]),
  :deep(input[type="number"]) {
    font-weight: normal;
    width: auto !important;
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }

  input[type="checkbox"] {
    box-shadow: none;
  }

  tr {
    background-color: var(--colour-data-puerto-rico-light);
  }

    td {
      border: none;
      font-size: inherit;
    }

    /* Style the last td */
    td:last-child {
      padding-inline-end: var(--spacing-2);
    }

  .column-wrapper {
    display: flex;
    gap: var(--spacing-3);
  }
</style>