<template>
  <div class="rule-tree">
    <template
      v-for="(branch, i) in branches"
      :key="branch.objectLabel"
    >
      <div
        v-if="branch.objectLabel !== 'value' || selectedValues.type === 'analysis'"
        class="branch"
      >
        <div class="branch-heading">
          <div
            v-if="i === 0"
            class="hand"
          />
          <h4>{{ branch.label }}</h4>
          <div class="branch" />
          <div class="hand" />
        </div>
        <MenuFilter
          :items="branch.options"
          :selected-value="selectedValues[branch.objectLabel]"
          menu-position="right"
          @on-change="selectOption($event, branch)"
        />
      </div>
    </template>

    <div
      v-if="selectedValues.type === 'crm'"
    >
      <CustomInput
        id="field-value"
        :label="t('field_value')"
        :value="fieldValue"
        :required="true"
        :error="null"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { CustomInput, MenuFilter } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';
import { convertToUserFriendlyStr } from '@/shared/utils/strings';
import useAdminUsers from '@/admin/composables/useAdminUsers';

const store = useStore();
const {
  getFields,
  fieldsLookup,
} = useAdminUsers({ store });

const props = defineProps({
  rule: {
    type: [Object],
    default: null
  },
  isTotalAnalysisEntityMax: {
    type: Boolean,
    default: false
  },
  isTotalCrmEntityMax: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['isValid']);

const fields = computed(() => store.state.admin.users.fields.data);

const branches = ref([
  {
    label: t('field_type'),
    objectLabel: 'type',
    options: [{
      text: t('analysis_field'),
      value: 'analysis'
    }, {
      text: t('c_r_m_field'),
      value: 'crm'
    }],
  },
  {
    label: t('field_category'),
    objectLabel: 'entity',
    options: [],
  },
  {
    label: t('field_name'),
    objectLabel: 'field_id',
    options: [],
  },
  {
    label: t('operator'),
    objectLabel: 'operator',
    options: [],
  },
  {
    label: t('field_value'),
    objectLabel: 'value',
    options: [],
  },
]);

const selectedValues = ref({});
const fieldValue = ref('');

const findBranchByLabel = (label) => branches.value.find(branch => branch.objectLabel === label);

const updateBranchOptions = () => {
  if (!fields.value.operators) return;

  const crmOptions = fields.value.crm?.map(opt => ({
    text: convertToUserFriendlyStr(opt.name),
    value: opt.name,
  })) || [];
  
  const analysisOptions = fields.value.analysis?.map(opt => ({
    text: convertToUserFriendlyStr(opt.name),
    value: opt.name,
    type: opt.type
  })) || [];

  // Update type options based on maximums
  findBranchByLabel('type').options = [
    {
      text: t('analysis_field'),
      value: 'analysis'
    },
    {
      text: t('c_r_m_field'),
      value: 'crm'
    }
  ].filter(option => {
    if (option.value === 'crm' && props.isTotalCrmEntityMax && selectedValues.value.type !== 'crm') {
      return false;
    }
    if (option.value === 'analysis' && props.isTotalAnalysisEntityMax && selectedValues.value.type !== 'analysis') {
      return false;
    }
    return true;
  });

  // Update entity options based on selected type
  findBranchByLabel('entity').options = selectedValues.value.type === 'crm' ? crmOptions : analysisOptions;

  // Update operator options
  const optionText = {
    equals: t('equals'),
    not_equals: t('does_not_equal')
  };

  const operatorOptions = fields.value.operators?.map(opt => ({
    text: optionText[opt],
    value: opt,
  })) || [];
  findBranchByLabel('operator').options = operatorOptions;
};

const selectOption = async (option, branch) => {
  if (branch.objectLabel === 'type') {
    // Reset selections when type changes
    if (selectedValues.value[branch.objectLabel] !== option.value) {
      selectedValues.value = {};
      fieldValue.value = '';
    }
    selectedValues.value.type = option.value;
    updateBranchOptions();
  }
  
  if (branch.objectLabel === 'entity') {
    await getFields({
      type: selectedValues.value.type,
      entity: option.value,
    });
    const options = fields.value[option.value]?.map(opt => ({
      text: convertToUserFriendlyStr(opt.name),
      value: opt.id,
      type: opt.type
    })) || [];
    findBranchByLabel('field_id').options = options;
  }
  
  if (branch.objectLabel === 'field_id') {
    selectedValues.value.name = option.text;
    if (selectedValues.value.type === 'analysis') {
      await fieldsLookup({
        entity: selectedValues.value.entity,
        id: option.value,
      });

      const options = fields.value[selectedValues.value.entity]?.map(opt => ({
        field_lookup_id: opt.id,
        code: opt.code,
        text: `${opt.description} - ${opt.code}`,
        value: `${opt.description} - ${opt.code}`
      })) || [];
      findBranchByLabel('value').options = options;
      selectedValues.value.data_type = option.type;
    }
  }
  
  if (branch.objectLabel === 'value') {
    selectedValues.value.field_lookup_id = option.field_lookup_id;
    selectedValues.value.code = option.code;
  }
  
  selectedValues.value[branch.objectLabel] = option.value;
  checkValidity();
};

const checkValidity = () => {
  const isValid = Boolean(
    selectedValues.value.type &&
    selectedValues.value.entity &&
    selectedValues.value.field_id &&
    selectedValues.value.operator &&
    selectedValues.value.value
  );
  
  emit('isValid', {
    valid: isValid,
    values: selectedValues.value
  });
};

const handleInput = (input) => {
  fieldValue.value = input;
  selectedValues.value.value = input;
  checkValidity();
};

const setUpDefaultOptions = async () => {
  if (fields.value.operators) {
    updateBranchOptions();

    if (props.rule) {
      selectedValues.value.type = props.rule.type;

      await getFields({
        type: props.rule.type,
        entity: props.rule.entity,
      });

      const options = fields.value[props.rule.entity]?.map(opt => ({
        text: convertToUserFriendlyStr(opt.name),
        value: opt.id,
        type: opt.type
      })) || [];
      findBranchByLabel('field_id').options = options;

      if (props.rule.type === 'analysis' && props.rule.entity && props.rule.field_id) {
        await fieldsLookup({
          id: props.rule.field_id,
          entity: props.rule.entity,
        });

        const valueOptions = fields.value[props.rule.entity]?.map(opt => ({
          id: opt.id,
          text: `${opt.description} - ${opt.code}`,
          value: `${opt.description} - ${opt.code}`,
          field_lookup_id: opt.id,
          code: opt.code
        })) || [];
        findBranchByLabel('value').options = valueOptions;
      }

      Object.assign(selectedValues.value, {
        type: props.rule.type,
        name: props.rule.name,
        entity: props.rule.entity,
        id: props.rule.field_id,
        operator: props.rule.operator,
        value: props.rule.value,
        field_lookup_id: props.rule.field_lookup_id,
        code: props.rule.code,
        field_id: props.rule.field_id
      });

      if (props.rule.type === 'crm' && props.rule.value) {
        handleInput(props.rule.value);
      }
    }
  } else {
    setTimeout(setUpDefaultOptions, 100);
  }
};

onMounted(async () => {
  if (props.rule) {
    selectedValues.value.type = props.rule.type;
  }
  await setUpDefaultOptions();
});

watch([props.isTotalCrmEntityMax, props.isTotalAnalysisEntityMax], () => {
  updateBranchOptions();
  
  if (props.isTotalCrmEntityMax && selectedValues.value.type === 'crm' && (!props.rule || props.rule.type !== 'crm')) {
    // Do not reset the selection straight away
    checkValidity();
  } else {
    updateBranchOptions();
  }
});
</script>

<style lang="scss" scoped>
.rule-tree {
  display: flex;
  > div {
    flex: 1;
  }

  .form-group :deep(label) {
    color: var(--colour-utility-black);
    font-size: var(--font-size-4);
    line-height: 1.3;
    font-weight: var(--font-weight-medium);
  }
}

.branch-heading {
  display: flex;
  align-items: center;
  .branch {
    height: 1px;
    border: 1px solid black;
    flex: 1;
  }
  .hand {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: black;
    margin-right: var(--spacing-1);
  }
}
</style>