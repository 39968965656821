<template>
  <DropDown
    id="property"
    :is-dropdown-visible="dropdownVisibility.property"
    :show-footer="showFooter"
    :disabled="disabled"
    @trigger-click="toggleDropdown"
    @update:is-dropdown-visible="updateDropdownVisibility('property', $event)"
  >
    <template #label>
      {{ t('property') }}
    </template>

    <template #trigger>
      <DropDownTriggerSelect
        :disabled="disabled"
        :value="selectedPropertiesText"
      >
        {{ selectedPropertiesText || t('select_property') }}
      </DropDownTriggerSelect>
    </template>

    <template #content>
      <div class="p-2 flow">
        <CustomCheckbox
          v-for="value in propertyOptions"
          :key="value.code"
          :label="`${ value.value } - ${ value.code }`"
          :value="isChecked(value)"
          @input="updateTempSelectedProperties(value)"
        />

        <div
          v-if="propertyOptions.length >= limit"
          class="load-more-wrapper"
        >
          <CustomButton
            purpose="neutral"
            small
            :loading="isLoading"
            @click="loadMore"
          >
            {{ t('more') }}
          </CustomButton>
        </div>
      </div>
    </template>

    <template
      v-if="showFooter"
      #footer
    >
      <CustomButton
        purpose="action"
        small
        @click="applyPropertySelection"
      >
        {{ t('apply') }}
      </CustomButton>
    </template>
  </DropDown>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { CustomButton, CustomCheckbox } from '@sales-i/dsv3';
import DropDown from '@/admin/components/Targets/DropDown.vue';
import DropDownTriggerSelect from '@/admin/components/Targets/DropDownTriggerSelect.vue';
import useTargets from '@/intelligence/composables/useTargets';

const store = useStore();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  isEditMode: {
    type: Boolean,
    default: false
  },
  propertyOptions: {
    type: Array,
    default: () => []
  },
  selectedProperties: {
    type: Array,
    default: () => []
  },
  limit: {
    type: Number,
    default: 50
  }
});

const emit = defineEmits(['validity-changed', 'property-selected', 'load-more']);

const { 
  formData,
  updateValidity,
  handlePropertySelection,
} = useTargets({ store });

const dropdownVisibility = ref({ property: false });
const tempSelectedProperties = ref([...props.selectedProperties || []]);
const isLoading = ref(false);
const currentOffset = ref(0);

const showFooter = computed(() => {
  // Only show the apply button if the data in the tempSelectedProperties and selectedProperties are different.
  return JSON.stringify(tempSelectedProperties.value) !== JSON.stringify(formData.value.selectedProperties);
});

const selectedPropertiesText = computed(() => {
  // If we have tempSelectedProperties, use those
  const selected = tempSelectedProperties.value;
  if (selected.length > 1) {
    return `${selected[0].value} - ${selected[0].code} (+${selected.length - 1})`;
  } else if (selected.length === 1) {
    return `${selected[0].value} - ${selected[0].code}`;
  }
  return '';

  // return tempSelectedProperties.value.map(item => item.value).join(', ');
});


const isChecked = (value) => {
  return tempSelectedProperties.value.some(item => item.code === value.code);
};

const updateTempSelectedProperties = (value) => {
  const index = tempSelectedProperties.value.findIndex(item => item.code === value.code);

  if (index === -1) {
    tempSelectedProperties.value.push(value);
  } else {
    tempSelectedProperties.value.splice(index, 1);
  }
};

function applyPropertySelection() {
  // Update the formData with the new selectedProperties
  handlePropertySelection(tempSelectedProperties.value);
  
  const isValid = tempSelectedProperties.value.length > 0;
  updateValidity('property', isValid);
  emit('validity-changed', isValid);
  
  // Emit the selected properties
  emit('property-selected', tempSelectedProperties.value);

  updateDropdownVisibility('property', false);
  document.getElementById('property').focus();
}

const loadMore = async () => {
  isLoading.value = true;
  currentOffset.value += props.limit;
  
  // Emit event to parent component to load more data
  emit('load-more', currentOffset.value);
  
  // Reset loading state after a short delay
  setTimeout(() => {
    isLoading.value = false;
  }, 500);
};

const toggleDropdown = (dropdownId) => {
  dropdownVisibility.value[dropdownId] = !dropdownVisibility.value[dropdownId];
  for (const key in dropdownVisibility.value) {
    if (key !== dropdownId) {
      dropdownVisibility.value[key] = false;
    }
  }
};

const updateDropdownVisibility = (dropdownId, isVisible) => {
  dropdownVisibility.value[dropdownId] = isVisible;
};

onMounted(() => {
  // If we're in edit mode and there are existing selectedProperties,
  // make sure tempSelectedProperties is initialized with them
  if (props.selectedProperties && props.selectedProperties.length > 0) {
    tempSelectedProperties.value = [...props.selectedProperties];
    // Directly apply the selection on mount to ensure formData is updated
    handlePropertySelection(tempSelectedProperties.value);
  }

  // Reset offset when component is mounted
  currentOffset.value = 0;
});

// If the user selects a different analysis field, reset the tempSelectedProperties
watch(() => formData.value.selectedProperties, (newValue) => {
  tempSelectedProperties.value = [...newValue];
}, { deep: true });

watch(() => props.selectedProperties, (newValue) => {
  if (newValue) {
    tempSelectedProperties.value = [...newValue];
  }
}, { deep: true });

</script>

<style lang="scss" scoped>
  .load-more-wrapper {
    display: grid;
    place-content: center;
  }
</style>