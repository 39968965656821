<!-- todo: translate -->
<template>
  <div class="users flow">
    <DropDown
      id="users"
      :is-dropdown-visible="isDropdownVisible"
      :show-footer="showFooter"
      disabled="props.disabled"
      @trigger-click="toggleDropdown"
      @update:is-dropdown-visible="isDropdownVisible = $event"
    >
      <template #label>
        {{ t('Users') }}
      </template>

      <template #trigger>
        <div
          class="dropdown-trigger"
          :value="selectedUsersText"
        >
          {{ t('Select user(s)') }}
        </div>
        <IconBase
          class="chevron"
          width="32"
          height="32"
          icon-name="chevron-down"
          icon-color="var(--colour-panel-g-64)"
        />
      </template>

      <template #content>
        <div class="p-2 flow">
          <CustomCheckbox
            v-for="value in usersOptions"
            :key="value.value"
            :label="value.text"
            :value="isChecked(value)"
            @input="updateTempSelectedUser(value)"
          />
        </div>
      </template>

      <template
        v-if="showFooter"
        #footer
      >
        <CustomButton
          purpose="action"
          small
          @click="applyUserSelection"
        >
          {{ t('Apply') }}
        </CustomButton>
      </template>
    </DropDown>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomButton, CustomCheckbox, IconBase } from '@sales-i/dsv3';
import { useUsers } from '@/shared/composables/useUsers';
import DropDown from '@/admin/components/Targets/DropDown.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  selectedUsers: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['user-selected', 'validity-changed']);

const store = useStore();

const { usersOptions } = useUsers(store);

const isDropdownVisible = ref(false);
const tempSelectedUser = ref([]);

const isValid = computed(() => {
  return selectedUsersText.value.length > 0;
});

const showFooter = computed(() => {
  return JSON.stringify(tempSelectedUser.value) !== JSON.stringify(props.selectedUsers);
});

const isChecked = (value) => {
  const checked = tempSelectedUser.value.some(item => item.value === value.value);
  return checked;
};

const updateTempSelectedUser = (value) => {
  const index = tempSelectedUser.value.findIndex(item => item.value === value.value);

  if (index === -1) {
    tempSelectedUser.value.push(value);
  } else {
    tempSelectedUser.value.splice(index, 1);
  }
};

const applyUserSelection = () => {
  emit('user-selected', [...tempSelectedUser.value]);
  isDropdownVisible.value = false;
  emit('validity-changed', isValid.value);
};

const toggleDropdown = () => {  
  // When opening the dropdown, initialize tempSelectedUser with the current selectedUser
  if (isDropdownVisible.value) {
    tempSelectedUser.value = [...props.selectedUsers];
  }
};

// Computed property to generate the string representation of selected users
const selectedUsersText = computed(() => {
  const selectedUsers = props.selectedUsers;
  if (selectedUsers.length > 1) {
    return `${selectedUsers[0].text} (+${selectedUsers.length - 1})`;
  } else if (selectedUsers.length === 1) {
    return selectedUsers[0].text;
  } else {
    return '';
  }
});
</script>

<style lang="scss" scoped>
  .dropdown-list__footer {
    background-color: var(--colour-panel-g-4);
    display: flex;
    place-content: center;
    padding-block: var(--spacing-2);
    padding-inline: var(--spacing-3);
  }
  
  .dropdown-trigger {
    background-color: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    box-shadow: 0px 0px 0px 1px var(--border-utility-base);
    line-height: 1.75;
    outline: 2px solid transparent;
    padding-block: var(--spacing-1);
    padding-inline: var(--spacing-2) var(--spacing-6);
    text-align: left;
    white-space: nowrap;
    width: 100%;
  }

  .chevron {
    position: absolute;
    right: var(--spacing-1);
    top: var(--spacing-1);
  }
</style>