<template>
  <div class="filter-builder">
    <p>{{ t('insert_analysis_fields_and_select_values_to_only_export_accounts_that_meet_the_criteria') }}</p>
    <div class="header-row">
      <div class="column fw-sbld text-deluge">
        {{ t('analysis_field') }}
      </div>
      <div class="column fw-sbld text-deluge">
        {{ t('selected_values') }}
      </div>
      <div class="column create-filter-col">
        <CustomButton
          :disabled="!filteredAnalysisFields.length"
          class="create-filter-btn"
          small
          @click="toggleFilter"
        >
          {{ t('create_filter') }}
        </CustomButton>
      </div>
    </div>
    <template
      v-for="(filter, index) in analysisFieldFilter"
      :key="index"
    >
      <AnalysisFilter
        :filter="filter"
        :filter-index="index"
        @delete-filter="onDeleteFilter"
      />
    </template>
    <div 
      v-if="activeFilters"
      class="input-row"
    >
      <div class="column">
        <CustomSelect
          class="analysis-select"
          :items="filteredAnalysisFields"
          :placeholder="t('select_analysis_field')"
          :value="analysisFieldRef"
          icon-align="right"
          label-field="bubble_label"
          key-field="id"
          @on-change="onAnalysisFieldChange"
        />
      </div>
      <div class="column">
        <CustomMultiSelect
          v-model="selectedValues"
          class="custom-select"
          :disabled="!analysisFieldId"
          :fetch-function="searchResultsHandler"
          :select-value-prompt="t('please_select_value')"
          :icon-size="32"
          label-field="description"
        >
          <template #item="{ option, isSelected, toggleSelection }">
            <CustomCheckbox
              class="flex"
              :label="option.description"
              :value="isSelected"
              @input="() => toggleSelection(option)"
            />
          </template>
        </CustomMultiSelect>
      </div>
      <div class="column btn-group">
        <CustomButton
          small
          @click="cancelFilter"
        >
          {{ t('cancel') }}
        </CustomButton>
        <CustomButton
          :disabled="!selectedValues.length"
          small
          @click="createFilter"
        >
          {{ t('create') }}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { CustomButton, CustomSelect, CustomCheckbox, CustomMultiSelect } from '@sales-i/dsv3';
import AnalysisFilter from './AnalysisFilter.vue';
import { tAdmin as t } from '@sales-i/utils';
import { useProspect } from '@/crm/composables/useProspect';

const props = defineProps({
  analysisFieldFilter: {
    type: Array,
    default: () => []
  },
  filterIndex: {
    type: Number,
    required: true
  }
});

const { fetchAnalysisFields, searchAnalysisFields } = useProspect();

const emit = defineEmits(['update:analysis-field', 'delete-filter']);

const activeFilters = ref(false);
const analysisFields = ref([]);
const analysisFieldId = ref(null);
const analysisFieldLabel = ref('');
const selectedValues = ref([]);

const filteredAnalysisFields = computed(() => {
  const usedIds = props.analysisFieldFilter.map(f => f.analysis_field);
  return analysisFields.value.filter(field => !usedIds.includes(field.id));
});

onMounted(async () => {
  const data = await fetchAnalysisFields();
  if (data) analysisFields.value = data;
});

function toggleFilter() {
  activeFilters.value = !activeFilters.value;
}

function cancelFilter() {
  activeFilters.value = false;
  analysisFieldId.value = null;
  selectedValues.value = [];
}

function createFilter() {
  const analysisField = analysisFieldId.value;
  const analysisValues = selectedValues.value.map(item => ({
    id: item.id,
    label: item.description
  }));

  const payload = {
    analysis_field: Number(analysisField), // eg. 1
    analysis_field_label: analysisFieldLabel.value, // eg. "Sales Rep"

    analysis_values: analysisValues.map(v => v.id), // eg. [2, 11, 6]
    analysis_value_labels: analysisValues.map(v => v.label) // eg. ['Chris Samuel', 'Darren Franklin']
  };

  emit('update:analysis-field', payload);

  activeFilters.value = false;
  selectedValues.value = [];
}

function onAnalysisFieldChange(selectedId) {
  const selectedItem = analysisFields.value.find(item => item.id == selectedId);
  if (selectedItem) {
    analysisFieldLabel.value = selectedItem.bubble_label;
  }
  analysisFieldId.value = selectedId;
  selectedValues.value = [];
}

function onDeleteFilter(filterIndex) {
  emit('delete-filter', filterIndex);
}

async function searchResultsHandler(params = {}) {
  try {
    const { q } = params;
    const id = analysisFieldId.value;
    const results = await searchAnalysisFields({ id, q });
    return results;
  } catch (error) {
    console.error('Error fetching search results:', error);
  }
}
</script>

<style lang="scss" scoped>
.filter-builder {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--border-radius-half);
}

.header-row {
  display: flex;
  flex-direction: row;
  background-color: var(--colour-panel-action);
  padding: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.input-row {
  display: flex;
  flex-direction: row;
  background-color: var(  --colour-data-puerto-rico-light);
  padding: var(--spacing-2);

  .custom-select {
    min-width: 220px;
    width: initial;
  }
}

.column {
  flex: 1;
  display: flex;
  align-items: center;
}

.create-filter-col {
  justify-content: flex-end;
}

.btn-group {
  justify-content: flex-end;
  gap: var(--spacing-1);
}

.analysis-select {
  margin-bottom: 0;
}
</style>