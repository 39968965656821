import {
  POST_SUBJECTS,
  POST_SUBJECTS_LOADING,
  POST_SUBJECTS_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { mapWidgetForAPI } from './mappings';

// mutations
export const postSubjectsMutations = {
  [POST_SUBJECTS]: (state, widget) => {
    state.selected.data.widgets = [...(state.selected.data?.widgets || []), widget];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [POST_SUBJECTS_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_SUBJECTS_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
  },
};

// actions
export const postSubjectsActions = {
  [POST_SUBJECTS]: async ({ commit, }, widget) => {
    commit(POST_SUBJECTS_LOADING);
    try {
      const id = await httpRequest('post', 'dashboards/subjects', mapWidgetForAPI(widget), { area: 'dashboard' });
      commit(POST_SUBJECTS, { ...widget, id });
      return true;
    } catch (error) {
      console.error(error);
      commit(POST_SUBJECTS_ERROR);
      return false;
    }
  },
};
