// Alerts / ModalQueue Module
export const SET_ITEMS = 'SET_ITEMS';
export const REMOVE_BY_INDEX = 'REMOVE_BY_INDEX';
export const GET_MENTIONS = 'GET_MENTIONS';
export const DISMISS_MENTION = 'DISMISS_MENTION';

// System Actions
export const SET_ENTITY = 'SET_ENTITY';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const SET_ROUTE = 'SET_ROUTE';
export const PUSH_ROUTE = 'PUSH_ROUTE';
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';
export const SET_IFRAME = 'SET_IFRAME';
export const GET_TENANT = 'GET_TENANT';
export const GET_TENANT_LOADING = 'GET_TENANT_LOADING';
export const GET_TENANT_ERROR = 'GET_TENANT_ERROR';
export const TOGGLE_CUSTOMER_MODE = 'TOGGLE_CUSTOMER_MODE';

// PBAC
export const CHECK_PERMISSIONS = 'CHECK_PERMISSIONS';
export const CHECK_FEATURE_FLAGS = 'CHECK_FEATURE_FLAGS';
export const SET_ROLE = 'SET_ROLE';
export const SET_REJECTION_ACTION = 'SET_REJECTION_ACTION';
export const REJECT_ACCESS = 'REJECT_ACCESS';

// Common Actions
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_SUMMARY_LOADING = 'GET_SUMMARY_LOADING';
export const GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR';

export const GET_FIELDS = 'GET_FIELDS';
export const GET_FIELDS_LOADING = 'GET_FIELDS_LOADING';
export const GET_FIELDS_ERROR = 'GET_FIELDS_ERROR';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';

export const GET_BY_ID = 'GET_BY_ID';
export const GET_BY_ID_LOADING = 'GET_BY_ID_LOADING';
export const GET_BY_ID_ERROR = 'GET_BY_ID_ERROR';

export const GET_WIDGET_BY_ID = 'GET_WIDGET_BY_ID';
export const GET_WIDGET_BY_ID_LOADING = 'GET_WIDGET_BY_ID_LOADING';
export const GET_WIDGET_BY_ID_ERROR = 'GET_WIDGET_BY_ID_ERROR';
export const CLEAR_WIDGET_BY_ID = 'CLEAR_WIDGET_BY_ID';
export const SET_UNSAVED_MODAL_STATE = 'SET_UNSAVED_MODAL_STATE';
export const SET_QUERY_STRING_CHANGED_STATE = 'SET_QUERY_STRING_CHANGED_STATE';

export const GET_CUSTOMER_ID = 'GET_CUSTOMER_ID';
export const CLEAR_BY_ID = 'CLEAR_BY_ID';
export const PUT_BY_ID = 'PUT_BY_ID';
export const PUT_BY_ID_LOADING = 'PUT_BY_ID_LOADING';
export const PUT_BY_ID_ERROR = 'PUT_BY_ID_ERROR';
export const DELETE_BY_ID = 'DELETE_BY_ID';
export const DELETE_BY_ID_LOADING = 'DELETE_BY_ID_LOADING';
export const DELETE_BY_ID_ERROR = 'DELETE_BY_ID_ERROR';

export const GET_ALL = 'GET_ALL';
export const GET_ALL_LOADING = 'GET_ALL_LOADING';
export const GET_ALL_ERROR = 'GET_ALL_ERROR';
export const CLEAR_ALL = 'CLEAR_ALL';
export const GET_BY_ENTITY = 'GET_BY_ENTITY';
export const GET_BY_ENTITY_LOADING = 'GET_BY_ENTITY_LOADING';
export const GET_BY_ENTITY_ERROR = 'GET_BY_ENTITY_ERROR';
export const CLEAR_BY_ENTITY = 'CLEAR_BY_ENTITY';
export const PATCH_BY_ENTITY = 'PATCH_BY_ENTITY';
export const PATCH_BY_ENTITY_LOADING = 'PATCH_BY_ENTITY_LOADING';
export const PATCH_BY_ENTITY_ERROR = 'PATCH_BY_ENTITY_ERROR';
export const POST_BY_ENTITY = 'POST_BY_ENTITY';
export const POST_BY_ENTITY_LOADING = 'POST_BY_ENTITY_LOADING';
export const POST_BY_ENTITY_ERROR = 'POST_BY_ENTITY_ERROR';
export const DELETE_BY_ENTITY = 'DELETE_BY_ENTITY';
export const DELETE_BY_ENTITY_LOADING = 'DELETE_BY_ENTITY_LOADING';
export const DELETE_BY_ENTITY_ERROR = 'DELETE_BY_ENTITY_ERROR';
export const MERGE_INTERACTIONS = 'MERGE_INTERACTIONS';

export const GET_DEFAULT = 'GET_DEFAULT';
export const GET_DEFAULT_LOADING = 'GET_DEFAULT_LOADING';
export const GET_DEFAULT_ERROR = 'GET_DEFAULT_ERROR';
export const SET_DEFAULT  = 'SET_DEFAULT';
export const SET_DEFAULT_LOADING  = 'SET_DEFAULT_LOADING';
export const SET_DEFAULT_ERROR  = 'SET_DEFAULT_ERROR';
export const CLEAR_DEFAULT = 'CLEAR_DEFAULT';

export const SET_VISIBLE = 'SET_VISIBLE';
export const CLEAR_VISIBLE = 'CLEAR_VISIBLE';
export const SET_ACTIVE_AREA = 'SET_ACTIVE_AREA';
export const SET_UPDATED_FLAG = 'SET_UPDATED_FLAG';
export const CLEAR_UPDATED_FLAG = 'CLEAR_UPDATED_FLAG';

export const SET_SELECTED = 'SET_SELECTED';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';

// ConfirmationModal Module
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL';
export const CLEAR_CONFIRMATION_MODAL = 'CLEAR_CONFIRMATION_MODAL';

// Search Bar Module
export const GET_SEARCH_BAR_RESULTS = 'GET_SEARCH_BAR_RESULTS';
export const SET_SEARCH_BAR_RESULTS = 'SET_SEARCH_BAR_RESULTS';
export const SET_SEARCH_BAR_RESULTS_LOADING = 'SET_SEARCH_BAR_RESULTS_LOADING';
export const SET_SEARCH_BAR_RESULTS_ERROR = 'SET_SEARCH_BAR_RESULTS_ERROR';
export const CLEAR_SEARCH_BAR_RESULTS = 'CLEAR_SEARCH_BAR_RESULTS';
export const UPDATE_RECENTLY_VIEWED = 'UPDATE_RECENTLY_VIEWED';

// User management
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
// User Details Module
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_CURRENCY = 'GET_CURRENCY';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

// Platform Module
export const GET_PLATFORM = 'GET_PLATFORM';
export const SET_PLATFORM = 'SET_PLATFORM';

export const GET_INTERACTIONS_SELECTED = 'GET_INTERACTIONS_SELECTED';
export const SET_INTERACTIONS_SELECTED = 'SET_INTERACTIONS_SELECTED';
export const CLEAR_INTERACTIONS_SELECTED = 'CLEAR_INTERACTIONS_SELECTED';
export const REMOVE_INTERACTIONS_SELECTED = 'REMOVE_INTERACTIONS_SELECTED';

// Translations
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

// Reload the router view
export const RELOAD_VIEW = 'RELOAD_VIEW';

// Custom sections
export const GET_CUSTOM_SECTION = 'GET_CUSTOM_SECTION';
export const GET_CUSTOM_SECTION_LOADING = 'GET_CUSTOM_SECTION_LOADING';
export const GET_CUSTOM_SECTION_ERROR = 'GET_CUSTOM_SECTION_ERROR';
export const CLEAR_CUSTOM_SECTION = 'CLEAR_CUSTOM_SECTION';

// Collection manipulations 
export const POST_ITEM = 'POST_ITEM';
export const POST_ITEM_LOADING = 'POST_ITEM_LOADING';
export const POST_ITEM_ERROR = 'POST_ITEM_ERROR';
export const PATCH_ITEM = 'PATCH_ITEM';
export const PATCH_ITEM_LOADING = 'PATCH_ITEM_LOADING';
export const PATCH_ITEM_ERROR = 'PATCH_ITEM_ERROR';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_LOADING = 'DELETE_ITEM_LOADING';
export const DELETE_ITEM_ERROR = 'DELETE_ITEM_ERROR';

// Saved query / Alert Builder
export const GET_CUSTOMER_ALERTS_BY_ID = 'GET_CUSTOMER_ALERTS_BY_ID';
export const GET_CUSTOMER_ALERTS_BY_ID_ERROR = 'GET_CUSTOMER_ALERTS_BY_ID_ERROR';
export const SET_UNIQUE_CUSTOMERS = 'SET_UNIQUE_CUSTOMERS';

// Feature flags
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';