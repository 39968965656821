<template>
  <LoadingOverlay v-if="loading" />
  <Products
    :name="t('Associated Products')"
    :items="products.data"
    :page-offset="offset"
    :page-limit="limit"
    two-way-pagination
    :industry-types="industryTypes"
    :association-industry-types="associationsIndustryTypes.data"
    @set-muted="setMuted"
    @change-type="changeType"
    @update="updateItem"
    @page-changed="changePage"
  />
  <DismissModal
    :dismiss-modal-open="dismissModalOpen"
    :close-dismiss-modal="closeDismissModal"
    :dismiss-options="dismissOptions"
    object-type="association"
    @post-feedback="deleteProductAssociation"
  />
</template>

<script type="text/javascript">
import { t } from '@sales-i/utils';
import { mapActions, mapState } from 'vuex';
import { LoadingOverlay } from '@sales-i/dsv3';
import Products from '@/admin/components/Associations/Products.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { GET_PRODUCTS, CLEAR_PRODUCTS } from '@/admin/store/actionType';
import { DELETE_BY_ID, SET_ITEMS, GET_ALL, PUT_BY_ID, GET_BY_ID } from '@/shared/store/actionType';
import store from '@/shared/store';

export default {
  name: 'SetupAssociationsProductsView',
  components: {
    DismissModal,
    Products,
    LoadingOverlay,
  },
  data() {
    return {
      dismissModalOpen: false,
      dismissOptions: {},
      type_filter: 'item_level',
      item: null,
      limit: 50,
      offset: 0,
      loading: true,
    };
  },
  computed: {
    ...mapState({
      products: state => state.admin.associations.products,
      industryTypes: state => state.admin.config,
      associationsIndustryTypes: state => state.admin.associations.industryTypes,
    }),
  },
  watch: {
    $route() {},
  },
  async mounted() {
    this.loading = true;

    await this.getProducts({
      type: this.type_filter,
      limit: this.limit,
      offset: 0,
    });

    await this.getIndustryTypes();

    await this.getIndustryTypesForAssociations();

    this.loading = false;
  },
  methods: {
    t,
    ...mapActions({
      getProducts: `admin/associations/${GET_PRODUCTS}`,
      clearProducts: `admin/associations/${CLEAR_PRODUCTS}`,
      muteProduct: `admin/associations/${DELETE_BY_ID}`,

      getIndustryTypes: `admin/config/${GET_ALL}`,

      getAssociationIndustryTypes: `admin/associations/${GET_BY_ID}`,
      saveProductAssociation: `admin/associations/${PUT_BY_ID}`,
    }),
    async getIndustryTypesForAssociations() {
      // Build an array of associations to fetch the industry types for
      const industryTypePromises = [];
      let n = 0;
      for (n; n < this.products.data.length; n++) {
        const _ref = this.products.data[n];
        industryTypePromises.push(this.getAssociationIndustryTypes(_ref.rule_id));
      }

      return await Promise.all(industryTypePromises);
    },
    async changePage(page) {
      this.offset = page;
      await this.getProducts({
        type: this.type_filter,
        limit: this.limit,
        offset: this.offset,
      });
      await this.getIndustryTypesForAssociations();
    },
    async changeType(value) {
      this.type_filter = value;

      await this.getProducts({
        type: this.type_filter,
        limit: this.limit,
        offset: 0,
      });
      await this.getIndustryTypesForAssociations();
    },
    async deleteProductAssociation() {
      try {
        await this.muteProduct({
          antecedent_code: this.item.antecedent_code,
          consequent_code: this.item.consequent_code,
        });

        if (this.products.errors.length > 0) {
          throw new Error(this.products.errors[0]);
        }

        this.item = null;

        this.closeDismissModal();

        await this.getProducts(this.type_filter);

        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t('Successfully dismissed association'),
          selfDismiss: true,
        });
      } catch (e) {
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: t('Error dismissing association'),
          selfDismiss: true,
        });
      }
    },
    setMuted(item) {
      this.item = item;
      this.dismissOptions = {
        object: 'product_association_rules',
        object_id: `${item.antecedent_code}-${item.consequent_code}`,
      };

      this.dismissModalOpen = true;
    },
    closeDismissModal() {
      this.dismissModalOpen = false;
    },
    async updateItem(item) {
      try {
        await this.saveProductAssociation(item.rule_id);

        this.closeDismissModal();

        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t('Successfully updated association', 'successfully_updated_variable', {
            interpolations: {
              variable: 'association',
            },
          }),
          selfDismiss: true,
        });
      } catch (e) {
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: t('Error updating association'),
          selfDismiss: true,
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
