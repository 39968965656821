<!-- todo: translate -->
<template>
  <div>
    <template v-if="target">
      <div class="selected-target">
        <template
          v-for="field in target.analysis_fields"
          :key="field.analysis_field"
        >
          <p class="selected-target__title">
            {{ field.title }} ({{ formatTargetPeriod(target.target_period) }})
          </p>
        
          <div class="selected-target__details">
            <div class="analysis-fields">
              <ViewTargetsDisclosure
                tooltip
                :values="getAnalysisPropertyValues(field)"
              />
            </div>
            <div class="doughnut">
              <ViewTargetsDoughnut
                :current-value="elapsedDays"
                :total-value="totalDays"
              >
                {{ elapsedDaysDisplay }}
                <template #subText>
                  {{ t('days') }}
                </template>
              </ViewTargetsDoughnut>
            </div>

            <div class="bars">
              <ViewTargetsChart
                :data="target"
              />
            </div>          
          </div>
        </template>
      </div>
    </template>
    
    <template v-else>
      <div class="no-target-selected">
        <p>{{ t('select_a_target_below_to_review_performance') }}</p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import useTargets from '@/intelligence/composables/useTargets';
import ViewTargetsChart from '@/admin/components/Targets/ViewTargetsChart.vue';
import ViewTargetsDoughnut from '@/admin/components/Targets/ViewTargetsDoughnut.vue';
import ViewTargetsDisclosure from '@/admin/components/Targets/ViewTargetsDisclosure.vue';

const props = defineProps({
  selectedTargets: {
    type: Array,
    required: true
  },
  target: {
    type: Object,
    default: null
  },
  formatTargetPeriod: {
    type: Function,
    required: true
  },
  measurementValue: {
    type: Function,
    required: true
  },
  attained: {
    type: Function,
    required: true
  }
});

const {
  daysFromInterval,
  getAnalysisPropertyValues
} = useTargets();

const elapsedDays = ref(5); // Hard-coded for now. This will be dynamic in the future

const totalDays = computed(() => daysFromInterval[props.target.target_period.interval] || 1);
const elapsedDaysDisplay = computed(() => 
  `${elapsedDays.value} / ${daysFromInterval[props.target.target_period.interval] || 1}`
);
</script>

<style lang="scss" scoped>
.no-target-selected {
  font-size: var(--font-size-3);
  display: grid;
  place-items: center;
  height: 116px;
  border: 2px dashed black;
  border-radius: var(--border-radius-1);
  padding: var(--spacing-3);
}

.selected-target {
  border: 2px solid black;
  border-radius: var(--border-radius-1);
  padding: var(--spacing-3);
  height: 116px;
  position: relative;
}

.selected-target__title {
  background-color: var(--colour-utility-white);
  position: absolute;
  top: calc(var(--spacing-1) * -1);
  left: var(--spacing-1);
  line-height: 1;
  padding-inline: var(--spacing-half);
}

.selected-target__details {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: 10rem auto 1fr;
  align-items: center;
}

.analysis-fields {
  display: flex;
  gap: var(--spacing-1);
}
</style>