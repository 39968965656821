<template>
  <CustomModal
    show-modal
    :max-width="600"
    title="Interval targets"
    @close-modal="closeModal"
  >
    <table>
      <tbody>
        <tr
          v-for="(item, index) in formattedIntervalData"
          :key="index"
          :class="{ 'current-period': index === props.currentPeriod }"
        >
          <td>{{ item.formattedDate }}</td>
          <td>{{ item.formattedValue }}</td>
        </tr>
      </tbody>
    </table>

    <template #footer>
      <ButtonGroup>
        <CustomButton
          purpose="action"
          small
          @click="closeModal"
        >
          {{ t('dismiss') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue';
import { tAdmin as t, dates } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';
import { CustomButton, CustomModal } from '@sales-i/dsv3';

const emit = defineEmits(['closeModal']);

const props = defineProps({
  intervalData: {
    type: Array,
    default: () => []
  },
  currentPeriod: {
    type: Number,
    default: 0
  },
});

const { cPrice } = useCurrency();

const formattedIntervalData = computed(() => {
  return props.intervalData.map(item => ({
    ...item,
    formattedDate: dates.format(item.date, 'short'),
    formattedValue: cPrice(item.value)
  }));
});


function closeModal() {
  emit('closeModal');
}
</script>

<style lang="scss" scoped>
table {
  margin: var(--spacing-1);
  width: fit-content;
}

  :deep(tbody td) {
    border: none;
    color: inherit;
    font-size: initial;
    padding-block: var(--spacing-half);
    padding-inline: var(--spacing-2);
  }

  .current-period {
    font-weight: bold;
  }
</style>