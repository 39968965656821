<template>
  <template v-if="target">
    <div class="selected-target flow">
      <div class="selected-target__details">
        <div
          v-if="isSmall"
          class="period-selector"
        >
          <label
            for="period"
            class="visually-hidden"
          >
            {{ t('select_period') }}
          </label>
          <CustomSelect
            id="period"
            :value="currentPeriodIndex"
            type="select"
            :items="periodOptions"
            @on-change="handlePeriodChange"
          />
        </div>

        <template
          v-for="field in target.analysis_fields"
          :key="field.analysis_field"
        >
          <div class="doughnuts">
            <ViewTargetsDoughnut
              :current-value="attainedValue"
              :total-value="targetValue"
              :remaining-value="remainingValue"
              :text-size="13"
              :text-size-subtext="6"
            >
              {{ cPrice(remainingValue) }}
              <template #subText>
                {{ t('to_reach_target') }}
              </template>
            </ViewTargetsDoughnut>

            <ViewTargetsDoughnut
              :current-value="elapsedDays"
              :total-value="totalDays"
              :remaining-value="remainingDays"
              :text-size="17"
              :text-size-subtext="8"
            >
              {{ remainingDays }}
              <template #subText>
                {{ t('days_to_go') }}
              </template>
            </ViewTargetsDoughnut>
          </div>
        </template>
      </div>

      <template
        v-for="field in target.analysis_fields"
        :key="field.analysis_field"
      >
        <p class="selected-target__title">
          {{ field.title }} ({{ formatTargetPeriod(target.target_period) }})
        </p>
        <div class="bars">
          <ViewTargetsChart 
            :data="target" 
            :date-array="props.dateArray"
            :current-period-index="currentPeriodIndex"
            :values="target.target_value.values"
            :loading="loading"
            @period-changed="handlePeriodChange"
          />
        </div>
      </template>
    </div>
  </template>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { tAdmin as t, dates } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';
import useTargets from '@/intelligence/composables/useTargets';
import ViewTargetsChart from '@/admin/components/Targets/ViewTargetsChart.vue';
import ViewTargetsDoughnut from '@/admin/components/Targets/ViewTargetsDoughnut.vue';
import { CustomSelect } from '@sales-i/dsv3';

const props = defineProps({
  dateArray: {
    type: Object,
    default: () => ({})
  },
  isSmall: {
    type: Boolean,
    default: false
  },
  periodLabels: {
    type: Array,
    default: () => []
  },
  selectedTargets: {
    type: Array,
    default: () => []
  },
  target: {
    type: Object,
    default: null
  },
  formatTargetPeriod: {
    type: Function,
    default: () => true,
  },
  measurementValue: {
    type: Function,
    default: () => true,
  },
  attained: {
    type: Function,
    default: () => true,
  }
});

const {
  daysFromInterval,
  getTargetPeriods,
  getCurrentPeriodIndex,
  setCurrentPeriodIndex,
  currentPeriodIndices
} = useTargets();
const { cPrice } = useCurrency();

const loading = ref(false);
const localTargetDateArrays = ref({});

const periodOptions = computed(() => {
  return periodLabels.value.map((label, index) => ({
    value: index,
    text: dates.format(label, 'short')
  }));
});

// Get the start and end dates for the current period
const currentPeriodDates = computed(() => {
  if (!props.target || !currentTargetPeriods.value.length) return { startDate: null, endDate: null };
  const periodIndex = currentPeriodIndex.value;
  return {
    startDate: currentTargetPeriods.value[periodIndex]?.startDate,
    endDate: currentTargetPeriods.value[periodIndex]?.endDate
  };
});

// Calculate total days in the current period
const totalDays = computed(() => {
  if (!currentPeriodDates.value.startDate || !currentPeriodDates.value.endDate) {
    return props.target ? daysFromInterval[props.target.target_period.interval] || 30 : 30;
  }
  
  const start = new Date(currentPeriodDates.value.startDate);
  const end = new Date(currentPeriodDates.value.endDate);
  
  // Calculate days between dates
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
});

// Calculate elapsed days based on the current period
const elapsedDays = computed(() => {
  if (!currentPeriodDates.value.startDate) return 0;
  
  const start = new Date(currentPeriodDates.value.startDate);
  const today = new Date();
  const end = new Date(currentPeriodDates.value.endDate);
  
  // If today is before the period starts, return 0
  if (today < start) return 0;
  
  // If today is after the period ends, return totalDays
  if (today > end) return totalDays.value;
  
  // Calculate days elapsed
  const diffTime = Math.abs(today - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include the start date
});

// Remaining days is total minus elapsed
const remainingDays = computed(() => Math.max(0, totalDays.value - elapsedDays.value));

// Doughnut data
const targetValue = computed(() => {
  if (!props.target?.target_value?.values) return 0;
  return props.target.target_value.values[currentPeriodIndex.value] || 
         props.target.target_value.values[0] || 
         0;
});

const attainedValue = computed(() => 
  props.target?.attained?.attained_value || 0
);

const remainingValue = computed(() => targetValue.value - attainedValue.value);

// Periods for the current target
const currentTargetPeriods = computed(() => {
  if (!props.target || !localTargetDateArrays.value[props.target.id]) return [];
  return localTargetDateArrays.value[props.target.id].periods || [];
});

// Labels for chart periods
const periodLabels = computed(() => {
  if (!props.target) return [];
  return currentTargetPeriods.value.map(period => period.startDate);
});

// Current period index for the selected target
const currentPeriodIndex = computed(() => {
  return getCurrentPeriodIndex(props.target?.id);
});

// Handle period change in chart
const handlePeriodChange = (periodIndex) => {
  if (props.target?.id) {
    setCurrentPeriodIndex(props.target.id, periodIndex);
  }
};

// Generate date arrays for the current target
const generateDateArrayForTarget = async (target) => {
  if (!target || !target.target_period) return;
  
  try {
    loading.value = true;
    
    const response = await getTargetPeriods({
      type: 'getTargetPeriod',
      params: {
        startDate: target.target_period.startDate,
        endDate: target.target_period.endDate,
        interval: target.target_period.interval,
        intervalMultiplier: target.target_period.intervalMultiplier || 1
      }
    });
    
    if (response) {
      // Initialize the current period index if needed
      if (currentPeriodIndices.value[target.id] === undefined) {
        currentPeriodIndices.value[target.id] = 0;
      }
      
      // Store the date array for this target
      localTargetDateArrays.value[target.id] = response;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error generating date array:', error);
  } finally {
    loading.value = false;
  }
};

// Watch for changes to the target prop
watch(() => props.target, async (newTarget) => {
  if (newTarget && !localTargetDateArrays.value[newTarget.id]) {
    await generateDateArrayForTarget(newTarget);
  }
}, { immediate: true });

// On component mount, generate date arrays for the current target
onMounted(async () => {
  if (props.target) {
    await generateDateArrayForTarget(props.target);
  }
});
</script>

<style lang="scss" scoped>
:deep(select) {
  font-size: var(--font-size-small);
  width: auto;
}

.selected-target {
  background-color: var(--colour-utility-white);
  box-shadow: 0 -2px 4px var(--colour-panel-g-24);
  padding: var(--spacing-2);
}

.doughnuts {
  display: flex;
  gap: var(--spacing-2);
  justify-content: center;
}

.selected-target__title {
  text-align: center; 
}

.selected-target__details {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: auto auto 1fr;
  align-items: center;
}

.period-selector .form-group {
  margin-bottom: 0;
}

.analysis-fields {
  display: flex;
  gap: var(--spacing-1);
}
</style>