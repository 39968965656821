<!-- todo: translate -->
<template>
  <div class="graph">
    <div class="graph__label">
      {{ t('Target') }}
    </div>
    <div 
      class="graph__bar" 
      :style="{ width: targetWidth }"
    />
    <div class="graph__value">
      {{ cPrice(targetValue) }}
    </div>
    <div class="graph__label">
      {{ t('Attained') }}
    </div>
    <div
      class="graph__bar graph--attained"
      :style="{ width: attainedWidth }"
    />
    <div class="graph__value">
      {{ cPrice(attainedValue) }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { cPrice } = useCurrency();

const targetValue = computed(() => props.data.target_value.value);
const attainedValue = computed(() => props.data.attained.attained_value);

const targetWidth = computed(() => {
  if (attainedValue.value > targetValue.value) {
    return `${(targetValue.value / attainedValue.value) * 100}%`;
  }
  return '100%';
});

const attainedWidth = computed(() => {
  if (targetValue.value > attainedValue.value) {
    return `${(attainedValue.value / targetValue.value) * 100}%`;
  }
  return '100%';
});
</script>

<style lang="scss" scoped>
.graph {
  display: grid;
  align-items: center;
  gap: var(--spacing-half) var(--spacing-2);

  grid-template-areas:
    "label bar value"
    "label bar value";

  grid-template-columns: 6rem 1fr 5rem;
  grid-template-rows: repeat(2, auto);
}

.graph__bar {
  background-color: var(--colour-panel-g-32);
  border-radius: var(--border-radius-1);
  height: var(--spacing-2);
  max-width: 100%;
}

.graph__bar.graph--attained {
  background-color: var(--colour-data-viking-dark);
}

.chart-container {
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  align-items: center;
}

.bar-tooltip {
  width: 80px;
}

.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
}
</style>