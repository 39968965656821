<!-- todo: translate -->
<template>
  <div class="target-amount">
    <label
      for="target-amount"
      :class="{ 'visually-hidden': !isMobile }"
    >
      {{ t('Enter target amount') }}
    </label>
    <CustomInput
      id="target-amount"
      :value="targetAmount"
      class="target-amount"
      :disabled="props.disabled"
      type="number"
      name="target-amount"
      :placeholder="t('Enter target amount')"
      @input="handleTargetAmount"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { t } from '@sales-i/utils';
import { CustomInput } from '@sales-i/dsv3';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  targetAmount: {
    type: [String, Number],
    default: ''
  }
});

const emit = defineEmits(['target-amount', 'validity-changed']);

const mq = useMq();
const targetAmount = ref(props.targetAmount || '');
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const isValid = computed(() => {
  return targetAmount.value !== '';
});

const handleTargetAmount = (value) => {
  targetAmount.value = value;
  emit('target-amount', value);
  emit('validity-changed', isValid.value);
};

// Watch for changes in the prop and update the local state
watch(() => props.targetAmount, (newValue) => {
  targetAmount.value = newValue;
});
</script>

<style lang="scss" scoped>
.target-amount  {
  margin-bottom: 0;

  :deep(input:disabled) {
    opacity: 0.7;
  }
}
</style>